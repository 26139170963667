/**
 * Composant représentant une modal
 */
import { IComponentOptions, module } from 'angular';
import DialogController from './ex-dialog.controller';
import Binding from '../../helpers/binding.helper';

export default module('core.components.ex-dialog', []).component('exDialog', DialogFactory());

function DialogFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.ICON.OPTIONAL,
            Binding.LIBELLES.OPTIONAL,
            Binding.MULTI_OCCURRENCE.OPTIONAL,
            {
                lblTitre: '@?',
                lblConfirm: '@?',
                lblCancel: '@?',
                warm: '@',
                confirmAction: '&?',
                showConfirmAction: '<?',
                hideCancelAction: '<?',
                hideUsingCallbackOnly: '<?',
                closeOnError: '<?',
                // Permet d'exposer le formulaire au dialogue
                formDialog: '=?formCtrl',
                formCtrlAuth: '=?formCtrlAuth',
                ecranContext: '<?',
                data: '<?',
                confirmDisabled: '<?',
                error: '<?',
                avertissement: '<?',
                submitOnEnter: '<?',
                confirmerQuitterPage: '<?',
                busy: "<?",
                reloadData: "<?",
                authValiderCode: "<?",
                authBlocTitre: '<?',
                bloc: '<?'
            }
        ),
        transclude: {
            actionsSecondaires: '?exDialogActionsSecondaires',
            actions: '?exDialogActions',
        },
        controller: DialogController,
        controllerAs: 'vm',
        template: require('./ex-dialog.html')
    };
}

