import { IAugmentedJQuery, IComponentController, IFormController, IScope, ITimeoutService } from 'angular';
import { IKeyCodes } from '../../constants/key-codes.constant';
import { IDataType } from '../../services/data-types/data-type.service';
import { IDefaultsService } from '../../services/utils/defaults.service';

interface IComposantInputDate extends IComponentController {
    readonly: boolean;
    formCtrlAuth: IFormController;
    authcode: number;
    autoFocus(index: any, event: any): void;
    onChange(): void;
    dataType: IDataType;
    digits: Array<string>;
    longueurFixeCode: number;
    longueurFixeDigit: number;
    pattern: string;
}
/* @ngInject */
export default function InputAuthCodeController($scope: IScope,
    $element: IAugmentedJQuery,
    keyCodes: IKeyCodes,
    $timeout: ITimeoutService,
    defaults: IDefaultsService) {
    const vm: IComposantInputDate = this;

    vm.autoFocus = autoFocus
    vm.onChange = onChange;
    vm.$onInit = function $onInit() {
        defaults(vm, {
            longueurFixeCode: 6,
            longueurFixeDigit: 1,
            pattern: '/^[0-9]$/'
        })
        vm.digits = new Array(vm.longueurFixeCode).fill("")
    }

    function autoFocus(index: any, event: any) {
        const inputLength = event.target.value.length;
        const isLastInput = index === vm.digits.length - 1;
        if (inputLength === 1 && !isLastInput) {
            $element.find('.ex-input-auth-code-digit-input')[index + 1].focus()
        }
    }

    function onChange() {
        let flag = false;
        const validateCode: any = vm.digits.join('').trim();
        if (validateCode && !isNaN(validateCode) && validateCode.length === 6) {
            flag = true
            vm.authcode = validateCode
        }
        vm.formCtrlAuth.authcode.$setValidity('customError', flag);
    }
}
