import { IAugmentedJQuery, IComponentController, IScope } from 'angular';
import { ISelecteur } from '../../services/selecteur.service';
import { IDataLinker } from '../../services/data-linker.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IMultiOccurrence } from '../../services/multi-occurrence.service';
import { IMenuItem } from '../../services/menu/menu-item.service';
import { IColonneTri } from '../../services/colonne-tri.service';

interface IComposantSelecteur extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    selecteur: ISelecteur;
    libelles: any;
    lblColonneLeft: string;
    lblColonneRight: string;
    scopeColonnesHidden: any;
    menusColonneDroite: Array<IMenuItem>;
    multiOccurrence: IMultiOccurrence;
    getEnteteLibelle(col: IColonneTri | string): string;
}

/* @ngInject */
export default function SelecteurController($scope: IScope,
    $element: IAugmentedJQuery,
    dataLinker: IDataLinker) {
    const vm: IComposantSelecteur = this;

    vm.$onInit = $onInit;
    vm.getEnteteLibelle = getEnteteLibelle;

    function $onInit() {
        vm.lblColonneLeft = vm.lblColonneLeft || 'G_LBL_CHAMP_GAUCHE';
        vm.lblColonneRight = vm.lblColonneRight || 'G_LBL_CHAMP_DROITE';

        vm.selecteur.colonneLeft = applyFiltreColonneVisible([...vm.selecteur.colonneLeft]);
        vm.selecteur.colonneRight = applyFiltreColonneVisible([...vm.selecteur.colonneRight]);

        vm.scopeColonnesHidden = $scope.$new();
        vm.scopeColonnesHidden.data = {};

        dataLinker.link($element, vm.scopeColonnesHidden.data, vm.ecranContextCtrl.stateParams, vm.ecranContextCtrl.ecranDetails);
    }

    function getEnteteLibelle(col: IColonneTri | string): string {
        const colName = typeof col === 'string' ? col : col.nom;
        return vm.multiOccurrence.getEnteteLibelle(colName, vm.selecteur.colonneRegroupement, true);
    }

    function applyFiltreColonneVisible(colonnes: Array<IColonneTri | string>): Array<IColonneTri | string> {
        return colonnes.filter((colonne) => {
            const col = typeof colonne === 'string' ? colonne : colonne.nom;
            const colonneParametre = vm.multiOccurrence.colonnesParametres[col];
            const hidden = colonneParametre && colonneParametre.hidden;
            if(colonneParametre?.hideFromRecheche){
                return false;
            }
            if (colonneParametre === undefined) {
                return false;
            }

            if (hidden instanceof Function) {
                return !hidden(vm.scopeColonnesHidden && vm.scopeColonnesHidden.data);
            } else {
                return !hidden;
            }
        });
    }
}
