import { IMultiOccurrence, IMultiOccurrenceClass } from '../../services/multi-occurrence.service';
import { IDataType, IRaffinement, IRestriction } from '../../services/data-types/data-type.service';
import { ISchemaItem } from '../../interfaces/schemas.interface';
import { IPaginationClass } from '../../services/pagination.service';
import { IOperateurService } from '../../services/operateur.service';
import { IFiltre, IFiltreClass, IFiltreOptions } from '../../services/filtre.service';

import * as angular from 'angular';
import {
    IAugmentedJQuery,
    ICompileService,
    IComponentController,
    IDocumentService,
    IFormController,
    IScope,
    ITimeoutService,
    INgModelController,
    IRootScopeService
} from 'angular';
import { IApiConfig } from '../../interfaces/api-config.interface';
import { IBooleanDataTypeClass } from '../../services/data-types/boolean-data-type.service';
import {
    IFormulaireItemResourceParamsFonction,
    IFormulaireItemValueChangeFonction
} from '../../services/formulaire/formulaire-item.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { KeyCodes } from '../../constants/key-codes.constant';
import isMobile from '../../constants/mobile.constant';
import { IComposantMonoOccurrence } from "../ex-mono-occurrence/ex-mono-occurrence.controller";
import { IFilterLibelle } from '../../filters/ex-libelle.filter';

interface IComposantInputLov extends IComponentController {
    occurrence: IMultiOccurrence;
    ecranContextCtrl: IEcranContextController;
    formCtrl: IFormController;
    monoOccurrenceCtrl: IComposantMonoOccurrence;
    nameElement: string;
    col: string;
    data: any;
    value: any;
    code: any;
    hint: string;
    capture: string;
    recherche: string;
    dataType: IDataType;
    capturing: boolean;
    opened: boolean;
    loading: boolean;
    schemaItem: ISchemaItem;
    suggestion: any;
    suggestions: Array<any>;
    activeSuggestion: number;
    gettingMore: boolean;
    hasMore: boolean;
    disabled: boolean;
    multiOccurrence: IMultiOccurrence,
    position: string;
    hasMissingRestriction: boolean;
    resourceParams: any | IFormulaireItemResourceParamsFonction;
    readonly: boolean;
    nombreLibellesDetailsCacher: number;
    longueurFixe: number;
    srccodref: string;
    filtre: IFiltre;
    disableSelectionMultiple: boolean;
    isSelectionMultipleEmpty(flag: boolean): boolean;
    setIsSelectionMultipleEmpty: boolean;
    onValueChange: IFormulaireItemValueChangeFonction;
    filtres: Array<IFiltreOptions>;
    selectSuggestion(suggestion: string, options?: { focus: boolean }): void;
    handleSelectionSuggestion(event: JQueryEventObject, suggestion: any): void;
    toggleSuggestions(): void;
    getMore(): void;
    openAutocomplete(): void;
    closeAutocomplete(): void;
    startCapture(): void;
    lostFocus(): void;
    handleInputKey(event: JQueryEventObject): void;
    handleCaptureChange(): void;
    clearCapture(): void;
    isInvalid(): boolean;
    isDisabled(): boolean;
    displayMessageMessage(): boolean;
    displayMessageCaptureInvalid(): boolean;
    showLibelle(index: number): boolean;
    afficherDetailLov(suggestion: any, col: string): boolean;
    libelleSelectionMultiple(): string
}

let counter = 1;
const OPTIONS_PAR_PAGE = 15;

/* @ngInject */
export default function InputLovController(MultiOccurrence: IMultiOccurrenceClass,
    ApiConfig: IApiConfig,
    $document: IDocumentService,
    $scope: IScope,
    $timeout: ITimeoutService,
    $compile: ICompileService,
    Pagination: IPaginationClass,
    Operateur: IOperateurService,
    Filtre: IFiltreClass,
    $element: IAugmentedJQuery,
    BooleanDataType: IBooleanDataTypeClass,
    $rootScope: IRootScopeService,
    exLibelleFilter: IFilterLibelle) {
    const vm: IComposantInputLov = this;

    // Jquery elements
    const target: IAugmentedJQuery = $element.find('[ng-model]');
    const captureInput: IAugmentedJQuery = $element.find('.ex-input-lov-input');
    const scrollContainer: IAugmentedJQuery = $element.closest('[ex-input-lov-mask-container],md-content,md-dialog,body');
    const windowElement = angular.element(window);
    const container = $document.find('body');

    let colsRestrictions: Array<string>;
    let restrictions: { [key: string]: IRestriction };
    let colsRaffinements: Array<string>;
    let raffinements: { [key: string]: IRaffinement };

    let autocomplete: IAugmentedJQuery;
    let autocompleteContent: IAugmentedJQuery;
    let id = counter++;
    let codeCol: string;
    let pendingCapture = false;
    let captureIsDirty: boolean;

    vm.gettingMore = false;
    vm.hasMore = false;
    vm.isSelectionMultipleEmpty = isSelectionMultipleEmpty;
    vm.selectSuggestion = selectSuggestion;
    vm.handleSelectionSuggestion = handleSelectionSuggestion;
    vm.toggleSuggestions = toggleSuggestions;
    vm.getMore = getMore;
    vm.openAutocomplete = openAutocomplete;
    vm.closeAutocomplete = closeAutocomplete;
    vm.startCapture = startCapture;
    vm.lostFocus = lostFocus;
    vm.handleInputKey = handleInputKey;
    vm.handleCaptureChange = handleCaptureChange;
    vm.clearCapture = clearCapture;
    vm.isInvalid = isInvalid;
    vm.isDisabled = isDisabled;
    vm.displayMessageMessage = displayMessageMessage;
    vm.displayMessageCaptureInvalid = displayMessageCaptureInvalid;
    vm.showLibelle = showLibelle;
    vm.afficherDetailLov = afficherDetailLov;
    vm.libelleSelectionMultiple = libelleSelectionMultiple;
    vm.restrictionValues = {}

    vm.$onInit = function $onInit() {
        colsRestrictions = Object.keys(vm.dataType.params.restrictions || {});
        colsRaffinements = Object.keys(vm.dataType.params.raffinements || {});

        restrictions = colsRestrictions.reduce((resultat: { [key: string]: IRestriction }, col) => {
            resultat[col] = (typeof vm.dataType.params.restrictions[col] === 'string') ?
                { nom: vm.dataType.params.restrictions[col] as string, operateur: 'EGALE' } :
                { operateur: 'EGALE', ...vm.dataType.params.restrictions[col] as IRestriction };
            return resultat;
        }, {});

        raffinements = colsRaffinements.reduce((resultat: { [key: string]: IRaffinement }, col) => {
            resultat[col] = (typeof vm.dataType.params.raffinements[col] === 'string') ?
                { nom: <string>vm.dataType.params.raffinements[col] } :
                { useNullOnEmpty: false, ...vm.dataType.params.raffinements[col] as IRaffinement };
            return resultat;
        }, {});

        initMultiOccurrence();
        initScope();
        injectAutocomplete();

        //Losrqu'on est dans les critères suggeres et qu'il y a des lovs non valide on doit vider le champ
        $scope.$on('exReinitialiserCriteresSuggeres', () => {
            if (vm.formCtrl[vm.nameElement].$invalid) {
                delete vm.capture;
                delete vm.code;
                vm.formCtrl[vm.nameElement].$setValidity('captureInvalide', true);
            }
        });
        //effacer le texte de la lov au besoin
        $scope.$on('ex-input-lov-effacer-selection', () => {
            vm.clearCapture()
        });

        vm.monoOccurrenceCtrl && vm.monoOccurrenceCtrl.monoOccurrence.on("forceInitLovs", () => {
            $timeout(() => {
                vm.readonly = false
                vm.multiOccurrence.fetchDataList().then(() => {
                    vm.multiOccurrence.dataListQuery.$promise.then(() => {
                        if (vm.multiOccurrence.dataList.length === 1) {
                            vm.readonly = true
                            vm.selectSuggestion(vm.multiOccurrence.dataList[0], { focus: false })
                        }
                    })
                })
            })
        })

        $scope.$watch('vm.disabled', (disabled) => {
            // Permet d'arrêter la capture après que l'on ait changé la valeur du champ et que la lov est devenue désactivée (Ex. Critères suggérés de GL0008)
            if (disabled && vm.capturing) {
                vm.capturing = false;
            }
        });

        // Il faut laisser le temps à l'événement courant de terminer
        $element.on('focusout.exLov', '.ex-input-lov-input', (event: JQueryEventObject) => {
            if (vm.capturing) {
                vm.formCtrl[vm.nameElement].$setTouched();
                vm.formCtrl[vm.nameElement].$setDirty();
                handleCaptureEnd(event);
            }
        });

        $scope.$on('vm.setSelectionMultipleEmpty', (event, { flag }) => {
            isSelectionMultipleEmpty(flag)
        });
    }

    function isSelectionMultipleEmpty(flag: boolean) {
        vm.setIsSelectionMultipleEmpty = flag;
        return vm.setIsSelectionMultipleEmpty;
    }

    function libelleSelectionMultiple() {
        return exLibelleFilter('G_LBL_CRITERES_MULTIPLES', vm.libelles) + " - " + vm.titre
    }

    function initScope() {
        vm.capturing = false;
        vm.opened = false;
        vm.recherche = '';

        initSuggestion();

        codeCol = vm.dataType.params.description[0];
        vm.multiOccurrence.once('ready', () => {
            const champ = `${vm.col}__${codeCol}`;
            vm.code = vm.data[champ];

            $scope.$watch(`vm.data.${vm.col}`, () => {
                if (!vm.capturing || vm.data[champ]) {
                    // On obtient les champs additionnels pour la description
                    vm.code = vm.data[champ];
                    vm.capture = vm.code;
                    initSuggestion();
                }
            });
        });

        $scope.$watch('vm.schemaItem', (schemaItem: ISchemaItem) => {
            vm.schemaItem = schemaItem;
        });

        //Il faut attendre que les valeurs par défauts / de départs soient affectés avant d'initié les $watch.
        $timeout(() => {
            if (vm.onValueChange) {
                $scope.$watch(`vm.data.${vm.col}`, (newValue, oldValue) => {
                    if (newValue !== oldValue) {
                        vm.onValueChange(vm.data);
                    }
                });
            }

            if (colsRestrictions.length) {
                colsRestrictions.forEach((col: string, index: number) => {
                    if (vm.data[col]) {
                        //Si on a une valeur par défaut, il faut l'ajouter au data
                        vm.data[`${vm.col}__${col}`] = vm.data[col];
                    }
                });

                $scope.$watchCollection(() => colsRestrictions.map((col: string) => vm.data[col]),
                    (newValues: Array<string | number>, oldValues: Array<string | number>) => {
                        const isInitValues = newValues.every((newValue: string | number, index: number) => newValue === oldValues[index]);
                        const updatedValues = (!isInitValues) ? newValues.map(
                            (newValue: string | number, index: number) => {
                                if (newValue !== oldValues[index]) {
                                    return colsRestrictions[index];
                                }
                            }).filter(updatedCol => updatedCol) : colsRestrictions;

                        updatedValues.forEach((col: string) => {
                            //si c'est gs0090 on va ajouter le texte "null" pour avoir de l'information sans restrictions
                            if (vm.srccodref === "GS0090" && vm.data[col] === null) {
                                vm.data[col] = "null"
                            }
                            // Si une des restrictions n'est pas définie, on va désactiver la lov
                            vm.hasMissingRestriction = colsRestrictions.some((col: string) => !vm.data[col]);
                            if (vm.data[col] !== undefined) {
                                if (!restrictions[col].skipFiltre) {
                                    vm.multiOccurrence.etatSelected.replaceFiltre(new Filtre({
                                        colonne: restrictions[col].nom,
                                        operateur: Operateur[restrictions[col].operateur],
                                        valeur: vm.data[col]
                                    }), colsRestrictions.indexOf(col));
                                }
                                vm.restrictionValues[restrictions[col].nom] = vm.data[col]
                            }
                        });
                    });
            } else {
                vm.hasMissingRestriction = false;
            }

            if (colsRaffinements.length) {
                colsRaffinements.forEach(col => {
                    if (vm.data[col]) {
                        //Si on a une valeur par défaut, il faut l'ajouter au data
                        vm.data[`${vm.col}__${col}`] = vm.data[col];
                    }
                });

                $scope.$watchCollection(
                    () => {
                        return colsRaffinements.map((col: string) => vm.data[col]);
                    },
                    (newValues: Array<string | number>, oldValues: Array<string | number>) => {
                        const isInitValues = newValues.every((newValue: string | number, index: number) => newValue === oldValues[index]);
                        const updatedValues = !isInitValues ? newValues.map(
                            (newValue: string | number, index: number) => {
                                const isNewEmpty = newValue == null;
                                const isOldEmpty = oldValues[index] == null;
                                if (newValue !== oldValues[index] && !(isNewEmpty && isOldEmpty)) {
                                    return colsRaffinements[index];
                                }
                            })
                            .filter((updatedCol: string) => updatedCol) : colsRaffinements;

                        updatedValues.forEach((col: string) => {
                            if (vm.data[col] !== null || raffinements[col].useNullOnEmpty) {
                                const operateur = vm.data[col] == null && raffinements[col].useNullOnEmpty ? Operateur.NULL : Operateur.EGALE;
                                vm.multiOccurrence.etatSelected.replaceFiltre(new Filtre({
                                    colonne: raffinements[col].nom,
                                    operateur,
                                    valeur: vm.data[col]
                                }), colsRestrictions.length + colsRaffinements.indexOf(col));
                            } else {
                                vm.multiOccurrence.etatSelected.removeFiltre(colsRestrictions.length + colsRaffinements.indexOf(col));
                            }

                            // Si on ne fait pas ça, la lov va se mettre à jour même fermée
                            vm.multiOccurrence.preventDataListUpdate = true;

                            if (isRaffinementIncompatible(col, vm.data[col])) {
                                // Dès qu'un des raffinements change, il faut vider le champ, car la valeur est possiblement
                                // non-compatible avec le nouveau raffinement
                                vm.capture = '';
                                delete vm.data[vm.col];
                                vm.handleCaptureChange();
                            }
                        });
                    }
                );
            }
        });
    }

    function initSuggestion() {
        if (vm.data[vm.col]) {
            vm.suggestion = vm.dataType.params.description.reduce((suggestion: any, col: string) => {
                const champ = `${vm.col}__${col}`;
                if (vm.data[champ] != null || vm.ecranContextCtrl.ecranDetails.valeurs[champ]) {
                    vm.data[champ] = vm.data[champ] != null ? vm.data[champ] : vm.ecranContextCtrl.ecranDetails.valeurs[champ];
                    suggestion[col] = vm.data[champ];
                }

                return suggestion;
            }, {});
        }
    }

    function isRaffinementIncompatible(col: string, valeur: any) {
        return Boolean(
            raffinements &&
            raffinements[col] &&
            valeur !== vm.data[`${vm.col}__${col}`]
        );
    }

    function initMultiOccurrence() {
        const colonnesVisibles = [
            ...vm.dataType.params.description,
            ...vm.dataType.params.details || [],
            vm.dataType.params.valeur
        ].filter((col: string) => col);

        const colonnesCachees = [...Object.values(vm.dataType.params.autresValeurs || {}),
        ...Object.values(vm.dataType.params.affectations || {})];

        const filtres: Array<IFiltreOptions> = [
            ...colsRestrictions.filter((col: string) => !restrictions[col].skipFiltre).map((col: string) => {
                return {
                    colonne: restrictions[col].nom,
                    operateur: Operateur[restrictions[col].operateur],
                    valeur: vm.data[col]
                };
            }),
            ...colsRaffinements.map((col: string) => {
                return {
                    colonne: raffinements[col].nom,
                    operateur: Operateur.EGALE,
                    valeur: vm.data[col]
                };
            })
        ];

        if (vm.filtres) {
            filtres.push(...vm.filtres);
        }

        vm.multiOccurrence = new MultiOccurrence({
            stateParams: vm.ecranContextCtrl.stateParams,
            ecranDetails: vm.ecranContextCtrl.ecranDetails,
            ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails,
            resourceParamsDynamique: getResourceParamsValues,
            srccod: `lov-${vm.dataType.params.source}`,
            autoFetch: false,
            resourceUrl: `${ApiConfig.ROOT}/liste-valeurs/${vm.dataType.params.source}`,
            colonnesVisibles: Array.from(new Set(colonnesVisibles)),
            colonnesCachees: colonnesCachees,
            pagination: new Pagination({
                pageCourante: 1,
                nombreElementPage: OPTIONS_PAR_PAGE
            }),
            filtres: vm.filtres,
            fonctions: {
                filtrer: true,
                selectionnerDesColonnes: false,
                selectionnerTri: false,
                enregistrerUnEtat: false,
                reinitialiser: false,
                selectionnerUnEtat: false,
                exportation: false,
                importation: false,
                nouveau: false,
                rechercheParColonne: false
            }
        });

        vm.multiOccurrence.startWatching($scope);

        // On se met à écouter même fermer pour permettre de valider la valeur du champ code
        vm.multiOccurrence.on('dataListUpdate', () => {
            // Si on est sur une autre page que la première, il faut qu'on ajoute les éléments à la liste courante
            if (vm.multiOccurrence.etatSelected.pagination.pageCourante > 1) {
                // On s'assure qu'il n'y a pas de doublon
                for (const data of vm.multiOccurrence.dataList) {
                    const isInList = vm.suggestions.find(suggestion => {
                        return suggestion[vm.multiOccurrence.cleint] === data[vm.multiOccurrence.cleint];
                    });

                    if (!isInList) {
                        vm.suggestions.push(data);
                    }
                }
            } else {
                // Si on est à la page 1, on remplace toutes le suggestions avec la liste courante
                vm.suggestions = vm.multiOccurrence.dataList;
            }

            // Si on est en train d'obtenir plus de suggestions
            if (!vm.gettingMore) {
                vm.activeSuggestion = 0;
            } else {
                vm.gettingMore = false;
            }

            if (vm.opened) {
                vm.hasMore = vm.multiOccurrence.etatSelected.pagination.total > OPTIONS_PAR_PAGE;

                positionAutocomplete()
            }

            if (!pendingCapture) {
                // On défini le hint de capture quand la LOV est fermée
                if (!vm.opened && vm.suggestions.length === 1 && !vm.longueurFixe) {
                    vm.hint = String(vm.suggestions[0][codeCol]).replace(new RegExp(`^${vm.capture}`, 'i'), '');
                } else {
                    vm.hint = '';
                }
            }

            vm.code = vm.capture;
        });
    }

    function injectAutocomplete() {
        autocomplete = $compile(`<div class="ex-input-lov" ng-show="vm.opened" ng-class="{ 'ex-input-lov--top': vm.position === 'top', 'ex-input-lov--has-details': vm.dataType.params.details.length }" name="{{vm.name}}">
    <div class="ex-input-lov-focus-trap" tabindex="0"></div>
    <ex-erreur-chargement ng-if="vm.multiOccurrence.initError" class="p-8" lbl-message="G_MSG_CHARG"></ex-erreur-chargement>
    <div ng-if="!vm.multiOccurrence.initError">
        <ex-recherche class="p-8" multi-occurrence="vm.multiOccurrence"
            recherche-automatique="true" recherche="vm.recherche"
            disabled-introspection="true"></ex-recherche>
        <md-progress-circular ng-if="vm.multiOccurrence.fetchingDataList && !vm.multiOccurrence.dataListError && !vm.gettingMore" class="ex-input-lov-data-loader layout-align-center-center layout-column" md-diameter="20"></md-progress-circular>
        <ex-erreur-chargement ng-if="vm.multiOccurrence.dataListError" lbl-message="G_MSG_CHARG_DONNEES" retry-action="vm.multiOccurrence.fetchDataList()" retrying="vm.multiOccurrence.fetchingDataList"></ex-erreur-chargement>
        <div class="ex-input-lov-content">
            <div class="ex-input-lov-item p-8" tabindex="-1"
                ng-repeat="suggestion in vm.suggestions"
                ng-class="{'ex-input-lov-item--active': vm.activeSuggestion == $index}"
                ng-click="vm.handleSelectionSuggestion($event, suggestion)"
                name="ex-lov-item-{{$index}}">
                <span class="ex-input-lov-item-description" ng-repeat="col in vm.dataType.params.description">
                    <ex-data-value col="{{col}}" data="suggestion" data-types="vm.multiOccurrence.dataTypes" schemas="vm.multiOccurrence.schema"></ex-data-value><span ng-if="!$last"> - </span>
                </span>
                <ex-data col="{{col}}" ng-repeat="col in vm.dataType.params.details"
                    ng-class="{ 'ex-input-lov-details-sans-libelle': !vm.showLibelle($index + 1), 'ex-input-lov-details-coche': vm.multiOccurrence.dataTypes[col].params.coche }"
                    ng-if="vm.afficherDetailLov(suggestion, col)"
                    liste_details_css="vm.dataType.params.listeDetailsCss"
                    libelles="vm.multiOccurrence.libelles" schemas="vm.multiOccurrence.schema"
                    data-types="vm.multiOccurrence.dataTypes" data="suggestion">
                    <ex-data-texte ng-if="vm.showLibelle($index + 1)">{{vm.dataType.params.details[$index] | exLibelle: vm.multiOccurrence.libelles}}</ex-data-texte>
                </ex-data>
            </div>
            <div ng-if="!vm.suggestions.length && !vm.multiOccurrence.fetchingDataList && !vm.multiOccurrence.dataListError" class="ex-input-lov-item ex-input-lov-item--no-result p-8">{{'G_LBL_AUCUNE_VALEUR' | exLibelle}}</div>
            <div ng-if="vm.hasMore" class="ex-input-lov-footer ex-accent-hue-2-fg" tabindex="-1" ng-click="vm.getMore()" ex-busy="vm.gettingMore"></div>
        </div>
    </div><div class="ex-input-lov-focus-trap ex-input-lov-focus-trap--end" tabindex="0"></div>
</div>`)($scope);

        $timeout(() => autocompleteContent = autocomplete.find(".ex-input-lov-content"))

        container.append(autocomplete);

        // Quand le scope est détruit, il faut s'assurer de détruire les listenners globaux
        $scope.$on('$destroy', () => {
            autocomplete.remove();
            unsetListenners();
            vm.multiOccurrence.removeAllListeners();
        });
    }

    function getCleValeur() {
        return vm.dataType.params.valeur || vm.multiOccurrence.cleint;
    }

    function positionAutocomplete() {
        autocomplete.width(target.outerWidth());
        const targetOffset = target.offset();
        const targetHeight = target.outerHeight();
        const bodyHeight = $document.outerHeight();
        const halfBodyHeight = (bodyHeight * .5);
        // On ne colle pas la lov au champ pour des raisons esthétiques
        const margeBasLov = 3;
        // Le libellé se trouve plus haut que le champ target
        const margeHautLibelle = 16;
        // Si la lov va dépasser le bas de la fenêtre on affichage sur le haut
        if (targetOffset.top + halfBodyHeight + margeBasLov > bodyHeight) {
            vm.position = 'top';
            autocomplete.css({
                left: targetOffset.left,
                top: 'auto',
                bottom: bodyHeight - targetOffset.top + margeHautLibelle /* espace pour le libellé */
            });
        } else {
            vm.position = 'bottom';
            autocomplete.css({
                left: targetOffset.left,
                top: targetOffset.top + targetHeight + margeBasLov,
                bottom: 'auto'
            });
        }
        //on met le focus dans la boite de texte
        if (autocomplete) {
            const focus = autocomplete.find("ex-recherche").find('input')
            //validation de null
            if (focus && focus?.length >= 1) {
                focus[0].focus();
            }
        }
    }

    function setListenners() {
        // Il faut repositionner l'autocomplete quand on scroll
        scrollContainer.on('scroll.exLov', positionAutocomplete);
        // Quand la fenêtre change de taille, le champ risque de changer de position et de taille. On doit donc
        // repositionner l'autocomplete.
        window.addEventListener('resize', positionAutocomplete);

        windowElement.on('focusin.exLov click.exLov', (event: JQueryEventObject) => {
            if ((!event.originalEvent || ((event.originalEvent as any).exLov !== id)) &&
                !angular.element(event.target).closest('.ex-recherche-menu-content').length &&
                !autocomplete.find(event.target).length && !$element.find(event.target).length) {
                closeAutocomplete();
            }
        });

        autocomplete.on('focusin.exLov click.exLov', (event: JQueryEventObject) => {
            // On identifie les événements qui proviennent de l'autocomplete, pour que l'on sache ne pas fermer l'autocomplete
            if (event.originalEvent) {
                (event.originalEvent as any).exLov = id;
            }
        }).on('input.exLov click.exLov', () => autocomplete.find('ex-recherche input').focus()).on('keydown.exLov', 'ex-recherche input', (event: JQueryEventObject) => {
            switch (event.which) {
                case KeyCodes.ENTER:
                    if (vm.opened && !vm.multiOccurrence.fetchingDataList) {
                        event.originalEvent.preventDefault()
                        selectSuggestion(vm.suggestions[vm.activeSuggestion]);
                    }
                    break;
                case KeyCodes.UP:
                    if (vm.opened && vm.activeSuggestion > 0) {
                        $scope.$applyAsync(() => {
                            vm.activeSuggestion--;
                            scrollItemIntoView();
                        })
                    }
                    break;
                case KeyCodes.DOWN:
                    if (!vm.opened) {
                        openAutocomplete();
                    } else if (vm.suggestions && vm.activeSuggestion < vm.suggestions.length - 1) {
                        $scope.$applyAsync(() => {
                            vm.activeSuggestion++;
                            scrollItemIntoView();

                            if (vm.hasMore && vm.activeSuggestion === vm.suggestions.length - 1) {
                                vm.getMore()
                            }
                            
                        });
                    }
                    break;
                case KeyCodes.ESCAPE:
                    event.originalEvent.preventDefault()
                    $scope.$applyAsync(() => {
                        closeAutocomplete();
                    });
                    break;
            }
        }).on('focusin', '.ex-input-lov-focus-trap', (event: JQueryEventObject) => {
            const isLast = angular.element(event.target).hasClass('ex-input-lov-focus-trap--end');
            focusElement(isLast ? 'next' : 'prev');
            closeAutocomplete();
        });

        autocompleteContent.on('scroll', (e: JQueryEventObject) => {
            const scrollElement = e.currentTarget as HTMLDivElement;
            $scope.$applyAsync(() => {
                if ((scrollElement.scrollHeight - scrollElement.offsetHeight - scrollElement.scrollTop) < 10 && vm.suggestions && vm.hasMore) {
                    vm.getMore();
                }
            });
        });
    }

    function unsetListenners() {
        scrollContainer.off('.exLov');
        windowElement.off('.exLov');
        window.removeEventListener('resize', positionAutocomplete);
        autocomplete.off('.exLov');
        if (autocompleteContent) { autocompleteContent.off('scroll'); }
    }

    function getMore() {
        if (!vm.gettingMore) {
            vm.multiOccurrence.etatSelected.pagination.pageCourante++;
            vm.gettingMore = true;
        }
    }

    function handleSelectionSuggestion(event: JQueryEventObject, suggestion: any) {
        (<any>event.originalEvent).exInputLovClicked = true;

        return selectSuggestion(suggestion);
    }

    function selectSuggestion(suggestion: any, options: { focus: boolean } = { focus: true }) {
        vm.formCtrl[vm.nameElement].$viewValue = suggestion[getCleValeur()];
        vm.formCtrl[vm.nameElement].$commitViewValue();
        vm.code = suggestion[codeCol];
        vm.capture = "";
        vm.recherche = ""
        vm.hint = '';
        vm.suggestions = null;
        vm.suggestion = suggestion;

        // On insère les valeurs additionnelles
        if (vm.dataType.params.autresValeurs) {
            Object.keys(vm.dataType.params.autresValeurs).forEach((champAutreValeur: string) => {
                const champAutreValeurLov = vm.dataType.params.autresValeurs[champAutreValeur];
                vm.data[champAutreValeur] = suggestion[champAutreValeurLov];
            });
        }

        // On insère les valeurs d'affection
        if (vm.dataType.params.affectations) {
            Object.keys(vm.dataType.params.affectations).forEach((champAffectation: string) => {
                const champAffectionLov = vm.dataType.params.affectations[champAffectation];
                vm.data[champAffectation] = suggestion[champAffectionLov];
            });
        }

        // On défini les valeurs de restriction
        if (colsRestrictions.length) {
            colsRestrictions.forEach((col: string) => {
                vm.data[`${vm.col}__${col}`] = suggestion[restrictions[col].nom];
            });
        }

        // On défini les valeurs de raffinement
        if (colsRaffinements.length) {
            colsRaffinements.forEach((col: string) => {
                vm.data[`${vm.col}__${col}`] = suggestion[raffinements[col].nom];
            });
        }

        // On insère les champs de description. C'est nécessaire pour la sauvegarde des valeurs sur les états
        vm.dataType.params.description.forEach((col: string) => {
            vm.data[`${vm.col}__${col}`] = suggestion[col];
        });

        closeAutocomplete();

        if (options.focus && !isMobile) {
            if (vm.dataType.params.sousType !== "lovEntiteExterne") {
                captureInput.trigger("focus");
            }
        }

        vm.formCtrl[vm.nameElement].$setValidity('captureInvalide', true);
        //si on change d'entite externe on doit vider les champs du formulaire qui ont la propriete viderchamp=true
        if (vm.nameElement === "refcleint") {
            $rootScope.$broadcast("exEntiteExterne.initForm")
        }
    }

    function scrollItemIntoView() {
        if (vm.suggestions && vm.activeSuggestion === vm.suggestions.length) {
            autocompleteContent.scrollTop(autocompleteContent[0].scrollHeight);
        } else {
            const position = autocompleteContent.find('.ex-input-lov-item').eq(vm.activeSuggestion).position();

            if (position) {
                const scrollTop = autocompleteContent.scrollTop();
                const height = autocompleteContent.outerHeight();

                if (position.top > height - 35) {
                    autocompleteContent.scrollTop(scrollTop + position.top - height + 35);
                } else if (position.top < 0) {
                    autocompleteContent.scrollTop(scrollTop + position.top);
                }
            }
        }
    }

    function openAutocomplete() {
        if (!vm.opened) {
            vm.recherche = vm.capture === vm.data[`${vm.col}__${vm.dataType.params.description[0]}`] ? "" : vm.capture;
            vm.multiOccurrence.preventDataListUpdate = false;
            vm.multiOccurrence.etatSelected.pagination.pageCourante = 1;
            vm.multiOccurrence.dataListError = false;
            removeFiltre()

            vm.suggestions = null;

            $timeout(() => {
                vm.opened = true;
                vm.multiOccurrence.fetchDataList();
            }, 250)

            positionAutocomplete();

            setListenners();
            vm.capturing = false;
        }
    }

    function closeAutocomplete() {
        vm.opened = false;
        vm.formCtrl[vm.nameElement].$setTouched();
        vm.formCtrl[vm.nameElement].$setDirty();

        unsetListenners();
    }

    function startCapture() {
        if (vm.readonly) {
            focusElement('next');
            return;
        }

        vm.multiOccurrence.preventDataListUpdate = false;
        vm.capturing = true;
        vm.capture = vm.code || '';
        vm.hint = '';

        if (vm.opened) {
            closeAutocomplete();
            positionCaptureCaret();
        } else {
            ($element.find('.ex-input-lov-input')[0] as HTMLInputElement).select();
        }
    }

    function positionCaptureCaret() {
        const input = $element.find('.ex-input-lov-input')[0] as HTMLInputElement;
        input.selectionStart = input.value.length;
        input.selectionEnd = input.value.length;
    }

    function toggleSuggestions() {
        if (!vm.opened) {
            openAutocomplete();
        } else {
            closeAutocomplete();
        }
    }

    function handleInputKey(event: JQueryEventObject) {
        switch (event.which) {
            case KeyCodes.DOWN:
            case KeyCodes.UP:
                if (!event.shiftKey && !event.metaKey && !event.ctrlKey) {
                    toggleSuggestions();
                }
                break;
            default:
                if (vm.hasMissingRestriction) {
                    event.preventDefault()
                }
        }
    }

    function handleCaptureChange() {
        if (vm.capture.trim().length > 0) {
            vm.disableSelectionMultiple = true
        } else {
            vm.disableSelectionMultiple = false
        }

        vm.hint = '';
        vm.code = vm.capture;
        vm.suggestions = null;
        captureIsDirty = true;

        // On vide la description
        for (const col of vm.dataType.params.description) {
            delete vm.data[`${vm.col}__${col}`];
        }

        // On vide les valeurs additionnelles
        if (vm.dataType.params.autresValeurs) {
            Object.keys(vm.dataType.params.autresValeurs).forEach((champAutreValeur: string) => {
                delete vm.data[champAutreValeur];
            });
        }

        // On vide les valeurs de restriction
        if (colsRestrictions.length) {
            colsRestrictions.forEach((col: string) => {
                delete vm.data[`vm.data.${vm.col}__${col}`];
            });
        }

        // On vide les valeurs de raffinement
        if (colsRaffinements.length) {
            colsRaffinements.forEach((col: string) => {
                delete vm.data[`vm.data.${vm.col}__${col}`];
            });
        }

        vm.value = undefined;

        if (vm.capture && !vm.opened) {
            vm.multiOccurrence.etatSelected.pagination.pageCourante = 1;

            let value = vm.capture;
            if (vm.col.match(/^peccleint/) && vm.capture.length === 4 && !vm.capture.includes('-')) {
                value = `${vm.capture[0]}${vm.capture[1]}-${vm.capture[2]}${vm.capture[3]}`;
            } else if (vm.longueurFixe) {
                value = vm.capture.padStart(vm.longueurFixe, '0');
            }
            removeFiltre()
            vm.filtre = new Filtre({
                colonne: vm.dataType.params.description[0],
                operateur: Operateur.COMMENCE_PAR,
                valeur: value
            });
            vm.multiOccurrence.etatSelected.addFiltre(vm.filtre);
        }
    }

    function handleCaptureEnd(event: JQueryEventObject) {
        if (pendingCapture) {
            return;
        }

        if (!vm.capture && vm.code) {
            vm.capture = vm.code
        }

        const control: INgModelController = vm.formCtrl[vm.nameElement];
        const captureMatch = getCaptureMatch();

        if ((control.$invalid || captureIsDirty) && (!vm.capture || (vm.suggestions && !captureMatch))) {
            resetFormControl(control);
        }
        if (angular.element(event.relatedTarget).hasClass('ex-input-lov-clear')) {
            captureIsDirty = false
            return
        }
        // Si la capture est terminée, un qu'un seul résultat est trouvé, on peut le sélectionner
        if (vm.capturing && captureMatch) {
            vm.selectSuggestion(captureMatch, { focus: false });
            const focusLovCtrl = angular.element($document[0].activeElement.closest('ex-input-lov')).controller('exInputLov');

            if (focusLovCtrl && focusLovCtrl.col === vm.col) {
                // Gère le focus
                focusTarget(event);
            } else {
                vm.capturing = false;
            }
        } else if (!vm.opened && vm.multiOccurrence.fetchingDataList) {
            pendingCapture = true;
            vm.multiOccurrence.once('dataListUpdate', () => {
                pendingCapture = false;
                handleCaptureEnd(event);
            });
        } else if (vm.capture && !vm.value) {
            event.preventDefault();
            control.$setTouched();
            control.$setDirty();
            control.$setValidity('captureInvalide', false);
        } else {
            vm.capturing = false;
        }

        if (!pendingCapture) {
            captureIsDirty = false;
        }
    }

    function resetFormControl(control: INgModelController) {
        control.$viewValue = null;
        vm.value = null;
        control.$commitViewValue();
    }

    function getCaptureMatch() {
        if (!vm.suggestions) {
            return null;
        } else if (vm.suggestions && vm.suggestions.length === 1) {
            return vm.suggestions[0];
        } else {
            const codeCol = vm.dataType.params.description[0];
            return vm.suggestions.find((suggestion) => String(suggestion[codeCol]).toLowerCase() === vm.capture.toLowerCase());
        }
    }

    function focusTarget(event: JQueryEventObject) {
        if (event.type === 'keydown' && event.which === KeyCodes.TAB) {
            focusElement(event.shiftKey ? 'prev' : 'next');
        }
        if (event.type && event.which === KeyCodes.ENTER) {
            captureInput.focus();
        } else {
            vm.capturing = false;
        }
    }

    function focusElement(dir: 'prev' | 'next') {
        const focusableElements = $document.find('button,input,textarea,a,[tabindex][tabindex!="-1"]').filter(':visible');
        const position = focusableElements.index(captureInput);
        let nextPosition;

        if (dir === 'next') {
            nextPosition = position + 1;
        } else {
            nextPosition = position - 1;
        }

        if (nextPosition < 0) {
            nextPosition = 0;
        } else if (nextPosition > focusableElements.length) {
            nextPosition = 0;
        }

        let elementToFocus = focusableElements.eq(nextPosition);
        // On ne doit pas focuser le bouton clear, car il va disparaître
        if (elementToFocus.hasClass('ex-input-lov-clear')) {
            elementToFocus = focusableElements.eq(nextPosition + 1);
        }

        if (elementToFocus[0].tagName === 'MD-CHECKBOX') {
            elementToFocus[0].classList.add('md-focused');
        }

        elementToFocus.focus();
    }

    function clearCapture() {
        vm.capture = '';
        vm.recherche = ""
        removeFiltre()
        vm.handleCaptureChange();
        captureInput.focus();
    }

    function removeFiltre() {
        if (vm.filtre) {
            const indexOf = vm.multiOccurrence.etatSelected.filtres.findIndex(f => f.id === vm.filtre.id)
            if (indexOf !== -1) {
                vm.multiOccurrence.etatSelected.filtres.splice(indexOf, 1)
            }
            vm.filtre = undefined
        }
    }

    function isInvalid() {
        return Boolean(
            vm.formCtrl[vm.nameElement].$invalid &&
            (vm.formCtrl[vm.nameElement].$touched || vm.formCtrl.$submitted)
        );
    }

    function lostFocus() {
        //on met toString() dans le cas d'avoir un type different de string
        if (vm.capture && vm.capture.toString().trim().length === 0) {
            vm.formCtrl[vm.nameElement].$setValidity('captureInvalide', true);
        }

    }

    function isDisabled() {
        return vm.disabled || vm.hasMissingRestriction;
    }

    function displayMessageMessage() {
        return Boolean(
            vm.formCtrl[vm.nameElement].$error.required &&
            (vm.formCtrl[vm.nameElement].$touched || vm.formCtrl.$submitted)
        );
    }

    function displayMessageCaptureInvalid() {
        return vm.formCtrl[vm.nameElement].$error.captureInvalide &&
            (vm.formCtrl[vm.nameElement].$touched || vm.formCtrl.$submitted);
    }

    function showLibelle(index: number) {
        return !vm.nombreLibellesDetailsCacher || index > vm.nombreLibellesDetailsCacher;
    }

    function afficherDetailLov(suggestion: any, col: string) {
        if (vm.multiOccurrence.dataTypes[col] instanceof BooleanDataType) {
            return suggestion[col];
        } else {
            return suggestion[col] != null;
        }
    }

    function getResourceParamsValues() {
        let resourceParams;
        if (vm.resourceParams instanceof Function) {
            resourceParams = vm.resourceParams(vm.data);
        } else {
            resourceParams = vm.resourceParams || {};
        }

        if (vm.srccodref && !resourceParams.srccod) {
            resourceParams.srccod = vm.srccodref;
        }
        resourceParams.isInputLov = 1;
        return { ...resourceParams, ...vm.restrictionValues }
    }
}
