/**
 * Composant représentant un input-date
 *
 */
import {IComponentOptions, module} from 'angular';
import Binding from '../../helpers/binding.helper';
import InputAuthCodeController from './ex-input-auth-code.controller';

export default module('core.components.ex-input-auth-code', []).component('exInputAuthCode', InputAuthCodeFactory());

function InputAuthCodeFactory(): IComponentOptions {
    return {
        bindings: Binding(
            Binding.NAME_ELEMENT,
            Binding.LIBELLES,
            Binding.VALUE,
            Binding.DISABLED,
            Binding.DATA.OPTIONAL,
            Binding.READONLY.OPTIONAL,
            Binding.DATA_TYPE.OPTIONAL,
            {
                formCtrlAuth:'=?formCtrlAuth',            
            }
        ),
        controllerAs: 'vm',
        controller: InputAuthCodeController,
        template: require('./ex-input-auth-code.html')
    };
}
