/**
 * Permet d'ajouter un break line lorsque le texte est long 
 */
export function breakTexteInLines(input: String, texteLigneMax: number, breakLineString: string) {
    let result = '';
    const mots = input.split(' ');
    let ligneCurrante = '';
    mots.forEach((mot) => {
        if ((ligneCurrante + mot).length > texteLigneMax) {
            result += ligneCurrante + breakLineString;
            ligneCurrante = mot + ' ';
        } else {
            ligneCurrante += mot + ' ';
        }
    });

    result += ligneCurrante.trim();
    return result;
}