import {
    IAugmentedJQuery,
    IComponentController,
    IFormController,
    ILogService,
    INgModelController,
    IQService,
    IScope,
    ITimeoutService
} from 'angular';
import { IComposantMonoOccurrence } from '../ex-mono-occurrence/ex-mono-occurrence.controller';
import { IMonoOccurrence, IMonoOccurrenceClass } from '../../services/mono-occurrence.service';
import { IMultiOccurrence } from '../../services/multi-occurrence.service';
import { FormulaireElement, IFormulaire } from '../../services/formulaire/formulaire.service';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IValidateItemResource } from '../../resources/validate-item.resource';
import { IChangeItemResource } from '../../resources/change-item.resource';
import { IApiError } from '../../interfaces/api-error.interface';
import { IFormulaireItemSautDeLigneClass } from '../../services/formulaire/formulaire-item-saut-de-ligne.service';
import { IComposantMonoOccurrenceEcran } from '../ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import { INotificationHandler } from '../../services/utils/notification-handler.service';
import { IDataLinker } from '../../services/data-linker.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IParametresSecuriteService } from '../../services/parametres-securite.service';
import { IFormulaireGroupe, IFormulaireGroupeClass } from '../../services/formulaire/formulaire-groupe.service';
import { IFormulaireItem, IFormulaireItemClass } from '../../services/formulaire/formulaire-item.service';
import { IComportement } from '../../services/comportements.service';
import { IImputationDataTypeClass } from '../../services/data-types/imputation-data-type.service';
import { IComposantCriteresSuggeres } from '../ex-criteres-suggeres/ex-criteres-suggeres.controller';
import { IComposantPlaquettesFonctionsTransversales } from '../../../apps/sofe/components/ex-plaquettes-fonctions-transversales/ex-plaquettes-fonctions-transversales.controller';
import { IMessagesErreurs } from '../../../apps/sofe/components/ex-multi-occurrence-analyse/ex-multi-occurrence-analyse.controller';
import { IFormulaireGroupeAccordeonClass } from '../../services/formulaire/formulaire-groupe-accordeon.service';
import { IFormulaireGroupeConditionnelClass } from '../../services/formulaire/formulaire-groupe-conditionnel.service';
import { IComposantFonctionsTransversales } from '../../../apps/sofe/components/ex-fonctions-transversales/ex-fonctions-transversales.controller';
import { IEcranStateParamsService } from '../../services/utils/ecran-state-params.service';
import { IDialog } from '../../services/dialog.service';
import { IErrorTypes } from '../../constants/error-types.constant';

export interface IComposantFormulaire extends IComponentController {
    ecranContextCtrl: IEcranContextController;
    occurrence: IMonoOccurrence | IMultiOccurrence;
    formulaire: IFormulaire;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    criteresSuggeresCtrl: IComposantCriteresSuggeres;
    plaquettesFonctionsTransversalesCtrl: IComposantPlaquettesFonctionsTransversales;
    fonctionsTransversalesCtrl: IComposantFonctionsTransversales;
    data: any;
    messagesErreur: IMessagesErreurs;
    formData: any;
    name: string;
    monoOccurrenceCtrl: IComposantMonoOccurrence;
    formCtrl: IFormController;
    largeur: number;
    customFormulaire: boolean;
    hasZonePiecesJointes: boolean;
    indexFormulaire: number;

    //Permet d'empêcher une boucle infinie d'initialisation de formulaire
    defaultValuesInitDone: boolean;
    hasMessageErreur(): boolean;
}

/* @ngInject */
export default function FormulaireController($scope: IScope,
    defaults: IDefaultsService,
    $timeout: ITimeoutService,
    ImputationDataType: IImputationDataTypeClass,
    $element: IAugmentedJQuery,
    $q: IQService,
    $log: ILogService,
    ValidateItemResource: IValidateItemResource,
    ErrorTypes: IErrorTypes,
    ChangeItemResource: IChangeItemResource,
    FormulaireItem: IFormulaireItemClass,
    FormulaireItemSautDeLigne: IFormulaireItemSautDeLigneClass,
    FormulaireGroupe: IFormulaireGroupeClass,
    FormulaireGroupeAccordeon: IFormulaireGroupeAccordeonClass,
    FormulaireGroupeConditionnel: IFormulaireGroupeConditionnelClass,
    notificationHandler: INotificationHandler,
    dataLinker: IDataLinker,
    parametresSecurite: IParametresSecuriteService,
    ecranStateParams: IEcranStateParamsService,
    MonoOccurrence: IMonoOccurrenceClass,
    DialogAlert: IDialog,
    appName: string) {
    const vm: IComposantFormulaire = this;

    vm.$onInit = $onInit;
    vm.hasMessageErreur = hasMessageErreur;
    vm.defaultValuesInitDone = false;

    function $onInit() {
        defaults(vm, {
            name: 'formCtrl',
            customFormulaire: false
        });

        vm.isPortail = appName === "portail-employe"

        if (!vm.occurrence && !vm.monoOccurrenceCtrl) {
            return $log.error('Un Formulaire doit être contenu dans un MonoOccurrence ou se faire passer une occurrence');
        }

        if (!vm.occurrence && vm.monoOccurrenceCtrl) {
            vm.occurrence = vm.monoOccurrenceCtrl.monoOccurrence;
        }

        if (!vm.formulaire) {
            vm.formulaire = vm.occurrence.formulaire;
        }

        vm.hasZonePiecesJointes = hasZonePiecesJointesVisible();

        $scope.$watch('this[vm.name]', (newValue: IFormController) => {
            if (newValue) {
                vm.formCtrl = newValue;
            }
        });

        $scope.$watch(() => vm.formCtrl && Object.keys(vm.formCtrl).some(k => !k.startsWith("$")), (value) => {
            if (value) {
                defineChangeListeners();
                defineValidations();
            }
        })

        if (!vm.data) {
            $scope.$watch('vm.occurrence.data', (newValue: any) => {
                vm.data = { ...newValue };
                initData();
            });
        } else {
            $scope.$watch('vm.data', (newValue: any) => {
                if (newValue !== vm.formData && vm.criteresSuggeresCtrl) {
                    // Permet de travailler sur une copie au moment des critères suggérés
                    vm.data = { ...newValue };
                }
                if (vm.data.$resolved === undefined) {
                    vm.data.$resolved = true
                }

                initData();
            });
        }

        $scope.$watch("vm.fichier", () => {
            if (vm.fichier || vm.data.fichier) {
                vm.data.fichier = vm.fichier
            }
        })
    }

    $scope.$on('exEntiteExterne.initForm', function () {

        if (vm.monoOccurrenceCtrl) {
            try {
                //on cherche les champs qui ont la propriete viderByEntiteExterne=true ou un sous-formulaire
                vm.monoOccurrenceCtrl.monoOccurrenceOptions.formulaire.liste.filter((el: any) => el.viderByEntiteExterne || el.formulaire).map((el: any) => {
                    //formulaire principal
                    if (el.col) {
                        viderByEntiteExterneForm(el.col, el.default)
                    }
                    else {
                        //on se demande s'il s'agit d'un sous-formulaire
                        if (el.formulaire && el.formulaire.liste) {
                            //dans le sous-formulaire on va vider les champs qui ont la propriete viderByEntiteExterne=true
                            el.formulaire.liste.filter((sel: any) => sel.viderByEntiteExterne).map((v: any) => {
                                viderByEntiteExterneForm(v.col, v.default)
                            });
                        }

                    }
                })
            } catch (error) {
            }

        }
    });

    function viderByEntiteExterneForm(col: any, valeurDefault: any) {
        //on fait un loop des champs du formulaire afin de les vider
        Object.keys(vm.formData).forEach(key => {
            //s'il s'agit d'une lov on doit vider les champs liés
            if (key.includes(col)) {
                vm.formData[key] = (typeof valeurDefault !== "undefined") ? valeurDefault : null;
            }
        });
    }

    function initData() {
        if (vm.formData !== vm.data) {
            // On expose les données du formulaire puisqu'elles sont potentiellement clônées
            vm.formData = vm.data;
        }

        dataLinker.link($element, vm.formData, vm.ecranContextCtrl.stateParams, vm.ecranContextCtrl.ecranDetails);

        // On initialise les données avec les défauts
        vm.occurrence.ready.then(() => {
            // Dans de rares cas, par exemple dans un multi avec un accordéon contenant seulement un détail,
            // il n'y a pas de formulaire
            if (vm.formulaire && !vm.defaultValuesInitDone) {
                vm.formulaire.initFormData(vm.data, vm.occurrence.dataTypes);
                if (vm.occurrence instanceof MonoOccurrence) {
                    vm.occurrence.data = { ...vm.data };

                    // Important d'indiquer à tous que les données ont changées
                    // Permet de s'assurer que le link du dataLinker a bien été appelé
                    vm.occurrence.emit('dataUpdate');
                }
                vm.formCtrl.$setPristine();
                vm.formCtrl.$setUntouched();
                vm.defaultValuesInitDone = true;
            }
        });

        if (!vm.formulaire || (vm.formulaire.liste[0] instanceof FormulaireItemSautDeLigne && !vm.formulaire.piecesJointes)) {
            return vm.largeur = 100;
        } else if (vm.monoOccurrenceEcranCtrl && vm.monoOccurrenceEcranCtrl.contentWidth <= 980) {
            return vm.largeur = 50;
        } else {
            return vm.largeur = 40;
        }
    }

    vm.isUploadFichier = function isUploadFichier() {
        return vm.formulaire && (vm.formulaire.uploadFichier instanceof Function ? vm.formulaire.uploadFichier(vm.data) : vm.formulaire.uploadFichier)
    }

    /**
     * On un changeListener pour chaque champ pour lequel une procédure de changement est définie
     */
    function defineChangeListeners(form: IFormController = vm.formCtrl): void {
        if (vm.occurrence.listenedFields) {
            for (let key in form) {
                if (key.startsWith('$')) {
                    continue;
                } else if (form[key] && form[key].$$controls) {
                    // Formulaires imbriqués
                    defineChangeListeners(form[key]);
                } else if (!form.$$sofeChangeListenerDefined) {
                    defineFormChangeListeners(form);
                }
            }
        }
    }

    function defineFormChangeListeners(form: IFormController) {
        form.$$sofeChangeListenerDefined = true;

        const blocs = vm.occurrence.getNomSourceDetails().toLowerCase().split('-');
        blocs.shift()

        if (blocs[blocs.length - 1] === 'edition') {
            blocs.pop();
        }

        // On ajoute un _ à la fin
        if (blocs.length) {
            blocs.push('');
        }

        const prefix = blocs.join('_');

        vm.occurrence.listenedFields.filter((listenedField: string) => {
            if (!prefix) {
                // On accept seulement les préfixes de 1 lettre
                return !listenedField.includes('_') || listenedField.match(/^([a-z0-9])_([a-z0-9]+)$/i);
            } else {
                return listenedField.startsWith(prefix);
            }
        }).forEach((listenedField: string) => {
            const champ = listenedField.replace(new RegExp(`^${prefix}`), '');

            defineFieldChangeListener(champ, listenedField, form);
        });
    }

    function hasMessageErreur() {
        return Boolean(vm.messagesErreur && vm.messagesErreur[vm.data.$id] && vm.occurrence.cleint);
    }

    function hasZonePiecesJointesVisible() {
        return Boolean(vm.formulaire && vm.formulaire.piecesJointes && (vm.occurrence.id || !hasSaisieAssistee(vm.formulaire.liste)));
    }

    function hasSaisieAssistee(liste: Array<FormulaireElement>): boolean {
        return liste.filter((formulaireElement: FormulaireElement) => (formulaireElement instanceof FormulaireItem || formulaireElement instanceof FormulaireGroupe) && !isChampHidden(formulaireElement as IFormulaireItem)).some((champ: IFormulaireItem | IFormulaireGroupe) => {
            if (champ instanceof FormulaireItem) {
                return vm.occurrence.isChampSelectionMultipleAdvanced(champ);
            } else {
                return hasSaisieAssistee(champ.formulaire.liste);
            }
        });
    }

    function isChampHidden(champ: IFormulaireItem) {
        return Boolean(
            getHiddenValue(champ, vm.data) ||
            getHiddenValue(vm.occurrence.comportements[champ.col], vm.data)
        );
    }

    function getHiddenValue(champ: IFormulaireItem | IComportement, data: any) {
        if (!champ) {
            return false;
        } else if (champ.hidden instanceof Function) {
            return !data || champ.hidden(data);
        } else {
            return champ.hidden;
        }
    }

    function defineFieldChangeListener(col: string, listenedField: string, form: IFormController) {
        // On utilise un watch ici en raison des champs qui peuvent être cachés dans un FormulaireGroupeConditionnel
        $scope.$watch(() => form[col], () => {
            if (form[col]) {
                form[col].$viewChangeListeners.push(() => onViewValueChange(col, listenedField, form));
            }
        });
    }

    function onViewValueChange(col: string, listenedField: string, form: IFormController) {
        $timeout(() => {
            if (!vm.formCtrl.$$parentForm['$$sofeValidateItemsWaiting']) {
                invokeChangeListener(col, listenedField, form).catch((err: IApiError) => {
                    afficherValidationErreur(err, {
                        confirmAction() {
                            return invokeChangeListener(col, listenedField, form);
                        }
                    });
                });
            } else {
                onViewValueChange(col, listenedField, form);
            }
        });
    }

    function invokeChangeListener(col: string, listenedField: string, form: IFormController) {
        if (form[col].$valid) {
            if (!vm.formCtrl.$$parentForm['$$sofeChangeItemsWaiting']) {
                vm.formCtrl.$$parentForm['$$sofeChangeItemsWaiting'] = [col];
            } else {
                vm.formCtrl.$$parentForm['$$sofeChangeItemsWaiting'].push(col);
            }

            let srccodref;
            const ids = vm.occurrence.getParentIds();
            const nomSourceDetails = vm.occurrence.getNomSourceDetails();

            if ((nomSourceDetails.startsWith('lov-') || vm.plaquettesFonctionsTransversalesCtrl) && vm.occurrence.resourceParams) {
                srccodref = vm.occurrence.resourceParams.srccod;
            }

            const paramDefaults = {
                champ: listenedField,
                srccod: nomSourceDetails,
                ids: ids.join(','),
                ...ecranStateParams(vm.ecranContextCtrl.stateParams),
                ...parametresSecurite(vm.ecranContextCtrl.stateParams),
                srccodref
            };

            const formData = {
                ...vm.occurrence.resourceParams,
                ...(vm.occurrence as IMultiOccurrence).resourceParamsDynamique && (vm.occurrence as IMultiOccurrence).resourceParamsDynamique(vm.formData),
                ...vm.formData,
                // La valeur en cours n'a pas encore eu le temps d'être reflété dans formData
                [col]: form[col].$modelValue
            };

            return ChangeItemResource.save(paramDefaults, formData).$promise
                .then((result: any) => {
                    if (result.msgerr) {
                        const colCorrection = Object.keys(result).find(colCorrection => col === colCorrection);
                        if (colCorrection) {
                            modificationDonneeFormulaire(result, colCorrection);
                        }

                        afficherValidationErreur(result, {
                            lblMessage: result.msgerr
                        }, result.typmsg);
                    } else {
                        // On met à jour les données du formulaire avec le résultat de la procédure de changement
                        vm.occurrence.emit('exMenuItemActionDataUpdate', result);
                        Object.keys(result)
                            .filter((col) => !col.startsWith('$'))
                            .forEach((col) => modificationDonneeFormulaire(result, col));
                    }
                })
                .finally(() => {
                    const index = vm.formCtrl.$$parentForm['$$sofeChangeItemsWaiting'].indexOf(col);
                    vm.formCtrl.$$parentForm['$$sofeChangeItemsWaiting'].splice(index, 1);

                    if (!vm.formCtrl.$$parentForm['$$sofeChangeItemsWaiting'].length) {
                        delete vm.formCtrl.$$parentForm['$$sofeChangeItemsWaiting'];
                    }
                });
        } else {
            return $q.resolve();
        }
    }

    function modificationDonneeFormulaire(data: any, col: string) {
        if (vm.formCtrl[col]) {
            vm.formCtrl[col].$viewValue = data[col];
            vm.formCtrl[col].$commitViewValue();
            vm.formCtrl[col].$processModelValue();
            vm.formCtrl[col].$render();

            let formulaireItem = vm.formulaire.liste.find((formulaireItem: IFormulaireItem) => formulaireItem.col === col);
            if (!formulaireItem) {
                vm.formulaire.liste.forEach((formulaireItemGroupe) => {
                    if (formulaireItemGroupe instanceof FormulaireGroupeAccordeon ||
                        formulaireItemGroupe instanceof FormulaireGroupe ||
                        formulaireItemGroupe instanceof FormulaireGroupeConditionnel) {
                        formulaireItem = (<IFormulaireGroupe>formulaireItemGroupe).formulaire.liste.find((formulaireItem: IFormulaireItem) => formulaireItem.col === col);
                    }
                });
            }
            if (formulaireItem) {
                if (vm.occurrence.isChampNumber(<IFormulaireItem>formulaireItem)) {
                    // Pour les nombres, on doit s'assurer de passer dans tous les parsers
                    vm.formCtrl[col].$processModelValue();
                } else if ((<IFormulaireItem>formulaireItem).enableCount) {
                    // Pour les champs avec un compteur, on doit appeler la validation du compteur pour qu'il se mette à jour.
                    vm.formCtrl[col].$validators['md-maxlength'](vm.formCtrl[col].$modelValue, vm.formCtrl[col].$viewValue);
                }
            }
        } else {
            //Dans le cas où la donnée retournée n'est pas un champ de formulaire,
            // on veut tout de même l'ajouter aux données.
            vm.formData[col] = data[col];
        }
    }

    function afficherValidationErreur(error: IApiError, locals: { [index: string]: any } = {}, typmsg: string = null) {
        if (typmsg === ErrorTypes.ERREUR || typmsg === null) {
            notificationHandler.erreur({
                lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
                lblMessage: 'G_MSG_CHARG_DONNEES',
                error,
                ...locals
            });
        } else if (typmsg === ErrorTypes.AVERTISSEMENT) {
            notificationHandler.avertissement({
                lblMessage: error.msgerr,
                ...locals
            });
        }
    }

    /**
     * On ajoute les validateurs côté-serveur pour chacune des chacuns pour lesquels il y a une validation
     */
    function defineValidations(): void {
        vm.occurrence.ready.then(() => {
            defineFormValidations(vm.occurrence, vm.formCtrl);
        });
    }

    function defineFormValidations(occurrence: IMonoOccurrence | IMultiOccurrence, form: IFormController) {
        for (let col in form) {
            if (col.startsWith('$') || !form.hasOwnProperty(col)) {
                continue;
            } else if (occurrence.validatedFields.includes(col)) {
                defineFieldValidation(col, occurrence, form);
            } else if (form[col] && form[col].$$controls) {
                // Formulaires imbriqués
                defineFormValidations(occurrence, form[col]);
            }
        }
    }

    function defineFieldValidation(col: string, occurrence: IMonoOccurrence | IMultiOccurrence, form: IFormController): void {
        if (occurrence.dataTypes[col] instanceof ImputationDataType) {
            // Pour l'imputation, on appelle la validation uniquement lorsque les clés d'imputation changent.
            Object.keys(occurrence.dataTypes[col].params.cols).forEach((colImputation: string) => {
                const colToWatch = occurrence.dataTypes[col].params.cols[colImputation];
                form[colToWatch].$asyncValidators.validateApi = (modelValue: INgModelController) => validateApi(modelValue, col, occurrence, form);
            });
        } else {
            form[col].$asyncValidators.validateApi = (modelValue: INgModelController) => validateApi(modelValue, col, occurrence, form);
            const formulaireElement = (occurrence?.formulaire) ? occurrence.formulaire.flatListe.find(formulaireItem => formulaireItem instanceof FormulaireItem && formulaireItem.col === col) : undefined;

            if (formulaireElement && formulaireElement instanceof FormulaireItem && formulaireElement.isValidatingAtLoad && formulaireElement.isValidatingAtLoad(occurrence instanceof MonoOccurrence ? occurrence.data : occurrence.dataList[Number(form.$name.replace('gridFormRangee', ''))])) {
                form[col].$validate();
            }
        }
    }

    function validateApi(modelValue: INgModelController, col: string, occurrence: IMonoOccurrence | IMultiOccurrence, form: IFormController) {
        if (hasAccessValidation(occurrence, col, modelValue)) {
            if (occurrence.dataTypes[col] instanceof ImputationDataType && form[col].$error.validateImputation) {
                return $q.resolve();
            }

            const deferred = $q.defer();

            if (!vm.formCtrl.$$parentForm['$$sofeValidateItemsWaiting']) {
                vm.formCtrl.$$parentForm['$$sofeValidateItemsWaiting'] = [col];
            } else {
                vm.formCtrl.$$parentForm['$$sofeValidateItemsWaiting'].push('col');
            }

            // On doit envoyer toutes les valeurs courantes pour permettre les validations inter-champs, mais on doit
            // y ajouter la valeur courante du champ en modification
            const data: any = Object.assign({}, vm.formData);
            data[col] = modelValue;
            data.$editionRapideCtrl = undefined;

            const ids = occurrence.getParentIds();

            // On laisse tomber volontairement le dernier id pour que les valeurs validées soit celles de
            // l'enregistrement en cours, pas celles sauvegardées
            ids.pop();

            if (vm.fonctionsTransversalesCtrl) {
                if (!data.$linked) {
                    dataLinker.link($element, data, vm.ecranContextCtrl.stateParams, vm.ecranContextCtrl.ecranDetails);
                }

                ids.unshift(data.$ancetre.$id);
            }

            const paramDefaults = {
                champ: col,
                srccod: vm.occurrence.getNomSourceDetails(),
                srccodref: vm.ecranContextCtrl.stateParams.srccod,
                ids: ids.join(','),
                ...parametresSecurite(vm.ecranContextCtrl.stateParams)
            };

            ValidateItemResource.save(paramDefaults, data).$promise
                .then((result: any) => {
                    if (occurrence.dataTypes[col] instanceof ImputationDataType) {
                        vm.formCtrl[col].$setValidity('validateApi', !result[col]);
                        Object.keys(occurrence.dataTypes[col].params.cols).forEach((colImputation: string) => {
                            vm.formCtrl[colImputation].$setValidity('validateApi', !result[col]);
                        });
                    }

                    if (result[col]) {
                        // Dans le cas ou le formulaire n'existe plus
                        if (form[col]) {
                            // pour compatibilité avec l'ancienne méthode, au cas où j'en aurais oublier.
                            form[col].validateApi = result[col].value || result[col];
                            form[col]['$$SofeValidationMessage'] = result[col].value || result[col];
                            if (result[col].type) {
                                form[col]['$$SofeValidationType'] = result[col].type;
                            }
                            // On s'assure de l'affichage du message, même au chargement
                            form[col].$setTouched();
                            form[col].$setDirty();
                        }

                        if ([ErrorTypes.AVERTISSEMENT, ErrorTypes.INFORMATION].includes(form[col]['$$SofeValidationType'])) {
                            // Si "l'erreur" est un avertissement ou un information, on invalide pas le formulaire.
                            deferred.resolve();
                        } else {
                            deferred.reject();
                        }
                    } else {
                        const warningCol = col + '__warning';
                        if (result[warningCol]) {
                            DialogAlert.show({
                                locals: {
                                    avertissement: true,
                                    lblMessage: result[warningCol],
                                    lblTitre: 'G_LBL_AVERTISSEMENT'
                                }
                            });
                        }

                        if (form[col]) {
                            // on retire les propriété d'erreur parce que l'erreur as été pris en compte.
                            delete form[col]['$$SofeValidationMessage'];
                            delete form[col]['$$SofeValidationType'];
                        }
                        deferred.resolve();
                    }
                })
                .catch((err: IApiError) => {
                    afficherValidationErreur(err);
                    deferred.reject();
                })
                .finally(() => {
                    if (!vm.formCtrl.$$parentForm['$$sofeValidateItemsWaiting']) return
                    vm.formCtrl.$$parentForm['$$sofeValidateItemsWaiting'].splice(-1, 1);

                    if (!vm.formCtrl.$$parentForm['$$sofeValidateItemsWaiting'].length) {
                        delete vm.formCtrl.$$parentForm['$$sofeValidateItemsWaiting'];
                    }
                });

            return deferred.promise;
        } else {
            return $q.resolve();
        }
    }

    function hasAccessValidation(occurrence: IMultiOccurrence | IMonoOccurrence, col: string, modelValue: any) {
        const isMiseAJour = Boolean(vm.data[vm.occurrence.cleint]);
        return !vm.occurrence.isChampReadonly(vm.formulaire.flatListe.find((i: any) => i.col === col) as any, isMiseAJour, vm.data, vm.formulaire.securityCycle, vm.enregistrable) && Boolean(vm.criteresSuggeresCtrl || (occurrence.schema && (occurrence.schema[col] || (<any>occurrence.schema).noop)) || ((occurrence.dataTypes[col] instanceof ImputationDataType) && typeof modelValue !== 'undefined'));
    }
}