import { IDialogAllStatus } from '../../constants/dialog.constant';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IAugmentedJQuery, IComponentController, IFormController, IPromise, IQService, ITimeoutService } from 'angular';
import { IFormulaire } from '../../services/formulaire/formulaire.service';
import { IOccurrence } from '../../services/occurrence.service';
import { INotificationHandler } from '../../services/utils/notification-handler.service';
import { IFocusService } from '../../behaviors/ex-focus/ex-focus.behavior';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { MenuElement } from '../../services/menu/menu.service';
import { ITypesFichiersFunction } from "../../services/menu/menu-item-action-importation.service";

interface IDialogFormulaire extends IComponentController {
    lblTitre: string;
    lblCancel: string;
    lblConfirm: string;
    lblConfirmAndNext: string;
    customClass: string;
    confirmAction: (data?: any) => IPromise<any>;
    icon: string;
    largeur: number;
    closeOnError: boolean;
    hideCancelAction: boolean;
    showConfirmAction: boolean;
    formulaire: IFormulaire;
    occurrence: IOccurrence;
    data: any;
    formData: any;
    formCtrl: IFormController;
    savingAndNext: boolean;
    fichier: File;
    confirm(): void | IPromise<any>;
    confirmAndNext(): void;
}

export interface IDialogFormulaireOptions {
    confirmAction?: (data?: any) => IPromise<any>;
    lblTitre?: string;
    lblMessageInfo?: string;
    largeur?: number;
    hideCancelAction?: boolean;
    showConfirmAction?: boolean;
    lblCancel?: string;
    lblConfirm?: string;
    lblConfirmAndNext?: string;
    icon?: string;
    messagesErreur?: any;
    closeOnError?: boolean;
    formulaire: IFormulaire;
    occurrence: IOccurrence;
    data: any;
    customClass?: string;
    typesFichiers?: Array<string> | ITypesFichiersFunction;
    ecranContext?: IEcranContextController;
    actionsSecondaires?: Array<MenuElement>;
    authValiderCode?: boolean;
    authBlocTitre?: string;
    bloc?: string;
    confirm?(): void | IPromise<any>;
    confirmAndNext?(): void;
}

/* @ngInject */
export default function DialogFormulaireController(defaults: IDefaultsService,
    DialogStatus: IDialogAllStatus,
    notificationHandler: INotificationHandler,
    exFocus: IFocusService,
    $element: IAugmentedJQuery,
    $timeout: ITimeoutService,
    $q: IQService) {
    const vm: IDialogFormulaire = this;
    const lblDefaultConfirm = 'G_LBL_BTN_APPLIQUER';
    const lblDefaultCancel = 'G_LBL_BTN_ANNULER';

    vm.$onInit = function $onInit() {
        defaults(vm, {
            lblConfirm: lblDefaultConfirm,
            lblCancel: lblDefaultCancel,
            closeOnError: false,
            data: {}
        });

        vm.confirm = confirm;
        vm.confirmAndNext = confirmAndNext;

        if (vm.largeur) {
            vm.largeur = (vm.largeur <= 80) ? vm.largeur : 80;
            $element.find('md-dialog').css('min-width', `${vm.largeur}%`);
            $element.find('md-dialog').css('max-width', `${vm.largeur}%`);
        }

        if (vm.customClass) {
            $element.find('md-dialog').addClass(vm.customClass);
        }
    }

    function confirm(): any {
        vm.formCtrl.$setSubmitted();
        if (!vm.formCtrl.$valid) {
            return;
        }
        //valider si l'authcode existe
        if (vm?.formCtrl?.authcode) {
            Object.assign(vm.formData, { ...vm.formData, authcode: vm?.formCtrl?.authcode.$viewValue })
        }

        if (vm.confirmAction) {
            const data = (vm.fichier) ? { fichier: vm.fichier, ...vm.formData } : vm.formData;
            return vm.confirmAction(data);
        } else {
            return $q.resolve(vm.formData);
        }
    }

    function confirmAndNext(): void {
        vm.formCtrl.$setSubmitted();
        if (!vm.formCtrl.$valid) {
            return;
        }

        vm.savingAndNext = true;
        vm.confirmAction(vm.formData)
            .then(() => {
                const defaultValues = { ...vm.data };
                notificationHandler.succes('G_MSG_ACTION_SUCCES');

                vm.formData = {};
                vm.data = defaultValues;
                vm.formCtrl.$setPristine();
                vm.formCtrl.$setUntouched();
                exFocus($element.find('.ex-dialog-content'));
            })
            .finally(() => {
                $timeout(() => {
                    // Certains champs en valeur de défaut tombent parfois invalide après l'enregistrement, on les revalide donc pour être sûr.
                    vm.formCtrl.$getControls().forEach((control: any) => control.$validate());
                });
                vm.savingAndNext = false;
            });
    }
}
