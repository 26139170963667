import * as angular from 'angular';
import { IPromise, IQService, module } from 'angular';
import { IMenuItem, IMenuItemClass, IMenuItemOptions } from './menu-item.service';
import { IBoutonActionResource } from '../../resources/bouton-action.resource';
import { INotificationHandler } from '../utils/notification-handler.service';
import { IFonctionConditionAffichage, IMonoOccurrence } from '../mono-occurrence.service';
import { IMultiOccurrence, IMultiOccurrenceClass } from '../multi-occurrence.service';
import { IDialog } from '../dialog.service';
import { ISourceDetails } from '../../resources/source-details.resource';
import { IFormulaire } from '../formulaire/formulaire.service';
import { IOccurrence } from '../occurrence.service';
import { IDialogFormulaireOptions } from '../../dialogs/dialog-formulaire/dialog-formulaire.controller';
import { IFilterLibelle } from '../../filters/ex-libelle.filter';
import { IDialogAllStatus } from '../../constants/dialog.constant';
import { IApiError } from '../../interfaces/api-error.interface';
import { IParametresSecuriteService } from '../parametres-securite.service';
import { IData } from '../data-linker.service';
import { MenuElement } from './menu.service';
import IDialogOptions = angular.material.IDialogOptions;
import { IErrorTypes } from '../../constants/error-types.constant';

export interface IMenuItemActionClass {
    new(lblTitre: string, fonction: string, parametresGetter: IMenuItemActionParametres, options: IMenuItemActionOptions): IMenuItemAction;
    new(fonction: string, parametresGetter: IMenuItemActionParametres, options: IMenuItemActionOptions): IMenuItemAction;
}

export interface IMenuItemAction extends IMenuItem {
    readonly saveFirst: boolean;
}

interface IMenuItemActionParametres {
    (data: object): object;
}

export interface IMenuItemActionOptions extends IMenuItemOptions {
    successAction?: (response?: { [key: string]: any }, occurrence?: IOccurrence, params?: { [key: string]: any }, data?: any) => void;
    errorAction?: (response: IApiError) => void;
    champsRechargement?: Array<string>;
    lblMessageSuccess?: string;
    saveFirst?: boolean;
    messageConfirmation?: string | IMessageConfirmation;
    formulaireConfirmationAction?: IFormulaireConfirmationAction;
    rejectOnError?: boolean;
    reloadOnError?: boolean;
}

interface IMessageConfirmation {
    message: string;
    condition?: (data: IData) => boolean;
}

export interface IFormulaireConfirmationAction {
    lblTitre: string;
    customClass?: string;
    formulaire: IFormulaire;
    lblConfirm?: string;
    lblConfirmAndNext?: string;
    largeurFormulaire?: number;
    actionsSecondaires?: Array<MenuElement>;
    conditionAffichage?: boolean | IFonctionConditionAffichage;
    authValiderCode?: boolean;
    authBlocTitre?: string;
    bloc?: string;
}

export default module('core.services.menu-item-action', []).factory('MenuItemAction', MenuItemActionFactory);

/* @ngInject */
function MenuItemActionFactory(MenuItem: IMenuItemClass,
    DialogConfirm: IDialog,
    DialogFormulaire: IDialog,
    DialogStatus: IDialogAllStatus,
    $q: IQService,
    BoutonActionResource: IBoutonActionResource,
    notificationHandler: INotificationHandler,
    MultiOccurrence: IMultiOccurrenceClass,
    exLibelleFilter: IFilterLibelle,
    ecranSourceDetails: ISourceDetails,
    ErrorTypes: IErrorTypes,
    parametresSecurite: IParametresSecuriteService) {

    class MenuItemAction extends MenuItem implements IMenuItemAction {
        readonly saveFirst: boolean;

        constructor(lblTitre: string, fonction: string, parametresGetter: IMenuItemActionParametres, options: IMenuItemActionOptions) {
            // On détermine si un lblTitre a été passé
            if (typeof arguments[1] !== 'string') {
                lblTitre = '';
                fonction = arguments[0];
                parametresGetter = arguments[1];
                options = arguments[2];
            }
            super(lblTitre,
                (event: MouseEvent, data: any, occurrence: IMonoOccurrence | IMultiOccurrence) => executeAction(event, data, parametresGetter, fonction, options, occurrence),
                Object.assign({
                    fonction,
                    lblTitreValidation: 'G_LBL_MOD_ERREUR_TITRE'
                }, options));

            this.saveFirst = options.saveFirst;
        }
    }
    return MenuItemAction;

    function executeAction(event: MouseEvent,
        data: any,
        parametresGetter: IMenuItemActionParametres,
        fonction: string,
        options: IMenuItemActionOptions,
        occurrence: IMonoOccurrence | IMultiOccurrence) {
        const params: any = parametresGetter(data);
        const ids = occurrence.getParentIds();
        const id = params.id || data && data[occurrence.cleint];

        ids.pop();

        if (id) {
            ids.push(id);
        }

        if (occurrence instanceof MultiOccurrence && occurrence.criteresSuggeres) {
            data = { ...occurrence.etatSelected.criteresSuggeresData, ...data };
        }

        params.fonction = fonction;

        const securityParams = {
            ids: ids.join(','),
            srccod: occurrence.getNomSourceDetails(),
            ...parametresSecurite(occurrence.stateParams, occurrence.ecranDetails)
        };

        return saveAction(options, occurrence, params, data, securityParams, event)
            .then((result: any) => {
                const defaultLblMessage = (options.lblMessageSuccess) ? options.lblMessageSuccess : 'G_MSG_ACTION_SUCCES';

                const msg = result.msg || exLibelleFilter(defaultLblMessage);

                if (!result.typmsg) {
                    notificationHandler.succes(msg);
                } else if (result.typmsg === ErrorTypes.INFORMATION) {
                    notificationHandler.info({
                        lblMessage: msg
                    });
                } else if (result.typmsg === ErrorTypes.AVERTISSEMENT) {
                    notificationHandler.avertissement({
                        lblMessage: msg
                    });
                } else if (result.typmsg === ErrorTypes.ERREUR) {
                    notificationHandler.erreur({
                        lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
                        lblMessage: msg
                    });
                }

                if (options.champsRechargement) {
                    occurrence.emit('exMenuItemActionDataUpdate', result);
                }
            })
            .catch((error: any) => {
                //si la propriete existe on ajoute le flg afin d'executer le fetchdatalist
                if (options.reloadOnError) {
                    error.reloadOnError = 1;
                }
                if (options.errorAction) {
                    options.errorAction(error)
                } else if (options.rejectOnError) {
                    return $q.reject(error); // On laisse les composants gérer l'affichage du message d'erreur
                } else if (error !== DialogStatus.FERMER) {
                    afficherMessageErreur(error, event);
                }
            });
    }

    function saveAction(options: IMenuItemActionOptions, occurrence: IOccurrence, params: any, data: any, securityParams: any, event: MouseEvent) {
        let stepsQueue: IPromise<any> = $q.resolve();
        const messageConfirmation = typeof options.messageConfirmation === 'string' ? { message: options.messageConfirmation } : options.messageConfirmation;

        const hasMessageConfirmation = Boolean(
            options.messageConfirmation &&
            (!messageConfirmation.condition || messageConfirmation.condition(data))
        );

        if (hasMessageConfirmation) {
            const message = messageConfirmation.message;

            let messageDescription = occurrence.messages[message] ? occurrence.messages[message] : ecranSourceDetails.messages[message];
            const dialogOptions: IDialogOptions = {
                locals: {
                    icon: 'help',
                    lblTitre: exLibelleFilter('G_LBL_QUESTION'),
                    lblDescription: messageDescription,
                    lblConfirm: exLibelleFilter('G_LBL_OUI'),
                    ecranContext: {
                        stateParams: occurrence.stateParams,
                        ecranDetails: occurrence.ecranDetails,
                        ecranSourceDetails: occurrence.ecranSourceDetails
                    }
                }
            };

            stepsQueue = stepsQueue.then(() => DialogConfirm.show(dialogOptions));
        }

        if (options.formulaireConfirmationAction && shouldShowFormulaireConfirmation(options.formulaireConfirmationAction, occurrence, data)) {
            const dialogOptions: IDialogFormulaireOptions = {
                lblTitre: exLibelleFilter(options.formulaireConfirmationAction.lblTitre, occurrence.libelles),
                lblConfirm: options.formulaireConfirmationAction.lblConfirm || 'G_LBL_BTN_CONFIRMER',
                lblConfirmAndNext: options.formulaireConfirmationAction.lblConfirmAndNext,
                formulaire: options.formulaireConfirmationAction.formulaire,
                icon: (typeof options.icon === 'function') ? options.icon(data) : options.icon,
                largeur: options.formulaireConfirmationAction.largeurFormulaire,
                customClass: options.formulaireConfirmationAction.customClass,
                authValiderCode: options?.formulaireConfirmationAction?.authValiderCode,
                authBlocTitre: exLibelleFilter(options?.formulaireConfirmationAction?.authBlocTitre, occurrence.libelles),
                bloc: options?.formulaireConfirmationAction?.bloc,
                data: { ...params, ...data },
                occurrence: occurrence,
                closeOnError: false,
                ecranContext: {
                    stateParams: occurrence.stateParams,
                    ecranDetails: occurrence.ecranDetails,
                    ecranSourceDetails: occurrence.ecranSourceDetails
                },
                actionsSecondaires: options.formulaireConfirmationAction.actionsSecondaires,
                confirmAction: (formData: any) => {
                    return save({ ...securityParams, ...params }, Object.assign({}, (data || {}), (formData || {})), options.successAction, occurrence)
                        .catch((err: IApiError) => {
                            //On prend l'erreur en charge ici pour éviter la fermetture du DialogFormulaire
                            afficherMessageErreur(err, event);
                            return $q.reject(err);
                        });
                }
            };

            stepsQueue = stepsQueue.then(() => DialogFormulaire.show({ locals: dialogOptions, multiple: true }));
        } else {
            return stepsQueue = stepsQueue.then(() => save({ ...securityParams, ...params }, data, options.successAction, occurrence));
        }

        return stepsQueue;
    }

    function save(params: any, data: any, successAction: Function, occurrence: IOccurrence) {
        return BoutonActionResource.save(params, data).$promise
            .then((result: any) => {
                if (successAction) {
                    successAction(result, occurrence, params, data);
                }
                return result;
            });
    }

    function afficherMessageErreur(error: IApiError, event: MouseEvent) {
        notificationHandler.erreur({
            targetEvent: event,
            lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
            lblMessage: exLibelleFilter('G_MSG_ERREUR_ACTION'),
            error
        });
    }

    function shouldShowFormulaireConfirmation(formulaire: IFormulaireConfirmationAction, occurrence: IOccurrence, data: any) {
        if (typeof formulaire.conditionAffichage === 'undefined') {
            return true;
        } else if (formulaire.conditionAffichage instanceof Function) {
            return formulaire.conditionAffichage(<IMonoOccurrence>occurrence, data);
        } else {
            return formulaire.conditionAffichage;
        }
    }
}
