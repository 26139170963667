import * as angular from 'angular';
import {
    IAugmentedJQuery,
    IComponentController,
    IDocumentService,
    IRootScopeService,
    IScope,
    ITimeoutService
} from 'angular';
import { IColonne, IMultiLigneOptions, IMultiOccurrence } from '../../services/multi-occurrence.service';
import settingGlobal from '../../services/settingGlobal.service';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IFilterGroupBy } from '../../interfaces/group-by.interface';
import { IData, IDataLinker } from '../../services/data-linker.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IFormulaire, IFormulaireClass } from '../../services/formulaire/formulaire.service';
import { IFormulaireItem, IFormulaireItemClass } from '../../services/formulaire/formulaire-item.service';
import { IFocusService } from '../../behaviors/ex-focus/ex-focus.behavior';
import { ICaseEditionAssisteeDataTypeClass } from '../../services/data-types/case-edition-assistee-data-type.service';
import { IRaccourciService } from '../../services/raccourci.service';
import { IKeyCodes } from '../../constants/key-codes.constant';
import IFilterLimitTo = angular.IFilterLimitTo;
import { IProfil } from "../../resources/profil.resource";
import { IMenu } from "../../services/menu/menu.service";
import { IFilterLibelle } from "../../filters/ex-libelle.filter";
import { IMenuItem } from "../../services/menu/menu-item.service";
import { IMenuItemActionClass } from "../../services/menu/menu-item-action.service";

export interface IComposantGrid extends IGestionPaginationColonne, IComponentController {
    ecranContextCtrl: IEcranContextController;
    multiOccurrence: IMultiOccurrence,
    accordeonTemplate: string;
    hasClickRowAction: boolean;
    newRowIndex: number;
    valideClick($event: JQueryEventObject, id: number, data: any, index: number): any;
    valideDoubleClick($event: JQueryEventObject, data: any): void;
    clickRowAction(id: any): any;
    isActiveRow(data: any): boolean;
    isLoading(): boolean;
    getColReplacedValue(col: IColonne): string;
    getEnteteReplacedValue(col: string): string;
    onCheckboxChange(data: any): void;
    onRadioChange(value: boolean): void;
    compileAccordeonTemplate(index: number, rowScope: any): void;
    retryErreur(): void;
    isColEditionAssistee(col: string): boolean;
    clickRowContainer(event: JQueryEventObject): void;
    getColTotalFlex(col: string, index: number): string;
    saveColonneEditable(data: any): void;
}

interface IGestionPaginationColonne extends IPaginationColonne {
    DIRECTIONS: { LEFT: number; RIGHT: number };
    dataListGroups: Array<Array<string>>;
    colonnesVisibles: Array<string>;
    colonnesVisiblesAffichees: Array<string>;
    colonnesFixesGauche: Array<string>;
    colonnesFixesDroite: Array<string>;
    colonnesEnteteTitrePossible: any;
    colonnesVisibleEnteteTitreParametre: Array<IColonneEntete>;
    colonnesFixeGaucheEnteteTitreParametre: Array<IColonneEntete>;
    colonnesFixeDroiteEnteteTitreParametre: Array<IColonneEntete>;
    colonnesFixeGaucheEnteteTitrePlaceHolder: string;
    colonnesFixeDroiteEnteteTitrePlaceHolder: string;
    colonnesTotaux: Array<string>;
    scopeColonnesHidden: IScopeColonnesHidden;
    distributeWidth: boolean;
    largeurNavigateur: number;
    totauxData: ITotaux;
    couldHaveTotal: boolean;
    totalVisible: boolean;
    totalFixeVisible: boolean;
    largeurDesColonneFixeDroite: number;
    largeurDesColonneFixeGauche: number;
    largeurBoutonDefilables: number;
    largeurScrollBar: number;
    marginCarteParent: number;
    marginDashboardParent: number;
    hasPreviousButtonDeplacement: boolean;
    hasNextButtonDeplacement: boolean;
    direction: number;
    formulaireRangee: IFormulaire;
    editionRapideItems: { [col: string]: IFormulaireItem };
    multiOccurrenceOriginal: IMultiOccurrence;
    waitingForSoftUpdate: boolean;
    moveToLeft(): void;
    moveToRight(): void;
    getClassByRow(data: any): string;
    getClassByCol(data: any, col: string): string | void;
    stopWatchingHiddenColonnes(): void;
    hasData(): boolean;
    onRowKeydown(event: JQueryEventObject, colIndex: number): void;
    onColKeydown(event: JQueryEventObject, colIndex: number): void;
    hasEntete(colonnes: Array<IColonneEntete>): boolean;
    isMenuDisabled(menu: IMenu, data: IData): boolean;
    isColEditable(col: string, data: any): any;
    getClassColor(col: string, valeur: number, data: any): string;
    changeCellColor(col: string, valeur: number, data: any): string;
    isIconCell(col: string, data: any): { isIcon: boolean, icon: string, iconClass: string }
    champMultiLigne(col: string): IMultiLigneOptions;
}

interface IScopeColonnesHidden extends IScope {
    data: any;
}

interface IPaginationColonne {
    indexPaginationColonne: number;
    nombreColonnesAffichables: number;
}

interface IColonneEntete extends IColonne {
    nombreColonnes?: number;
}

interface ITotaux {
    [col: string]: any;
}

enum DIRECTIONS {
    LEFT = -1,
    RIGHT = 1
}

/* @ngInject */
export default function GridController($scope: IScope,
    $document: IDocumentService,
    MenuItemAction: IMenuItemActionClass,
    exFocus: IFocusService,
    $element: IAugmentedJQuery,
    defaults: IDefaultsService,
    $timeout: ITimeoutService,
    exLibelleFilter: IFilterLibelle,
    CaseEditionAssisteeDataType: ICaseEditionAssisteeDataTypeClass,
    limitToFilter: IFilterLimitTo,
    groupByFilter: IFilterGroupBy,
    dataLinker: IDataLinker,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass,
    raccourci: IRaccourciService,
    keyCodes: IKeyCodes,
    profil: IProfil,
    $rootScope: IRootScopeService) {
    const vm: IComposantGrid = this;

    vm.$onInit = $onInit;
    vm.valideClick = valideClick;
    vm.valideDoubleClick = valideDoubleClick;
    vm.isActiveRow = isActiveRow;
    vm.isLoading = isLoading;
    vm.getColReplacedValue = getColReplacedValue;
    vm.getEnteteReplacedValue = getEnteteReplacedValue;
    vm.onCheckboxChange = onCheckboxChange;
    vm.onRadioChange = onRadioChange;
    vm.moveToLeft = moveToLeft;
    vm.moveToRight = moveToRight;
    vm.retryErreur = retryErreur;
    vm.isColEditionAssistee = isColEditionAssistee;
    vm.getClassByRow = getClassByRow;
    vm.getClassByCol = getClassByCol;
    vm.hasData = hasData;
    vm.onRowKeydown = onRowKeydown;
    vm.onColKeydown = onColKeydown;
    vm.hasEntete = hasEntete;
    vm.isMenuDisabled = isMenuDisabled;
    vm.clickRowContainer = clickRowContainer;
    vm.getColTotalFlex = getColTotalFlex;
    vm.getClassColor = getClassColor;
    vm.changeCellColor = changeCellColor;
    vm.isIconCell = isIconCell;
    vm.champMultiLigne = champMultiLigne;
    function $onInit() {
        vm.editionRapideItems = {};

        defaults(vm, {
            hasClickRowAction: false
        });
        if (!vm.multiOccurrence.modeEditionRapide) {
            vm.formulaireRangee = new Formulaire([]);
            if (vm.multiOccurrence.etatSelected && vm.multiOccurrence.etatSelected.isEditionRapide) {
                const etatPagination = vm.multiOccurrence.etatSelected.pagination
                vm.multiOccurrence.etatSelected = vm.multiOccurrence.etatDefault;
                vm.multiOccurrence.etatSelected.pagination = etatPagination;
                vm.multiOccurrence.etatSelected.isEditionRapide = false;
                vm.multiOccurrence.fetchDataList({ forceFetch: true })
            }
        } else {
            if (vm.multiOccurrence.etatSelected && !vm.multiOccurrence.etatSelected.isEditionRapide) {
                const etatPagination = vm.multiOccurrence.etatSelected.pagination
                vm.multiOccurrence.etatSelected = vm.multiOccurrence.etatDefault;
                vm.multiOccurrence.etatSelected.pagination = etatPagination;
                vm.multiOccurrence.etatSelected.isEditionRapide = true;
                vm.multiOccurrence.fetchDataList({ forceFetch: true })
            }
            vm.formulaireRangee = new Formulaire(vm.multiOccurrence.etatSelected.colonnesVisibles.map(col => {
                const formulaireItemBase = (vm.multiOccurrence.formulaire && vm.multiOccurrence.formulaire.liste.find((champ: IFormulaireItem) => champ.col === col)) || {};

                const formulaireItem = new FormulaireItem(col, {
                    ...formulaireItemBase,
                    largeur: vm.multiOccurrence.colonnesParametres[col].largeur,
                    resourceParams: vm.multiOccurrence.colonnesParametres[col].resourceParams || (formulaireItemBase as IFormulaireItem).resourceParams
                } as any);

                vm.editionRapideItems[col] = formulaireItem;
                return formulaireItem;
            }));

            $scope.$on('$destroy', () => {
                $document.off(`click.exGrid_${vm.multiOccurrence.id}`);
            });

            $document.on(`click.exGrid_${vm.multiOccurrence.id}`, event => {
                const element = angular.element(event.target);

                if (!(<any>event.originalEvent).exGridRowClick && !element.closest('ex-dialog,.ex-input-lov').length && !(<any>event.originalEvent).exInputLovClicked && !element.closest('md-calendar').length) {
                    $scope.$applyAsync(() => {
                        vm.multiOccurrence.rangeeEditionRapide = null;
                        vm.multiOccurrence.indexEditionRapide = null;
                    });
                }
            });

            const focusinSelector = '.ex-grid-row:not(.ex-grid-row--edition-rapide):not(.ex-grid-row--deleted)';
            $element.on('focusin', focusinSelector, (event: JQueryEventObject) => {
                $scope.$applyAsync(() => {
                    const target = angular.element(event.target);
                    if (!target.closest('ex-grid-col--action').length) {
                        const rows = $element.find('.ex-grid-row');
                        const row = <JQuery<HTMLElement>>target.closest('.ex-grid-row');
                        const rowPosition = rows.index(row);
                        handleFocus(event, rowPosition);
                    }
                });
            });

            const observer = new MutationObserver(() => {
                if (vm.newRowIndex != null) {
                    focusCol(vm.newRowIndex, 0);
                    vm.newRowIndex = undefined;
                }
            });

            const observerOptions = {
                childList: true,
                subtree: true
            };

            observer.observe($element[0], observerOptions);
        }

        defaults(vm, {
            isMoveToRight: true,
            indexPaginationColonne: 0,
            colonnesEnteteTitrePossible: null,
            totalVisible: false,
            hasClickRowAction: false,
            distributeWidth: profil.parametres.OUTILS_SUPPORT === 'OUI' ? settingGlobal.getMultiOccurrenceDistributionWidths() : true
        });

        if (vm.multiOccurrence.initialized) {
            if (!vm.multiOccurrence.dataListReady) {
                initGestionLargeurColonneEtapes();
            }
        } else {//Dans le cas où on n’utilise pas le exOnce. On doit attendre l'émission du ready pour vérifier.
            vm.multiOccurrence.once('ready', () => {
                if (!vm.multiOccurrence.dataListReady) {
                    initGestionLargeurColonneEtapes();
                }
            });
        }
    }
    $rootScope.$on("ex-expandable.hasErrorGridToogle", (event, data) => {
        if (event.defaultPrevented) {
            return;
        }
        //si on est dans le meme bloc on continue la validation
        if (data && vm.multiOccurrence && vm.multiOccurrence.bloc && data.bloc === vm.multiOccurrence.bloc && data.srccod === vm.multiOccurrence.srccod) {
            //s'il s'agit d'un edition rapide on fais la validation
            if (vm.multiOccurrence.dataList && vm.multiOccurrence.dataList[0] && vm.multiOccurrence.dataList[0].$editionRapideCtrl && vm.multiOccurrence.editionRapideActive) {
                $rootScope.$broadcast("ex-expandable.setFlagRequeteToogle", { srccod: data.srccod, bloc: data.bloc, flag: vm.multiOccurrence.dataList[0].$editionRapideCtrl.$invalid || vm.multiOccurrence.dataList[0].$editionRapideCtrl.$dirty })
            } else {
                //on continue avec le meme comportament dans les autres cas
                $rootScope.$broadcast("ex-expandable.setFlagRequeteToogle", { srccod: data.srccod, bloc: data.bloc, flag: false })
            }
            event.preventDefault();
        }
    })
    function getClassColor(col: string, valeur: number, data: any): string {
        let classColor: string = "";
        if (vm?.multiOccurrence?.colonnesParametres[col]?.changeColor) {
            switch (data) {
                case 1:
                    classColor = 'ex-grid-col-setColorBlue';
                    break;
                case 2:
                    classColor = 'ex-grid-col-setColorRouge';
                    break;
                default:
                    break;
            }
        }
        return classColor;
    }
    function changeCellColor(col: string, valeur: number, data: any) {
        let classColor: string = "";
        // Diviser la liste en un tableau d'éléments
        if (vm?.multiOccurrence?.colonnesParametres[col]?.changeCellColor) {
            let elements: string[] = data.split(',');
            if (elements && Array.isArray(elements)) {
                elements.map((element) => {
                    const data = element.split('-')
                    if (data && Array.isArray(data) && data.length > 0) {
                        if (data[0] == col) {
                            switch (data[1]) {
                                case '1':
                                    classColor = 'ex-grid-col-setColorBlue';
                                    break;
                                case '2':
                                    classColor = 'ex-grid-col-setColorRouge';
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                });
            }
        }
        return classColor;
    }

    function isIconCell(col: string, data: any): { isIcon: boolean, icon: string, iconClass: string, iconTitle: string } {
        const dataCol = vm?.multiOccurrence?.colonnesParametres[col]
        //on retourne un object avec les proprietes de l'icone
        let result: {
            isIcon: boolean,
            icon: string,
            iconClass: string,
            iconTitle: string
        } = { isIcon: false, icon: "", iconClass: "", iconTitle: "" }

        if (dataCol && dataCol?.isIcon) {
            result.iconClass = "material-icons, " + vm.getClassColor(col, 0, data.color)
            Object.values(dataCol.isIcon).forEach((el: any) => {
                //on ajoutes les proprietes dans la colonne qui a la propriete isIcon
                if (el && data && el?.value === data[col]) {
                    result.isIcon = true
                    result.icon = el.icon
                    result.iconTitle = (el?.iconTitleData) ? data[el.iconTitleChamp] || "" : el.iconTitle
                    result.iconClass = (el?.iconClass) ? result.iconClass + el?.iconClass : result?.iconClass
                }
            })
        }
        return result;
    }

    function champMultiLigne(col: string): IMultiLigneOptions {
        const dataCol = vm?.multiOccurrence?.colonnesParametres[col]
        if (dataCol && dataCol?.champMultiLigne) {
            return dataCol?.champMultiLigne
        }
        return null
    }

    function clickRowContainer(event: JQueryEventObject) {
        if ((<any>event.originalEvent)) {
            (<any>event.originalEvent).exGridRowClick = true;
        }
    }

    function handleFocus(event: JQueryEventObject, index: number) {
        if (vm.multiOccurrence.indexEditionRapide !== index) {
            vm.multiOccurrence.rangeeEditionRapide = vm.multiOccurrence.dataList[index] || null;
            vm.multiOccurrence.indexEditionRapide = index || null;

            if (vm.multiOccurrence.rangeeEditionRapide && vm.multiOccurrence.rangeeEditionRapide.$editedRapide && vm.multiOccurrence.rangeeEditionRapide.$editionRapideCtrl) {
                vm.multiOccurrence.rangeeEditionRapide.$editionRapideCtrl.$setSubmitted();
            }
        }

        event.preventDefault();
    }

    function onRowKeydown(event: JQueryEventObject, rowIndex: number) {
        if (vm.multiOccurrence.raccourcisRangee) {
            const data = vm.multiOccurrence.dataList[rowIndex];
            raccourci(vm.multiOccurrence.raccourcisRangee, event, data);
        }
    }

    function onColKeydown(event: JQueryEventObject, colIndex: number) {
        if ((event.metaKey || event.ctrlKey) && !event.shiftKey) {
            if (event.which === keyCodes.UP) {
                event.preventDefault();
                event.stopPropagation();

                const rowIndex = getRowIndex(event);

                vm.newRowIndex = (rowIndex === 0) ? 0 : rowIndex - 1;

                if (rowIndex === 0 && hasAccesNouveau()) {
                    if (isNouveauAction()) {
                        (<IMenuItem>vm.multiOccurrence.actionsNouveaux.listeMenuItem[0]).action(null, vm.scopeColonnesHidden.data, vm.multiOccurrence);
                    } else {
                        vm.multiOccurrence.unshiftRangeeEditionRapide();
                    }
                } else {
                    focusCol(vm.newRowIndex, colIndex);
                }
            } else if (event.which === keyCodes.DOWN) {
                addRowAfter(event, colIndex);
            }
        } else if (event.which === keyCodes.ENTER && !event.metaKey && !event.ctrlKey && !event.shiftKey) {
            addRowAfter(event, 0);
        } else if (event.shiftKey && event.key === "F6" && hasAccesNouveau() && vm.multiOccurrence.editionRapideActive) {
            event.preventDefault()
            vm.multiOccurrence.dataList.push({ ...vm.multiOccurrence.dataList[vm.multiOccurrence.indexEditionRapide], [vm.multiOccurrence.cleint]: undefined, $$index: undefined, $$hashKey: undefined })
            setTimeout(() => focusCol(vm.multiOccurrence.dataList.length - 1, colIndex))
        }
    }

    function addRowAfter(event: JQueryEventObject, colIndex: number) {
        event.preventDefault();
        event.stopPropagation();

        const rowIndex = getRowIndex(event);

        vm.newRowIndex = (rowIndex === vm.multiOccurrence.dataList.length - 1) ?
            hasAccesNouveau() && !isNouveauAction() ? vm.multiOccurrence.dataList.length : rowIndex
            : rowIndex + 1;

        if (rowIndex === vm.multiOccurrence.dataList.length - 1 && hasAccesNouveau()) {
            if (isNouveauAction()) {
                (<IMenuItem>vm.multiOccurrence.actionsNouveaux.listeMenuItem[0]).action(null, vm.scopeColonnesHidden.data, vm.multiOccurrence);
            } else {
                vm.multiOccurrence.pushRangeeEditionRapide();
            }
        } else {
            focusCol(vm.newRowIndex, colIndex);
        }
    }

    function hasAccesNouveau() {
        if (vm.multiOccurrence.fonctions.nouveau instanceof Function) {
            return vm.multiOccurrence.fonctions.nouveau(vm.scopeColonnesHidden.data);
        } else {
            return vm.multiOccurrence.fonctions.nouveau;
        }
    }

    function isNouveauAction(): boolean {
        return Boolean(
            vm.multiOccurrence.actionsNouveaux &&
            vm.multiOccurrence.actionsNouveaux.listeMenuItem.length === 1 &&
            vm.multiOccurrence.actionsNouveaux.listeMenuItem[0] instanceof MenuItemAction
        );
    }

    function getRowIndex(event: JQueryEventObject) {
        const rows = $element.find('.ex-grid-row');
        const row = <JQuery<HTMLElement>>angular.element(event.target).closest('.ex-grid-row');
        const index = rows.index(row);
        return index;
    }

    function focusCol(rowIndex: number, colIndex: number) {
        vm.multiOccurrence.indexEditionRapide = rowIndex;
        vm.multiOccurrence.rangeeEditionRapide = vm.multiOccurrence.dataList[rowIndex];

        const col = $element.find(
            `.ex-grid-row:nth-child(${rowIndex + 1}) .ex-grid-col-container:nth-child(${colIndex + 1})`
        );

        exFocus(col);
    }

    function initGestionLargeurColonneEtapes() {
        initScopeColonnesHidden();
        initColonnesFixesGauche();
        initColonnesFixesDroite();
        initTotalFixeVisible();
        initColonnesVisibles();
        listenForHiddenColonnesFixes();
        initLargeurs();
        initDataList();
        initEntetesColonnes();
        listenForMenuActions();
        listenForDistributionWidths();
        listenForWindowResizing();
        listenForTotaux();
        listenForColonnesVisibles();
        listenForAjustementEvent();
        listenForHiddenColonnes();
    }

    function initTotalFixeVisible() {
        vm.totalFixeVisible = hasTotalFixe();

        if (vm.totalFixeVisible) {
            vm.totalVisible = true;
        }
    }

    function initScopeColonnesHidden() {
        vm.scopeColonnesHidden = <IScopeColonnesHidden>$scope.$new();
        vm.scopeColonnesHidden.data = {};
        dataLinker.link($element, vm.scopeColonnesHidden.data, vm.ecranContextCtrl.stateParams, vm.ecranContextCtrl.ecranDetails);
    }

    function initLargeurs() {
        const carteParent = $element.closest('md-card');
        const dashboardParent = ($element.closest('.ex-dashboard-card-droite').length) ?
            $element.closest('.ex-dashboard-card-droite') : $element.closest('.ex-dashboard-card-gauche');

        // Largeurs qui ne variront pas
        defaults(vm, {
            largeurBoutonDefilables: (vm.multiOccurrence.fonctions.colonnesFixes) ? 30 * 2 : (30 * 2) + 10,
            // on regarde si la grid est dans une carte, si oui, il n'aura jamais de scroll
            largeurScrollBar: (!$element.closest('ex-card')[0]) ? 17 : 0,
            // Dans le cas où la grille est dans une carte, il faut prendre en compte le margin de celle-ci.
            marginCarteParent: (carteParent.length) ? parseInt(carteParent.css('marginLeft')) + parseInt(carteParent.css('marginRight')) : 0,
            // On doit prendre en compte le margin si on est dans un dashboard-card (droite OU gauche)
            marginDashboardParent: (dashboardParent.length) ? parseInt(dashboardParent.css('marginLeft')) + parseInt(dashboardParent.css('marginRight')) : 0
        });

        initLargeursFixes();
    }

    function initLargeursFixes() {
        initLargeurFixeGauche();
        initLargeurFixeDroite();
    }

    function initLargeurFixeGauche() {
        vm.largeurDesColonneFixeGauche = (vm.colonnesFixesGauche.length) ? getSommeLargeurListeColonne(vm.colonnesFixesGauche) : 0;
    }

    function initLargeurFixeDroite() {
        vm.largeurDesColonneFixeDroite = (vm.colonnesFixesDroite.length) ? getSommeLargeurListeColonne(vm.colonnesFixesDroite) : 0;
    }

    function hasTotalFixe() {
        return [
            ...vm.colonnesFixesGauche,
            ...vm.colonnesFixesDroite
        ].some((col) => {
            return Boolean(vm.multiOccurrence.colonnesParametres[col] && vm.multiOccurrence.colonnesParametres[col].total);
        });
    }

    function initDataList() {
        if (vm.multiOccurrence.bris) {
            vm.multiOccurrence.on('dataListUpdate', onBrisDataUpdate);
        } else {
            if (vm.multiOccurrence.dataListReady) {
                vm.dataListGroups = [vm.multiOccurrence.dataList];
            } else {
                const dataList: Array<any> = [];
                vm.dataListGroups = [dataList];

            }

            if (vm.multiOccurrence && !vm.multiOccurrence.dataList) {
                vm.multiOccurrence.on('dataListUpdate', onSoftDataUpdate);
            }
        }

        $scope.$on('$destroy', () => {
            vm.multiOccurrence.removeListener('dataListUpdate', onBrisDataUpdate);
            vm.multiOccurrence.removeListener('dataListUpdate', onSoftDataUpdate);
        });
    }

    function onBrisDataUpdate() {
        vm.dataListGroups = groupByFilter(vm.multiOccurrence.dataList, vm.multiOccurrence.bris);
    }

    function onSoftDataUpdate() {
        if (vm.multiOccurrence.dataList.every((row: any) => !row.$opened)) {
            vm.dataListGroups.splice(0, 1, vm.multiOccurrence.dataList);
        } else if (!vm.waitingForSoftUpdate) {
            vm.waitingForSoftUpdate = true;
            const removeWatch = $scope.$watch(
                () => vm.multiOccurrence.dataList.every((row: any) => !row.$opened),
                (isEveryRowClosed: boolean) => {
                    if (isEveryRowClosed) {
                        vm.dataListGroups.splice(0, 1, vm.multiOccurrence.dataList);
                        vm.waitingForSoftUpdate = false;
                        removeWatch();
                    }
                }
            );
        }
    }

    function listenForMenuActions() {
        $scope.$on('$mdMenuOpen', (event, elem) => {
            elem.closest('.ex-grid-row').addClass('ex-grid-menu-visible');
        });

        $scope.$on('$mdMenuClose', () => {
            $element.find('.ex-grid-menu-visible').removeClass('ex-grid-menu-visible');
        });
    }

    function listenForWindowResizing() {
        window.addEventListener('resize', () => reajusterLargeurColonneGrid());

        $scope.$on('$destroy', () => {
            window.removeEventListener('resize', () => reajusterLargeurColonneGrid());
        });
    }

    function listenForDistributionWidths() {
        const event = 'multiOccurrenceDistributionWidthsChange';

        settingGlobal.on(event, onDistributionWidthsChange);

        $scope.$on('$destroy', () => {
            settingGlobal.removeListener(event, onDistributionWidthsChange);
        });

        function onDistributionWidthsChange(distributeWidth: boolean) {
            vm.distributeWidth = distributeWidth;

            if (vm.multiOccurrence.initialized) {
                initLargeurColonneGrid();
            }
        }
    }

    function listenForTotaux() {
        vm.couldHaveTotal = couldHaveTotal();
        if (vm.couldHaveTotal) {
            if (vm.multiOccurrence.fonctions.limiteTotalSurRangeesSelectionnees && vm.multiOccurrence.rangeesSelectionnables) {
                resetTotauxData();
                vm.multiOccurrence.on('rowSelectionChange', onTotauxRowSelectionUpdate);

                $scope.$on('$destroy', () => {
                    vm.multiOccurrence.removeListener('rowSelectionChange', onTotauxDataUpdate);
                });
            } else {
                vm.multiOccurrence.on('dataListUpdate', onTotauxDataUpdate);

                $scope.$on('$destroy', () => {
                    vm.multiOccurrence.removeListener('dataListUpdate', onTotauxDataUpdate);
                });
            }
        }
    }

    function couldHaveTotal(): boolean {
        return Object.keys(vm.multiOccurrence.colonnesParametres).some((col: string) => {
            return Boolean(vm.multiOccurrence.colonnesParametres[col].total);
        });
    }

    function onTotauxRowSelectionUpdate() {
        resetTotauxData();
        vm.multiOccurrence.dataList
            .filter((rowDetails: any) => rowDetails[vm.multiOccurrence.selectionColonne])
            .forEach((row: any) => {
                vm.colonnesVisibles
                    .filter((col, index) => getColonneTotal(index))
                    .forEach((colTotal) => {
                        vm.totauxData[colTotal] += Number(row[colTotal]);
                    });
            });

    }

    function resetTotauxData() {
        vm.totauxData = vm.colonnesVisibles
            .filter((col, index) => getColonneTotal(index))
            .reduce((totauxDataBase: any, col: string) => {
                totauxDataBase[col] = 0;
                return totauxDataBase;
            }, {});
    }

    function onTotauxDataUpdate() {
        let colonnesHiddenTotauxData: any = {};
        // Si on a des données, on va chercher les valeurs des totaux pour la recherche dans le premier
        // enregistrement.
        if (vm.multiOccurrence.dataList[0]) {
            vm.totauxData = Object.keys(vm.multiOccurrence.dataList[0]).reduce((accumulateur: any, col: string) => {
                if (col.startsWith('total___')) {
                    const colName = col.replace('total___', '');
                    accumulateur[colName] = vm.multiOccurrence.dataList[0][col];
                    colonnesHiddenTotauxData[col] = accumulateur[colName];
                }
                return accumulateur;
            }, {});
        } else {
            vm.totauxData = {};
        }
        // On permet d'utiliser les totaux pour cacher des colonnes, puisqu'il s'agit d'une donnée commune à toutes les lignes
        Object.assign(vm.scopeColonnesHidden.data, colonnesHiddenTotauxData);
        formatTotaux();
    }
    //effectuer le format des decimaux dans un champ qui a le flag enleverZeroNonSig
    function formatDecimalDataType() {
        //on fais la validation seulement pour les qui ont un total
        Object.keys(vm.totauxData).forEach((key) => {
            Object.values(vm.multiOccurrence.colonnesParametres).forEach((value) => {
                //on fais la validation pour le champ qui a le flag enleverZeroNonSig
                if (key === value.nom && value.enleverZeroNonSig) {
                    //mettre le type de donnee au string et le nombre de decimaux au 6 maximun
                    if (vm.multiOccurrence.dataTypes[value.nom]) {
                        vm.multiOccurrence.dataTypes[value.nom].schemaItem.type = "string"
                        vm.multiOccurrence.dataTypes[value.nom].schemaItem.decimals = 6;
                        vm.multiOccurrence.dataTypes[value.nom].alignment = 'right'
                    }
                    //on va chercher la colonne dans le datalist
                    Object.values(vm.multiOccurrence.dataList).forEach((column) => {
                        let data = column[value.nom] || undefined;
                        if (data) {
                            column[value.nom] = valideDecimaux(data)
                        }
                    })
                }
            })
        })
    }
    //format de nombre
    function valideDecimaux(data: any) {
        let resultat;
        let numDecimals = 2;
        let tmpVal = data;
        //s'il n'y a pas de decimaux on laisse 2 par defaut
        if (!data.toString().split(".")[1]) {
            data = "" + parseFloat(data.toString()).toFixed(numDecimals);
        }
        let tmpDecimals = data.toString().split(".")[1];
        //par defaut on utilise 2 decimaux
        tmpVal = "" + parseFloat(data.toString()).toFixed(numDecimals);
        numDecimals = tmpDecimals.trim().length
        if (tmpDecimals && tmpDecimals.length && tmpDecimals.length > 2) {
            //s'il y a plus de 6 decimaux on laisse 6 maximun
            if (numDecimals > 6) {
                numDecimals = 6;
            }
            //on ajuste le numero de décimaux
            tmpVal = parseFloat(data.toFixed(numDecimals));
            //on re-calcul le nombre de decimaux afin de l'utiliser dans la methode toLocaleString
            if (tmpVal.toString().split(".")[1]) {
                numDecimals = tmpVal.toString().split(".")[1].trim().length
            }
        }
        resultat = tmpVal;
        return resultat.toLocaleString('en-US', { minimumFractionDigits: numDecimals });
    }

    function formatTotaux() {
        try {
            formatDecimalDataType()
            Object.keys(vm.totauxData).forEach((key) => {
                Object.values(vm.multiOccurrence.colonnesParametres).forEach((value) => {
                    //on fais la validation pour le champ qui a le flag enleverZeroNonSig
                    if (key === value.nom && value.enleverZeroNonSig) {
                        let tmpVal = vm.totauxData[key];
                        let tmpValidator = false;
                        if (typeof tmpVal !== "undefined") {
                            tmpVal = valideDecimaux(tmpVal)
                            let calcul = (tmpVal - vm.totauxData[key]);
                            //on validade que le numero est correct sa soustraction doit être nulle
                            //on ajoute une tolerance de 0.5 s'il y a une valeur apres les 6 decimaux
                            tmpValidator = (calcul < 0.5 && calcul > -0.5) ? true : false;
                            if (tmpValidator) {
                                //on change le numero seulement si la validation est correcte
                                vm.totauxData[key] = tmpVal;
                            }
                        }
                    }
                })
            });
        } catch (exception) {
            //s'il y a une erreur on fais rien
        }
    }

    function listenForColonnesVisibles() {
        // Lorsqu'on modifie le sélecteur de colonnes, on doit initialiser la grid
        vm.multiOccurrence.on('colonnesVisiblesUpdate', onColonnesVisiblesUpdate);

        $scope.$on('$destroy', () => {
            vm.multiOccurrence.removeListener('colonnesVisiblesUpdate', onColonnesVisiblesUpdate);
        });

        reajusterLargeurColonneGrid();
    }

    function onColonnesVisiblesUpdate() {
        listenForHiddenColonnes();
        initColonnesVisibles();
        initLargeurColonneGrid();
    }

    function listenForAjustementEvent() {
        $scope.$on('exGridReajusterLargeurColonne', () => {
            reajusterLargeurColonneGrid();
            setColumnToLeft();
        });
    }

    function listenForHiddenColonnesFixes() {
        watchColonnesHiddenFixes(vm.multiOccurrence.colonnesFixesGauche, () => {
            delete vm.colonnesFixeGaucheEnteteTitreParametre;
            initColonnesFixesGauche();
            initLargeurFixeGauche();
        });

        watchColonnesHiddenFixes(vm.multiOccurrence.colonnesFixesDroite, () => {
            delete vm.colonnesFixeDroiteEnteteTitreParametre;
            initColonnesFixesDroite();
            initLargeurFixeDroite();
        });
    }

    function watchColonnesHiddenFixes(colonnes: Array<string>, callback: () => void) {
        const hiddenDynamiques = getHiddenDynamiques(colonnes);

        if (hiddenDynamiques.length) {
            vm.scopeColonnesHidden.$watchGroup(hiddenDynamiques, (newValue: any, oldValue: any) => {
                if (!angular.equals(newValue, oldValue)) {
                    callback();
                    initTotalFixeVisible();
                    vm.nombreColonnesAffichables = 0;
                    vm.direction = DIRECTIONS.RIGHT;
                    ajusterPaginationColonne();
                }
            });
        }
    }

    function initColonnesFixesGauche() {
        vm.colonnesFixesGauche = getColonnesAffichables(vm.multiOccurrence.colonnesFixesGauche);
    }

    function initColonnesFixesDroite() {
        vm.colonnesFixesDroite = getColonnesAffichables(vm.multiOccurrence.colonnesFixesDroite);
    }

    function listenForHiddenColonnes() {
        if (vm.stopWatchingHiddenColonnes) {
            vm.stopWatchingHiddenColonnes();
            delete vm.stopWatchingHiddenColonnes;
        }

        const hiddenDynamiques = getHiddenDynamiques(vm.multiOccurrence.etatSelected.colonnesVisibles);

        if (hiddenDynamiques.length) {
            vm.stopWatchingHiddenColonnes = vm.scopeColonnesHidden.$watchGroup(hiddenDynamiques, (newValue: any, oldValue: any) => {
                if (!angular.equals(newValue, oldValue)) {
                    initColonnesVisibles();
                    vm.nombreColonnesAffichables = 0;
                    vm.direction = DIRECTIONS.RIGHT;
                    ajusterPaginationColonne();
                }
            });
        }
    }

    function getHiddenDynamiques(colonnes: Array<string>) {
        return colonnes.reduce((resultat: any, colonne: string) => {
            if (vm.multiOccurrence.colonnesParametres[colonne]) {
                const hidden = vm.multiOccurrence.colonnesParametres[colonne].hidden;

                if (typeof hidden === 'function') {
                    resultat.push((scope: any) => {
                        return hidden(scope.data, vm.multiOccurrence);
                    });
                }
            }

            return resultat;
        }, []);
    }

    function initColonnesVisibles() {
        vm.colonnesVisibles = getColonnesAffichables(vm.multiOccurrence.etatSelected.colonnesVisibles);
    }

    function getColonnesAffichables(colonnes: Array<string>) {
        return colonnes.filter((colonne: string) => {
            if (!vm.multiOccurrence.colonnesParametres[colonne]) {
                return false;
            }

            const hidden = vm.multiOccurrence.colonnesParametres[colonne].hidden;

            if (typeof hidden === 'function') {
                return !hidden(vm.scopeColonnesHidden.data || {}, vm.multiOccurrence);
            } else {
                return !Boolean(hidden);
            }
        });
    }

    function getColonnesVisiblesAffichees() {
        const limite = (vm.multiOccurrence.fonctions.colonnesToujoursVisibles || vm.multiOccurrence.modeEditionRapide) ? vm.colonnesVisibles.length : vm.nombreColonnesAffichables;
        const indexDebut = (vm.multiOccurrence.fonctions.colonnesToujoursVisibles || vm.multiOccurrence.modeEditionRapide) ? 0 : vm.indexPaginationColonne;
        return limitToFilter(vm.colonnesVisibles, limite, indexDebut);
    }

    function initEntetesColonnes() {
        // S'il y a des entêtes de colonnes, on bâtit un object avec chaque colonne et son entête
        for (let entete in vm.multiOccurrence.colonnesEnteteTitre) {
            vm.colonnesEnteteTitrePossible = vm.colonnesEnteteTitrePossible || {};

            const enteteTitres = vm.multiOccurrence.colonnesEnteteTitre[entete];

            for (let n = 0, l = enteteTitres.length; n < l; n++) {
                const col = enteteTitres[n];
                vm.colonnesEnteteTitrePossible[col] = entete;
            }
        }
    }

    function buildColonnesEnteteTitre(colonnesVisible: Array<string>) {
        let colonnesEnteteTitreParametre: Array<IColonneEntete> = [];

        for (let n = 0, l = colonnesVisible.length; n < l; n++) {
            const colonneVisible = colonnesVisible[n];
            const derniereColonne = colonnesEnteteTitreParametre[colonnesEnteteTitreParametre.length - 1];
            const largeurColonneVisible = vm.multiOccurrence.colonnesParametres[colonneVisible].largeur;

            if (derniereColonne && derniereColonne.nom === vm.colonnesEnteteTitrePossible[colonneVisible]) {
                derniereColonne.largeur += largeurColonneVisible;
                derniereColonne.nombreColonnes++;
            } else {
                colonnesEnteteTitreParametre.push({
                    nom: vm.colonnesEnteteTitrePossible[colonneVisible] || '',
                    largeur: largeurColonneVisible,
                    nombreColonnes: 1
                });
            }
        }

        return colonnesEnteteTitreParametre;
    }

    function ajusterColonnesEnteteTitre() {
        if (vm.colonnesEnteteTitrePossible) {
            const colonnesVisible = vm.colonnesVisibles.slice(vm.indexPaginationColonne, vm.nombreColonnesAffichables + vm.indexPaginationColonne);
            vm.colonnesVisibleEnteteTitreParametre = buildColonnesEnteteTitre(colonnesVisible);

            if (vm.multiOccurrence.fonctions.colonnesFixes) {
                vm.colonnesFixeDroiteEnteteTitreParametre = vm.colonnesFixeDroiteEnteteTitreParametre || buildColonnesEnteteTitre(vm.colonnesFixesDroite);
                vm.colonnesFixeGaucheEnteteTitreParametre = vm.colonnesFixeGaucheEnteteTitreParametre || buildColonnesEnteteTitre(vm.colonnesFixesGauche);

                vm.colonnesFixeDroiteEnteteTitrePlaceHolder = buildEnteteTitrePlaceHolder(vm.colonnesFixeDroiteEnteteTitreParametre);
                vm.colonnesFixeGaucheEnteteTitrePlaceHolder = buildEnteteTitrePlaceHolder(vm.colonnesFixeGaucheEnteteTitreParametre);
            }
        }
    }

    function buildEnteteTitrePlaceHolder(colonnesEntete: Array<IColonneEntete>) {
        const hauteurMax = (colonnesEntete.length) ? Math.max(
            ...colonnesEntete.map((col: IColonneEntete) => {
                const libelle = exLibelleFilter(col.nom, vm.multiOccurrence.libelles, true, vm.getEnteteReplacedValue(col.nom));
                return (col.nom && col.nom.length) ? (libelle.match(/<\/?br>/g) || []).length + 1 : 0;
            })
        ) : 0;

        return '<br/>'.repeat(hauteurMax);
    }

    function initLargeurColonneGrid() {
        vm.nombreColonnesAffichables = 0;
        vm.direction = undefined;
        ajusterPaginationColonne();
    }

    function reajusterLargeurColonneGrid() {
        // On doit attendre un peu car elle se fait avant l'animation de la navigation de droit
        $timeout(() => {
            const largeur = getLargeurNavigateur();

            if (largeur !== vm.largeurNavigateur) {
                initLargeurColonneGrid();
            }
        }, 100);
    }

    $scope.$on('exGridReajusterLargeurColonneToLeft', () => {
        $timeout(() => {
            setColumnToLeft();
        }, 1000);
    });

    function setColumnToLeft() {
        ///déplacer le grid vers la gauche
        vm.indexPaginationColonne = 0;
        vm.moveToLeft();
    }
    function getColonnesTotaux(): Array<string> {
        const colonnesVisiblesAffichees = [
            ...vm.colonnesFixesGauche,
            ...vm.colonnesVisibles.slice(vm.indexPaginationColonne, vm.indexPaginationColonne + vm.nombreColonnesAffichables),
            ...vm.colonnesFixesDroite
        ];

        const indexGauche = vm.colonnesFixesGauche.length;
        const indexDroite = vm.colonnesFixesDroite.length * -1;
        colonnesVisiblesAffichees.splice(indexGauche, 0, 'col-bouton-defilement-gauche');
        (indexDroite === 0) ?
            colonnesVisiblesAffichees.push('col-bouton-defilement-droite') :
            colonnesVisiblesAffichees.splice(indexDroite, 0, 'col-bouton-defilement-droite');

        return colonnesVisiblesAffichees;
    }

    function moveToLeft(): void {
        vm.direction = DIRECTIONS.LEFT;
        ajusterPaginationColonne();
    }

    function moveToRight(): void {
        vm.direction = DIRECTIONS.RIGHT;
        ajusterPaginationColonne();
    }

    function hasNext(): boolean {
        const indexFuture = vm.nombreColonnesAffichables + vm.indexPaginationColonne;
        return (indexFuture < vm.colonnesVisibles.length);
    }

    function hasPrevious(): boolean {
        return vm.indexPaginationColonne > 0;
    }

    function getSommeLargeurListeColonne(arr: Array<string>) {
        return arr.reduce(function (accumulateur, valeurCourante) {
            return accumulateur + vm.multiOccurrence.colonnesParametres[valeurCourante].largeur;
        }, 0);
    }

    function getLargeurNavigateurRestant(): number {
        // Cette largeur peut varier selon la présence du total
        const largeurEspaceDebut = (vm.multiOccurrence.rangeesSelectionnables || vm.totalVisible || vm.totalFixeVisible) ? 52 : 0;

        const largeurDesActionsDroite = (vm.multiOccurrence.actionsRangeeDroite) ? vm.multiOccurrence.actionsRangeeDroite.listeMenuItem.length * 52 : 0;
        const largeurDesActionsGauche = (vm.multiOccurrence.actionsRangeeGauche) ? vm.multiOccurrence.actionsRangeeGauche.listeMenuItem.length * 52 : 0;

        return vm.largeurNavigateur -
            largeurDesActionsDroite -
            largeurDesActionsGauche -
            vm.largeurBoutonDefilables -
            largeurEspaceDebut -
            vm.largeurScrollBar -
            vm.marginCarteParent -
            vm.marginDashboardParent -
            (vm.multiOccurrence.modeEditionRapide ? 0 : vm.largeurDesColonneFixeDroite) -
            (vm.multiOccurrence.modeEditionRapide ? 0 : vm.largeurDesColonneFixeGauche);
    }

    function getLargeurMenus(): number {
        let largeur = 0;
        if (vm.multiOccurrence.criteresSuggeres && vm.multiOccurrence.fonctions.criteresSuggeresVisibles) {
            if (profil.preferences.gsfflgpannav) {
                largeur += 320;
            }

            if (profil.preferences.gsfflgpansom) {
                largeur += 320;
            }
        }

        return largeur;
    }

    function getLargeurNavigateur() {
        const gridElement: HTMLElement = <any>$element.closest('ex-grid').get(0);

        const hasCriteresSuggeresOuvert = (
            // Ceci permet quand c'est un critère suggéré d'avoir une grandeur approximative
            vm.multiOccurrence.criteresSuggeres && gridElement.clientWidth === 0 ||
            // Correction pour Firefox lorsqu'on réduit la fenêtre
            window.innerWidth < gridElement.clientWidth
        );

        if (hasCriteresSuggeresOuvert) {
            return window.innerWidth - getLargeurMenus();
        } else if ($element.closest('md-dialog').length) {
            return $element.closest('md-dialog')[0].clientWidth;
        }

        const tabsElement: HTMLElement = <any>$element.closest('ex-tabs').get(0);

        if (tabsElement && !$element.parents('ex-grid').length) {
            return tabsElement.clientWidth;
        } else if (!gridElement.clientWidth) {
            return window.innerWidth;
        } else {
            return gridElement.clientWidth;
        }
    }

    function ajusterPaginationColonne(): void {
        const aucunePagination = (
            vm.colonnesVisibles.length <= 1
        );

        if (aucunePagination) {
            vm.indexPaginationColonne = 0;
            vm.nombreColonnesAffichables = 0;
        }
        let index = (vm.direction === DIRECTIONS.RIGHT) ? vm.indexPaginationColonne + DIRECTIONS.RIGHT : (vm.direction === DIRECTIONS.LEFT) ? vm.indexPaginationColonne + DIRECTIONS.LEFT : vm.indexPaginationColonne;

        if (index < 0) {
            index = 0;
        }

        vm.largeurNavigateur = getLargeurNavigateur();

        Object.assign(vm, getNombreColonnesAffichables(index));

        vm.colonnesVisiblesAffichees = getColonnesVisiblesAffichees();
        vm.colonnesTotaux = getColonnesTotaux();
        vm.hasPreviousButtonDeplacement = hasPrevious();
        vm.hasNextButtonDeplacement = hasNext();

        ajusterColonnesEnteteTitre();
    }

    function getNombreColonnesAffichables(indexPaginationColonne: number): IPaginationColonne {
        let i = indexPaginationColonne,
            largeurTotalDesColonnesAfficher = 0,
            nombreColonnesAffichables = 0,
            largeurNavigateurRestant = getLargeurNavigateurRestant();

        vm.totalVisible = vm.totalFixeVisible;
        while (largeurTotalDesColonnesAfficher + getColonneLargeur(i) <= largeurNavigateurRestant && i < vm.colonnesVisibles.length) {
            nombreColonnesAffichables++;
            largeurTotalDesColonnesAfficher += getColonneLargeur(i);

            if (vm.couldHaveTotal && !vm.totalVisible && getColonneTotal(i)) {
                vm.totalVisible = true;
            }

            i++;
            largeurNavigateurRestant = getLargeurNavigateurRestant();
        }

        // Si on était à la fin et des colonnes se font cacher, il se peut que l'on se retrouve plus loin que la fin
        if (indexPaginationColonne >= vm.colonnesVisibles.length) {
            indexPaginationColonne = vm.colonnesVisibles.length - 1;
        }

        return {
            indexPaginationColonne,
            nombreColonnesAffichables
        };
    }

    function getColonneLargeur(index: number) {
        const colNom = vm.colonnesVisibles[index];
        return ((vm.multiOccurrence.colonnesParametres[colNom]) ? vm.multiOccurrence.colonnesParametres[colNom].largeur : 0);
    }

    function getColonneTotal(index: number) {
        const colNom = vm.colonnesVisibles[index];
        return (vm.multiOccurrence.colonnesParametres[colNom] && vm.multiOccurrence.colonnesParametres[colNom].total);
    }

    //#########################
    //# GESTION DU CHARGEMENT #
    //#########################

    function retryErreur() {
        if (vm.multiOccurrence.initError) {
            vm.multiOccurrence.autoFetch = true;
            vm.multiOccurrence.init();
        } else {
            vm.multiOccurrence.fetchDataList();
        }
    }

    function isLoading(): boolean {
        return (
            (
                vm.multiOccurrence.initializing ||
                vm.multiOccurrence.fetchingDataList
            ) &&
            (
                !vm.multiOccurrence.initError &&
                !vm.multiOccurrence.dataListError
            )
        );
    }

    //#####################
    //# LIBELLÉ DYNAMIQUE #
    //#####################

    function getColReplacedValue(col: any): string {
        const colonne = (col.nom) ? col : vm.multiOccurrence.colonnesParametres[col];
        if (colonne && colonne.replacedValue) {
            if (colonne.replacedValue instanceof Function) {
                return colonne.replacedValue(vm.multiOccurrence);
            } else {
                return colonne.replacedValue;
            }
        } else {
            return '';
        }
    }

    function getEnteteReplacedValue(col: string): string {
        if (vm.multiOccurrence.entetesReplacedValues && vm.multiOccurrence.entetesReplacedValues[col.toUpperCase()]) {
            return vm.multiOccurrence.entetesReplacedValues && vm.multiOccurrence.entetesReplacedValues[col.toUpperCase()];
        }
    }

    //#######################
    //# GESTION DES RANGÉES #
    //#######################

    function hasData(): boolean {
        return vm.dataListGroups && Boolean(Object.keys(vm.dataListGroups).length);
    }

    function valideClick($event: JQueryEventObject, id: number, data: any, index: number): void {
        if (vm.multiOccurrence.modeEditionRapide) {
            if (vm.multiOccurrence.rangeeEditionRapide !== data) {
                if (!data.$deleted) {
                    vm.multiOccurrence.rangeeEditionRapide = data;
                    vm.multiOccurrence.indexEditionRapide = index;
                }

                if (vm.multiOccurrence.rangeeEditionRapide && vm.multiOccurrence.rangeeEditionRapide.$editedRapide) {
                    vm.multiOccurrence.rangeeEditionRapide.$editionRapideCtrl.$setSubmitted();
                }
            }

            const target = angular.element($event.target);

            const shouldFocus = (
                !target.hasClass('ex-input-lov-chevron') &&
                !target.closest('.ex-input-lov-chevron').length &&
                !target.closest('ex-grid-col--action').length &&
                !data.$deleted
            );

            if (shouldFocus) {
                if (vm.editionRapideClickTimer) {
                    $timeout.cancel(vm.editionRapideClickTimer);
                }

                const col = target.closest('.ex-grid-col');
                const colInput = col.find('input,select,md-radio-group').filter(':visible').first();

                if (colInput.length) {
                    colInput.focus();
                } else {
                    const rowInput = col.find('input,select,md-radio-group').filter(':visible').first();
                    rowInput.focus();
                }
            }

            if ((<any>$event.originalEvent)) {
                (<any>$event.originalEvent).exGridRowClick = true;
            }
        } else {
            const target = angular.element($event.target);
            if (vm.clickRowAction &&
                typeof target.closest('.ex-grid-col').attr('ex-no-action') === 'undefined' &&
                !target.hasClass('ex-grid-col--action') && !target.closest('.ex-grid-col--action').length &&
                !target.hasClass('ex-data-value--action') && !target.closest('.ex-data-value--action').length &&
                !target.hasClass('ex-data-type-bouton') && !target.closest('.ex-data-type-bouton').length) {
                vm.clickRowAction({ $event: $event, $id: id, $data: data, $index: index });
            }
        }
    }

    function valideDoubleClick($event: JQueryEventObject, data: any) {
        if (vm.doubleClickRowAction) {
            vm.doubleClickRowAction(
                {
                    $event: $event,
                    $data: data
                }
            );
        }
    }

    function isActiveRow(data: any): boolean {
        return !vm.multiOccurrence.editionRapideActive && data[vm.multiOccurrence.cleint] === vm.multiOccurrence.activeRowCleint;
    }

    function onCheckboxChange(data: any) {
        vm.multiOccurrence.emit('rowSelectionChange', data[vm.multiOccurrence.cleint]);
        if (!data[vm.multiOccurrence.selectionColonne]) {
            vm.multiOccurrence.toutSelectionne = false;
        }
    }

    function onRadioChange(data: any) {
        vm.multiOccurrence.toutSelectionne = false;
        vm.multiOccurrence.toggleToutSelectionne();
        data[vm.multiOccurrence.selectionColonne] = true;
    }

    function getClassByRow(data: any) {
        if (vm.multiOccurrence.classItems && typeof vm.multiOccurrence.classItems.row === 'function') {
            return vm.multiOccurrence.classItems.row(data);
        }
        return vm.multiOccurrence.classItems && vm.multiOccurrence.classItems.row;
    }

    function getClassByCol(data: any, col: string) {
        if (vm.multiOccurrence.classItems && vm.multiOccurrence.classItems.col instanceof Function) {
            return vm.multiOccurrence.classItems.col(data, col);
        }
    }

    function isColEditionAssistee(col: string) {
        return vm.multiOccurrence.dataTypes[col] instanceof CaseEditionAssisteeDataType;
    }

    function getColTotalFlex(col: string, index: number) {
        if (index < vm.multiOccurrence.colonnesFixesGauche.length || index > vm.colonnesTotaux.length - vm.multiOccurrence.colonnesFixesDroite.length - 1) {
            return vm.multiOccurrence.colonnesParametres[col].largeur && `0 0 ${vm.multiOccurrence.colonnesParametres[col].largeur}px`;
        } else {
            return vm.multiOccurrence.colonnesParametres[col].largeur && `1 1 ${vm.multiOccurrence.colonnesParametres[col].largeur}px`;
        }
    }

    function hasEntete(colonnes: Array<IColonneEntete>) {
        return colonnes.some(col => col.nom);
    }

    function isMenuDisabled(menu: IMenu, data: IData) {
        if (menu) {
            if (menu.disabled instanceof Function) {
                return menu.disabled(data);
            } else {
                return menu.disabled;
            }
        } else {
            return false;
        }
    }

    vm.saveColonneEditable = function saveColonneEditable(data: any) {
        vm.multiOccurrence.saveRowDetails(data);
    }

    vm.isColEditable = function isColEditable(col: string, data: any) {
        const editable = vm.multiOccurrence.colonnesParametres[col].editable
        return !vm.multiOccurrence.fonctions.editionRapide && editable instanceof Function ? editable(data) : editable
    }
}
