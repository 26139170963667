import * as angular from 'angular';
import {
    IAugmentedJQuery,
    IComponentController,
    IFormController,
    INgModelController,
    IPromise,
    IQService,
    IRootScopeService,
    IScope
} from 'angular';
import { IExceptions } from '../../constants/exceptions.constant';
import { IFilterLibelle } from '../../filters/ex-libelle.filter';
import { IApiError } from '../../interfaces/api-error.interface';
import { ITranscludeSlotFunction } from '../../interfaces/transclude-function.interface';
import { IFonctionTransversaleService } from '../../services/fonction-transversale.service';
import { IFormulaire } from '../../services/formulaire/formulaire.service';
import { IMenuItem, IMenuItemClass } from '../../services/menu/menu-item.service';
import { IMenu, IMenuClass } from '../../services/menu/menu.service';
import { IMessagesCycle, IMessagesCycleClass } from '../../services/messages-cycle.service';
import {
    IMonoOccurrence,
    IMonoOccurrenceOptions
} from '../../services/mono-occurrence.service';
import { IMultiOccurrence } from '../../services/multi-occurrence.service';
import { INotificationHandler } from '../../services/utils/notification-handler.service';
import { IDialogFormulaireOptions } from '../../dialogs/dialog-formulaire/dialog-formulaire.controller';
import { IDialog } from '../../services/dialog.service';
import { IDialogAllStatus } from '../../constants/dialog.constant';
import { IParametresSecuriteService } from '../../services/parametres-securite.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IEcranDetailsResourcesEntite } from '../../resources/ecran-details.resource';
import { IFormulaireItem } from '../../services/formulaire/formulaire-item.service';
import { IChangementManager } from '../../services/changement-manager.service';
import IDialogService = angular.material.IDialogService;
import { IEcranStateParamsService } from '../../services/utils/ecran-state-params.service';
import { IFonctionTransversaleResourceClass } from '../../resources/fonction-transversale.resource';
import { IKeyCodes } from '../../constants/key-codes.constant';
import isMobile from '../../constants/mobile.constant';
import { IMenuItemEcran } from '../../services/menu/menu-item-ecran.service';
import { IAppTitreHelper } from '../../services/app-titre-helper.service';
import { IProfil } from '../../resources/profil.resource';
import { IStateParamsService, IStateService } from "angular-ui-router";

export interface IComposantMonoOccurrenceEcran extends IComponentController {
    codeEcran: string;
    contentWidth: number;
    bandeauPortail?: boolean;
    cycleHaut?: boolean;
    autresActionsError?: boolean;
    monoOccurrence: IMonoOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions;
    formulaire: IFormulaire;
    menuBandeau: IMenu;
    menuItemProchainEtat?: IMenuItemEcran;
    menuAutresActions?: Array<IMenuItemEcran>;
    hasPanneauPage: boolean;
    formCtrl: IFormController;
    formData: any;
    left: number;
    right: number;
    largeurFormulaire: number;
    multiOccurrencesEnfants: { [bloc: string]: IMultiOccurrence };
    mnemonique: string;
    messagesCycle: IMessagesCycle;
    fetchingMessagesErreur: boolean;
    messagesErreurError: boolean;
    fetchingMessagesAvertissement: boolean;
    messagesAvertissementError: boolean;
    fonctionTransversale: IFonctionTransversaleService;
    ecranContextCtrl: IEcranContextController;
    inModaleEcran: boolean;
    actionsInModale: boolean;
    stateChangeConfirmed: boolean;
    panneauOpened: boolean;
    valeursEcran: any;
    titreDialog?: string;
    isMobile: boolean;

    getTitre(): string;
    save($event: MouseEvent | KeyboardEvent): void;
    isDisabled(): boolean;
    registerForm(fromCtrl: IFormController): void;
    refreshProchainEtat(): IPromise<any>;
    isErreurIntrouvable(): boolean;
    isErreurServeur(): boolean;
    isEcranBusy(): boolean;
    isContentHidden(): boolean;
    retryFetchingMessages(): void;
    registerMultiOccurrenceEnfant(multiOccurrence: IMultiOccurrence): void;
    isSavable(): boolean;
    hasCarteMenusVisible(): boolean;
    isMenuItemHidden(menuItem: IMenuItem): boolean;
    hasBoutonsHautPage(): boolean;
    hasBlocCreation(): boolean;
    closeModaleEcran(): void;
    getPremierFormulaireNonSauvegarde(): HTMLElement;
    confirmerQuitterPage(): IPromise<any>;
    keydown(event: KeyboardEvent): void;
}

/* @ngInject */
export default function MonoOccurrenceEcranController(Menu: IMenuClass, MenuItem: IMenuItemClass,
    $q: IQService,
    $scope: IScope, $state: IStateService, $stateParams: IStateParamsService,
    $element: IAugmentedJQuery,
    DialogStatus: IDialogAllStatus,
    DialogFormulaire: IDialog,
    notificationHandler: INotificationHandler,
    Exceptions: IExceptions,
    exLibelleFilter: IFilterLibelle,
    $transclude: ITranscludeSlotFunction,
    fonctionTransversale: IFonctionTransversaleService,
    MessagesCycle: IMessagesCycleClass,
    parametresSecurite: IParametresSecuriteService,
    $mdDialog: IDialogService,
    ecranDetails: IEcranDetailsResourcesEntite,
    ecranStateParams: IEcranStateParamsService,
    FonctionTransversaleResource: IFonctionTransversaleResourceClass,
    changementManager: IChangementManager,
    AppTitreHelper: IAppTitreHelper,
    appName: string,
    profil: IProfil,
    keyCodes: IKeyCodes,
    $rootScope: IRootScopeService) {
    const vm: IComposantMonoOccurrenceEcran = this;

    vm.$onInit = $onInit;
    vm.save = save;
    vm.getTitre = getTitre;
    vm.isDisabled = isDisabled;
    vm.registerForm = registerForm;
    vm.isErreurIntrouvable = isErreurIntrouvable;
    vm.isErreurServeur = isErreurServeur;
    vm.isEcranBusy = isEcranBusy;
    vm.isContentHidden = isContentHidden;
    vm.registerMultiOccurrenceEnfant = registerMultiOccurrenceEnfant;
    vm.isSavable = isSavable;
    vm.hasCarteMenusVisible = hasCarteMenusVisible;
    vm.isMenuItemHidden = isMenuItemHidden;
    vm.hasBoutonsHautPage = hasBoutonsHautPage;
    vm.hasBlocCreation = hasBlocCreation;
    vm.closeModaleEcran = closeModaleEcran;
    vm.keydown = keydown;

    function $onInit() {
        // On expose la méthode de confirmation sur le context pour que le dialogue puisse l'utiliser
        vm.ecranContextCtrl.confirmerQuitterPage = () => changementManager.confirmerQuitterPage($element);
        vm.multiOccurrencesEnfants = {};
        vm.codeEcran = `${vm.ecranContextCtrl.stateParams.srccod}-${vm.ecranContextCtrl.stateParams.ecran}`;
        vm.fetchingMessagesErreur = false;
        vm.messagesErreurError = false;
        vm.fetchingMessagesAvertissement = false;
        vm.messagesAvertissementError = false;
        vm.fonctionTransversale = fonctionTransversale;
        vm.inModaleEcran = vm.ecranContextCtrl.ecranDetails.ecrcleint !== ecranDetails.ecrcleint;
        vm.actionsInModale = vm.inModaleEcran && vm.ecranContextCtrl.stateParams.showActionsInDialog;
        vm.valeursEcran = vm.ecranContextCtrl.ecranDetails.valeurs;
        vm.isMobile = isMobile;

        fonctionTransversale.closeFonctionTransversale();

        vm.monoOccurrenceOptions = vm.monoOccurrenceOptions || {};

        vm.monoOccurrenceOptions.formulaire = vm.monoOccurrenceOptions.formulaire || vm.formulaire;
        vm.formulaire = vm.monoOccurrenceOptions.formulaire;
        vm.menuBandeau = new Menu([])

        vm.monoOccurrenceOptions.fonctions = {
            stateParams: vm.ecranContextCtrl.stateParams as any,
            exportation: true,
            suiviModification: true,
            boutonEnregistrerHaut: true,
            ...vm.monoOccurrenceOptions.fonctions
        };

        $scope.$watch('::vm.monoOccurrence', (monoOccurrence: IMonoOccurrence) => {
            if (monoOccurrence) {
                if (vm.inModaleEcran && vm.actionsInModale && vm.monoOccurrenceOptions) {
                    const dialogEcranCtrl = $element.closest('md-dialog').controller();

                    if (vm.monoOccurrenceOptions.menus) {
                        dialogEcranCtrl.menus = vm.monoOccurrenceOptions.menus.filter((menuItem) => !isMenuItemHidden(menuItem));
                    }

                    dialogEcranCtrl.monoOccurrenceEcranCtrl = vm;
                    vm.titreDialog = dialogEcranCtrl.titre;
                }

                if (vm.formulaire) {
                    vm.monoOccurrence.on('exMenuItemActionDataUpdate', onMenuItemActionDataUpdate);
                }

                const removeListener = $scope.$watch(() => monoOccurrence.cycleCleint || monoOccurrence.initialized, (ready: boolean) => {
                    if (ready) {
                        removeListener();
                        vm.messagesCycle = new MessagesCycle({
                            cycleCleint: monoOccurrence.cycleCleint,
                            id: monoOccurrence.id
                        });

                        if (vm.largeurFormulaire) {
                            vm.left = vm.largeurFormulaire;
                            vm.right = 100 - vm.left;
                        } else if ((((vm.monoOccurrence.cycleCleint && !vm.cycleHaut) || $transclude.isSlotFilled('droite') || $transclude.isSlotFilled('gauche') || hasCardMenusVisible()) && (vm.monoOccurrence.id || vm.monoOccurrence.noId)) || vm.monoOccurrence.fonctions.zoneDroiteVisibleCreation) {
                            vm.left = 70;
                            vm.right = 30;
                        } else {
                            vm.left = 100;
                            vm.right = 0;
                        }
                    }
                });

                vm.mnemonique = vm.monoOccurrence.mnemonique;

                if (vm.ecranContextCtrl.stateParams.employe && vm.hasPanneauPage) {
                    vm.panneauOpened = true;
                }

                vm.monoOccurrence.promiseTitreFonctionTransversale = vm.monoOccurrence.ready.then(async () => {
                    if ((vm.monoOccurrence.id || vm.ecranContextCtrl.stateParams.employe) && (vm.monoOccurrenceOptions && !vm.monoOccurrenceOptions.hideTitreBlocFonctionTransversale)) {
                        vm.monoOccurrence.fetchTitreBlocFonctionTransversale = fetchTitreBlocFonctionTransversale;
                        return vm.monoOccurrence.data.$promise.then(() => {
                            return fetchTitreBlocFonctionTransversale();
                        });
                    }
                });

                if (vm.monoOccurrence.fonctions.boutonDupliquer) {
                    vm.monoOccurrence.actionsMore.add(new MenuItem("G_LBL_DUPLIQUER", dupliquer, { icon: "content_copy", hidden: (data: any) => !vm.monoOccurrence.hasNouveau(data) || (vm.monoOccurrence.fonctions.boutonDupliquer instanceof Function && !vm.monoOccurrence.fonctions.boutonDupliquer(data)) }))
                }
            }
        });

        // Permet aux composants parent de savoir qu'ils contiennent un MonoEcran
        $scope.$emit('exMonoOccurrenceEcranInit');

        const stopListening = changementManager.watchStateChange($element);

        // Si on est dans le premier niveau d'écran (pas dans un dialogue écran), on écoute les changements de route
        // et on confirme la perte de changements avant de permettre la navigation

        $scope.$on('$destroy', () => {
            stopListening();
            vm.monoOccurrence.removeAllListeners();
        });
        //en ecoute pour executer la methode fetchdata
        $scope.$on("exMenuItemMonoOccurrence.fetchData", function (event, data) {
            vm.monoOccurrence.fetchData()
        })
    }

    function onMenuItemActionDataUpdate(data: any) {
        vm.formulaire.liste.forEach((formulaireItem: IFormulaireItem) => {
            if (typeof data[formulaireItem.col] !== 'undefined') {
                vm.formData[formulaireItem.col] = data[formulaireItem.col];
            }
        });
    }

    /**
     * Cette méthode est utilisée afin d'enregistrer ses formulaires enfants. (ex-card-saisie)
     */
    function registerForm(formCtrl: IFormController): void {
        vm.formCtrl.$addControl(formCtrl);
        setValidateForm(formCtrl);
    }

    /**
     * Cette méthode récursive permet de valider les éléments d'un formulaire et de ses formulaires enfants
     */
    function setValidateForm(form: IFormController | INgModelController): void {
        angular.forEach(form, (input: IFormController | INgModelController, propName) => {
            if (!propName.startsWith('$') && (<INgModelController>input).$setViewValue) {
                (<INgModelController>input).$validate();
            } else if (!propName.startsWith('$')) {
                setValidateForm(input);
            }
        });
    }

    function getTitre() {
        return vm.titreDialog || vm.ecranContextCtrl.ecranDetails.titre;
    }

    function isDisabled() {
        return (isMainFormCtrlInvalid() || vm.formCtrl && vm.formCtrl.$invalid) || !vm.monoOccurrence.isSaveable()
    }

    function isMainFormCtrlInvalid() {
        if (vm.formulaire?.piecesJointes) {
            return Boolean(
                vm.formCtrl
                    ?.$getControls()
                    .filter((field: any) => typeof field.$$controls === 'undefined')
                    .some((field: any) => field.$invalid)
            );
        } else {
            //S'il n'y pas id et que la propriete noId=false nous sommes en mode saisie alors on affiche le button enregistrer
            if (vm?.monoOccurrence && !vm.monoOccurrence.noId && !vm.monoOccurrence.id) {
                return false
            }
            return Boolean(vm.formCtrl?.$invalid || !vm.monoOccurrence.hasChangementsNonSauvegardes(vm.formData));
        }
    }

    function save($event: MouseEvent | KeyboardEvent) {
        $event.preventDefault();
        vm.formCtrl.$setSubmitted();
        vm.formCtrl.$commitViewValue();

        if (!vm.isDisabled()) {
            // Timeout pour attendre la validation des champs avec lov
            setTimeout(() => {
                confirmAction().then(data => vm.monoOccurrence.save(data)).then(errorData => {
                    // On a fait un forage en chemin
                    if (vm.monoOccurrence.srccod !== vm.ecranContextCtrl.stateParams.srccod) return;

                    notificationHandler.succes();
                    //declancheur pour l'infos monetaires
                    if (vm.monoOccurrenceOptions.infosMonetaires)
                        $rootScope.$broadcast('monoOccurrence.infosMonetaires.updateDataList');

                    if (vm.ecranContextCtrl.stateParams.appliquerEnregistrement) {
                        return $mdDialog.hide(DialogStatus.APPLIQUER);
                    } else if (!vm.ecranContextCtrl.stateParams.id) {
                        vm.ecranContextCtrl.transitionTo(vm.ecranContextCtrl.stateParams.route.NAME, {
                            ...vm.ecranContextCtrl.stateParams,
                            id: vm.monoOccurrence.id,
                            error: errorData,
                            verifierChangements: false
                        }, { location: 'replace', reload: false });
                    } else {
                        reloadEcran(errorData)
                        return vm.monoOccurrence.data;
                    }
                }).catch(err => {
                    if (err !== DialogStatus.FERMER) {
                        afficherValidationErreur(err);
                    }
                })
            }, 500);
        }
    }

    function reloadEcran(errorData: any, flagBroadcast: boolean = false) {
        if (vm?.monoOccurrenceOptions?.reloadPage || flagBroadcast) {
            if (vm?.ecranContextCtrl) {
                vm.ecranContextCtrl.transitionTo(vm.ecranContextCtrl.stateParams.route.NAME, {
                    ...vm.ecranContextCtrl.stateParams,
                    id: vm.monoOccurrence.id,
                    error: errorData,
                    verifierChangements: false
                }, { location: 'replace', reload: false });
            }
        }
    }

    function confirmAction() {
        if (vm.monoOccurrenceOptions && vm.monoOccurrenceOptions.confirmationSauvegarde && afficherConfirmationSauvegarde()) {
            const options = vm.monoOccurrenceOptions.confirmationSauvegarde;
            const dialogOptions: IDialogFormulaireOptions = {
                lblTitre: exLibelleFilter(options.lblTitre, vm.monoOccurrence.libelles),
                icon: options.icon,
                lblMessageInfo: exLibelleFilter(options.lblMessageInfo, vm.monoOccurrence.libelles),
                largeur: options.largeur,
                lblConfirm: 'G_LBL_BTN_OK',
                formulaire: options.formulaire,
                data: vm.formData,
                occurrence: vm.monoOccurrence,
                ecranContext: vm.ecranContextCtrl
            };

            return DialogFormulaire.show({ locals: dialogOptions }).then(data => Object.assign(vm.formData, data)).catch(err => {
                if (err === DialogStatus.FERMER && vm.monoOccurrenceOptions.confirmationSauvegarde.saveOnCancel) {
                    return $q.resolve(vm.formData);
                } else {
                    throw err;
                }
            });
        } else {
            return $q.resolve(vm.formData);
        }
    }

    function afficherConfirmationSauvegarde() {
        if (vm.monoOccurrenceOptions.confirmationSauvegarde.conditionAffichage !== undefined) {
            if (vm.monoOccurrenceOptions.confirmationSauvegarde.conditionAffichage instanceof Function) {
                return vm.monoOccurrenceOptions.confirmationSauvegarde.conditionAffichage(vm.monoOccurrence, vm.formData);
            } else {
                return vm.monoOccurrenceOptions.confirmationSauvegarde.conditionAffichage;
            }
        } else {
            return true; //Si aucune condition d'affichage n'est définie, on affiche toujours la modale de confirmation.
        }
    }

    function afficherValidationErreur(error: IApiError) {
        const message = (error.data && error.data.code && error.data.code.startsWith('SOFE-')) ? error.data.message : 'G_MSG_SAUV_ENR';
        notificationHandler.erreur({
            error,
            lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
            lblMessage: message,
            confirmAction: () => {
                return vm.monoOccurrence.save(vm.formData);
            }
        });
    }

    function isErreurIntrouvable() {
        return vm.monoOccurrence.dataErrorType.NOM === Exceptions.INTROUVABLE.NOM;
    }

    function isErreurServeur() {
        return vm.monoOccurrence.dataErrorType.NOM === Exceptions.ERREUR_SERVEUR.NOM;
    }

    function isEcranBusy() {
        return (
            (vm.monoOccurrence.id || !vm.monoOccurrence.data || vm.monoOccurrence.noId) &&
            (
                vm.monoOccurrence.initializing ||
                vm.monoOccurrence.fetchingData ||
                vm.monoOccurrence.fetchingChampsTransactionnels ||
                vm.monoOccurrence.fetchingValeursDefaut ||
                vm.fetchingMessagesErreur ||
                vm.fetchingMessagesAvertissement
            ) &&
            !vm.monoOccurrence.initError &&
            !vm.monoOccurrence.dataError &&
            !vm.monoOccurrence.champsTransactionnelsError &&
            !vm.monoOccurrence.valeursDefautError &&
            !vm.messagesErreurError &&
            !vm.messagesAvertissementError
        );
    }

    function isContentHidden() {
        return (
            vm.monoOccurrence.initializing ||
            vm.monoOccurrence.fetchingData ||
            vm.monoOccurrence.dataError ||
            vm.monoOccurrence.fetchingChampsTransactionnels ||
            vm.monoOccurrence.champsTransactionnelsError ||
            vm.monoOccurrence.valeursDefautError ||
            (
                (vm.fetchingMessagesErreur || vm.fetchingMessagesAvertissement) &&
                (!vm.messagesErreurError && !vm.messagesAvertissementError) &&
                (!vm.monoOccurrence.data || !vm.monoOccurrence.data.$linked || !vm.monoOccurrence.data.$resolved)
            )
        );
    }

    function registerMultiOccurrenceEnfant(multiOccurrence: IMultiOccurrence) {
        vm.multiOccurrencesEnfants[multiOccurrence.bloc.toUpperCase()] = multiOccurrence;

        if (multiOccurrence && vm.monoOccurrenceOptions.updateDataWith && vm.monoOccurrenceOptions.updateDataWith.some(b => b.toUpperCase() === multiOccurrence.bloc.toUpperCase())) {
            multiOccurrence.once('dataListUpdate', () => {
                // Pour éviter le double chargement initial
                multiOccurrence.on('dataListUpdate', () => {
                    vm.monoOccurrence.softUpdateData();
                    vm.messagesCycle.refreshMessages(vm.monoOccurrence.srccod, vm.monoOccurrence.stateParams);
                });
            });
        }
    }

    function isSavable() {
        return Boolean(
            vm.formulaire && (
                (vm.monoOccurrence.fonctions.edition && (vm.monoOccurrence.id || vm.monoOccurrence.noId)) ||
                (vm.monoOccurrence.fonctions.nouveau && !vm.monoOccurrence.id && !vm.hasBlocCreation())
            )
        );
    }

    function hasCarteMenusVisible(): boolean {
        return vm.monoOccurrenceOptions && vm.monoOccurrenceOptions.menus &&
            vm.monoOccurrenceOptions.menus.some((menuItem) => !isMenuItemHidden(menuItem)) &&
            (
                !vm.monoOccurrenceOptions.fonctions ||
                (
                    vm.monoOccurrenceOptions.fonctions &&
                    !vm.monoOccurrenceOptions.fonctions.afficherMenusDansEntete
                )
            );
    }

    function isMenuItemHidden(menuItem: IMenuItem): boolean {
        if (menuItem.hidden instanceof Function) {
            return menuItem.hidden(vm.monoOccurrence.data);
        } else {
            return menuItem.hidden;
        }
    }

    function hasBoutonsHautPage(): boolean {
        return (vm.monoOccurrence.id || vm.monoOccurrence.noId || !hasBlocCreation() || vm.monoOccurrence.shouldDisplaySaveButton()) && hasMenusHautPage();
    }

    function hasMenusHautPage(): boolean {
        return Boolean(
            (!vm.inModaleEcran || !vm.actionsInModale) &&
            vm.monoOccurrenceOptions && vm.monoOccurrenceOptions.menus && vm.monoOccurrenceOptions.fonctions.afficherMenusDansEntete
        );
    }

    function hasBlocCreation(): boolean {
        return $transclude.isSlotFilled('blocCreation');
    }

    function closeModaleEcran() {
        changementManager.confirmerQuitterPage($element)
            .then(() => $mdDialog.hide(DialogStatus.FERMER));
    }

    function hasCardMenusVisible(): boolean {
        return vm.monoOccurrenceOptions && vm.monoOccurrenceOptions.menus &&
            !vm.monoOccurrenceOptions.fonctions.afficherMenusDansEntete &&
            vm.monoOccurrenceOptions.menus.some((menuItem: IMenuItem) => !isMenuItemHidden(menuItem));
    }

    function fetchTitreBlocFonctionTransversale(): IPromise<any> {
        const cleint = vm.monoOccurrence.cleint;

        const cleref = vm.monoOccurrence.data[cleint];
        const srccod = vm.ecranContextCtrl.stateParams.srccod;

        const params = {
            cleref,
            srccod: srccod,
            ...ecranStateParams(vm.ecranContextCtrl.stateParams),
            ...parametresSecurite(vm.ecranContextCtrl.stateParams)
        };

        return FonctionTransversaleResource.getTitre(params).$promise
            .then((result) => {
                vm.monoOccurrence.titreBlocFonctionTransversale = result.titre;
                setTitreBlocFonctionTransversale();
                vm.monoOccurrence.fonctionsTransversalesTitreErreur = false;
            })
            .catch((err) => {
                vm.monoOccurrence.fonctionsTransversalesTitreErreur = true;
                return err;
            });
    }

    function setTitreBlocFonctionTransversale(): void {
        AppTitreHelper.setTitrePage({ titreEcran: ecranDetails.titre, titreContenu: vm.monoOccurrence.titreBlocFonctionTransversale, codeEcran: vm.ecranContextCtrl.stateParams.srccod.toUpperCase() + "-" + vm.ecranContextCtrl.stateParams.ecran, titreApp: AppTitreHelper.getNomAppTitre(appName), cienomabr: profil.compagnie.cienomabr, cienom: profil.compagnie.cienom });
    }

    function keydown(event: KeyboardEvent) {
        if (event.ctrlKey && event.keyCode === keyCodes.S) {
            event.preventDefault();
            if (vm.isSavable()) {
                vm.save(event);
            }
        } else if (event.shiftKey && event.key === "F6" && vm.monoOccurrence.initialized && vm.monoOccurrence.fonctions.nouveau) {
            event.preventDefault()
            dupliquer()
        }
    }

    function dupliquer() {
        if (!vm.monoOccurrence.fonctions.boutonDupliquer || (vm.monoOccurrence.fonctions.boutonDupliquer instanceof Function && !vm.monoOccurrence.fonctions.boutonDupliquer(vm.monoOccurrence.data))) return
        localStorage.setItem("monoOccurrenceCopy", JSON.stringify({ ...vm.monoOccurrence.data, [vm.monoOccurrence.cleint]: undefined, usrcre: undefined, usrmod: undefined, datmod: undefined, datcre: undefined }))
        $state.go(`secure.${vm.monoOccurrence.srccod.toLowerCase()}.ecran`, { menuId: $stateParams.menuId, menucleref: $stateParams.menucleref, pracleint: $stateParams.pracleint, ecran: $stateParams.ecran }, { reload: true, inherit: false })
    }

    $scope.$on("vm.mono-occurrence.setSticky", function (event, data) {
        vm.ecranEnteteSticky = true;
        vm.hideTitreBlocFonctionTransversale = true;
    })
    $scope.$on("vm.mono-occurrence.setSticky.setPadding", function (event, data) {
        setTimeout(() => {
            if (data) {
                vm.messagesCycle = data;
            }
        });
    })
    const listener = $rootScope.$on('vm.mono-occurrence.reloadEcran', (event, data) => {
        if (!event.defaultPrevented) {
            if (data?.codeEcran && vm?.codeEcran === data.codeEcran) {
                event.defaultPrevented = true;
                reloadEcran({}, true);
            }
        }
    });

    $scope.$on('$destroy', () => {
        listener();
    });
}
