import { IStateParamsService } from "angular-ui-router";
import { IEcranContextController } from "../../behaviors/ex-ecran-context/ex-ecran-context.behavior";
import { IComposantDialog } from "../../components/ex-dialog/ex-dialog.controller";
import { IMultiOccurrence, IMultiOccurrenceClass, IMultiOccurrenceOptions } from "../../services/multi-occurrence.service";
import { IEcranDetailsResourcesEntite } from "../../resources/ecran-details.resource";
import { IDataType } from "../../services/data-types/data-type.service";
import { IMenuClass } from "../../services/menu/menu.service";
import { IMenuItemClass } from "../../services/menu/menu-item.service";
import { INotificationHandler } from "../../services/utils/notification-handler.service";
import { ILovDataTypeClass } from "../../services/data-types/lov-data-type.service";
import { IPromise, IQService, IFormController, ITimeoutService, IScope } from "angular";
import { ILocalDataListFunction } from "../../services/menu/menu-item-multi-occurrence.service";
import { IDefaultsService } from "../../services/utils/defaults.service";
import { IMonoOccurrence, IMonoOccurrenceOptions } from "../../services/mono-occurrence.service";
import { IMenuItemActionClass } from "../../../core/services/menu/menu-item-action.service";
import { IFormulaireClass } from "../../services/formulaire/formulaire.service";
import { IFormulaireItemClass } from "../../services/formulaire/formulaire-item.service";
export interface IDialogMultiOccurrence extends IComposantDialog {
    multiOccurrenceGrid: IMultiOccurrence;
    multiOccurrenceGridOptions: IMultiOccurrenceOptions;
    multiOccurrenceLov: IMultiOccurrence;
    monoOccurrenceOptions: IMonoOccurrenceOptions
    monoOccurrence: IMonoOccurrence;
    formCtrl: IFormController;
    ecranContextCtrl: IEcranContextController;
    ecranDetails: IEcranDetailsResourcesEntite;
    dataType: IDataType;
    srccod: string;
    datalist: Array<any> | ILocalDataListFunction;
    saveLocalAction(savedData: any, init?: boolean): IPromise<any>;
    saveLocalActionIntervalle(intervalle1: any, intervalle2: any, init?: boolean, savedData?: any): IPromise<any>;
    deleteLocalAction(idLigne: string | number): IPromise<any>;
    array: any[];
    isLovIntervalle: boolean;
    titre: string;
    multiOccurrenceIntervalle: IMultiOccurrence;
    multiOccurrenceIntervalleOptions: IMultiOccurrenceOptions;
    intervalle1: any;
    intervalle2: any;
    dataTypeintervalle1: IDataType;
    dataTypeintervalle2: IDataType;
    colIntervalle1: any;
    colIntervalle2: any;
}
interface arrayValues {
    [key: string]: string | number;
}

/* @ngInject */
export default function DialogChampSelectionMultipleController(MultiOccurrence: IMultiOccurrenceClass,
    $stateParams: IStateParamsService,
    $q: IQService,
    ecranDetails: IEcranDetailsResourcesEntite,
    Menu: IMenuClass,
    MenuItem: IMenuItemClass,
    notificationHandler: INotificationHandler,
    LovDataType: ILovDataTypeClass,
    defaults: IDefaultsService,
    MenuItemAction: IMenuItemActionClass,
    $timeout: ITimeoutService,
    $scope: IScope,
    Formulaire: IFormulaireClass,
    FormulaireItem: IFormulaireItemClass) {
    const vm: IDialogMultiOccurrence = this;
    vm.ecranDetails = ecranDetails;
    vm.$onDestroy = $onDestroy;
    vm.$onInit = function $onInit() {
        defaults(vm, {
            datalist: [],
            intervalle1: Array.from(vm.data),
            intervalle2: Array.from(vm.data),
            colIntervalle1: vm.col,
            colIntervalle2: vm.col
        });
        vm.saveLocalAction = saveLocalAction;
        vm.saveLocalActionIntervalle = saveLocalActionIntervalle
        vm.deleteLocalAction = deleteLocalAction;
        vm.dataType = new LovDataType({
            schemaItem: {},
            template: "{{vm.col | exLov : vm.data : vm.dataType}}",
            params: { source: vm.source, type: 'lov', description: vm.description }
        });

        vm.dataTypeintervalle1 = new LovDataType({
            schemaItem: {},
            template: "{{vm.col | exLov : vm.intervalle1 : vm.dataType}}",
            params: { source: vm.source, type: 'lov', description: vm.description }
        });

        vm.dataTypeintervalle2 = new LovDataType({
            schemaItem: {},
            template: "{{vm.col | exLov : vm.intervalle2 : vm.dataType}}",
            params: { source: vm.source, type: 'lov', description: vm.description }
        });


        initMultiOccurrence();

        vm.monoOccurrenceOptions = {
            hideTitreBlocFonctionTransversale: true,
            hideShadowBox: false,
            menus: [new MenuItemAction("G_LBL_BTN_SOUMETTRE", "BOUDIALOG", () => ({}), {
                iconButton: false,
                lblMessageSuccess: 'G_MSG_ACTION_SUCCES',
                disabled: () => !vm.formCtrl || vm.formCtrl.$invalid,
                successAction() {
                }
            })],
            fonctions: {
                boutonEnregistrerHaut: false,
                exportation: false,
                suiviModification: false,
                codeEcranBandeau: false,
                afficherMenusDansEntete: true,
                boutonDupliquer: false
            }
        };
    }

    function initMultiOccurrence() {
        if (!vm.multiOccurrenceGridOptions) {
            vm.multiOccurrenceGridOptions = {
                autoFetch: false,
                localDataList: true,
                editionRapideActive: false,
                actionsRangeeDroite: new Menu([
                    new MenuItem('G_LBL_SUPPRIMER', (event: MouseEvent, data: any) => {
                        if (vm.multiOccurrenceGrid.dataList.includes(data)) {
                            deleteLocalAction(data.id)
                        }
                    }, {
                        class: 'ex-menu-item-button--secondaire pe-multi-occurrence-template-bris-action-more-ligne',
                        iconButton: true,
                        icon: 'delete_forever'
                    })]),
                pagination: {
                    nombreElementPage: 3,
                    nbElementsPossibles: [3, 6, 15]
                },
                colonnesVisibles: [
                    { nom: vm.col, largeur: 300, hidden: vm.isLovIntervalle },
                    { nom: vm.colIntervalle1 + "Int1", titre: vm.titre + ' De', largeur: 100, hidden: !vm.isLovIntervalle },
                    { nom: vm.colIntervalle2 + "Int2", titre: vm.titre + ' à', largeur: 100, hidden: !vm.isLovIntervalle }
                ],
                colonnesCachees: [
                    'idsIntervalle'
                ],
                fonctions: {
                    supprime: false,
                    nouveau: false,
                    edition: false,
                    suiviModification: false,
                    exportation: false,
                    importation: false,
                    enregistrerUnEtat: false,
                    saveEtatTemporaire: false,
                    selectionnerDesColonnes: false,
                    reinitialiser: false,
                    skipDoubleRequetes: true,
                    selectionnerUnEtat: false,
                    criteresSuggeresVisibles: false,
                    recherche: false
                },

            }
            if (!vm.multiOccurrenceGrid) {
                vm.multiOccurrenceGrid = new MultiOccurrence(Object.assign({
                    stateParams: $stateParams,
                    ecranDetails: vm.ecranDetails,
                }, vm.multiOccurrenceGridOptions));
                vm.multiOccurrenceGrid.ready.then(() => {
                    vm.multiOccurrenceGrid.dataList = [];
                    vm.multiOccurrenceGrid.on('vm.multiOccurrenceGrid.dataList', initLocalDataList)
                    vm.multiOccurrenceGrid.fetchingDataList = true;
                    $timeout(() => { vm.multiOccurrenceGrid.fetchingDataList = false; initLocalDataList() }, 300)
                });

            }
        }
    }

    function $onDestroy() {
        vm.multiOccurrenceGrid.removeListener('vm.multiOccurrenceGrid.dataList', initLocalDataList);
    }

    function showNotificationError(error: any) {
        if (Array.isArray(error.data)) {
            error.data = error.data[0];
        }
        notificationHandler.erreur({
            error,
            lblTitre: 'G_LBL_MOD_ERREUR_TITRE',
            lblMessage: error.message || 'G_MSG_ERREUR_ACTION'
        });
    }

    function getNewValue(obj: any) {
        let tmp = { id: getRandom() }
        if (!vm.array) {
            vm.array = []
        }
        if (obj) {
            tmp = obj;
            Object.assign(tmp, { ...tmp, id: obj.id })
            if (!vm.array.includes(tmp)) {
                vm.array.push(tmp)
            }
        } else {
            //on utilise la property id pour faire le track dans le moment d'eliminer
            let element = {};
            Object.keys(vm.data).forEach((el) => {
                if (el && isNaN(+el)) {
                    //on utilise les champs qui n'ont pas un $$ et qui ne sont pas un index dans vm.data
                    if (typeof (el) === "string" && !(el.substring(0, 1) === "$")) {
                        Object.assign(tmp, {
                            ...tmp,
                            [el]: vm.data[el]
                        })
                        Object.assign(element, { ...element, [el]: vm.data[el] })
                    }
                }
            })
            Object.assign(element, { ...element, id: tmp.id })
            vm.array.push(element)
        }
        //on valide que la valeur soit unique
        if (!exist(tmp)) {
            return null;
        }
        return tmp;
    }

    //methode pour touver des doublons dans l'array des objects
    function exist(tmp: any) {
        let result = true;
        if (vm?.multiOccurrenceGrid?.dataList) {
            Object.keys(vm.multiOccurrenceGrid.dataList).forEach((el) => {
                if (el && !isNaN(+el)) {
                    let index = +el
                    //on utilise le champ "col" qui a été mis dans le formulaire du critere afin de faire la validation des doublons
                    if (vm.isLovIntervalle) {
                        if (compareIntervalleData(tmp["idsIntervalle"], vm.multiOccurrenceGrid.dataList[index]["idsIntervalle"])) {
                            result = false;
                        }
                    } else {
                        if (vm.multiOccurrenceGrid.dataList[index][vm.col] === tmp[vm.col]) {
                            result = false;
                        }
                    }
                }
            });
        }
        return result;
    }

    function saveLocalActionIntervalle(intervalle1: any, intervalle2: any, init?: boolean, savedData?: any): IPromise<any> {

        if (!init) {
            //on obtiens les colonnes de description de la lov
            const intervalle1 = copyFields(vm.intervalle1)
            const intervalle2 = copyFields(vm.intervalle2)

            //on cree le nouvel object
            const resultat = {
                id: intervalle1[vm.col],
                [vm.colIntervalle1 + "Int1"]: vm.multiOccurrenceGrid.getDataColonneIntervalle(intervalle1, vm.col),
                [vm.colIntervalle2 + "Int2"]: vm.multiOccurrenceGrid.getDataColonneIntervalle(intervalle2, vm.col),
                idsIntervalle: { intervalleDe: intervalle1[vm.col], intervalleA: intervalle2[vm.col] }
            }
            savedData = getNewValue(resultat);
        } else {
            savedData = getNewValue(savedData);
        }
        save(savedData)
        return $q.resolve()
    }

    function saveLocalAction(savedData: any, init: boolean = false, showError: boolean = true): IPromise<any> {

        if (!init) {
            savedData = getNewValue(null);
        }
        else {
            savedData = getNewValue(savedData);
        }
        save(savedData)
        return $q.resolve()

    }

    function save(savedData: any, init: boolean = false, showError: boolean = true) {
        if (savedData !== null) {
            if (!vm?.multiOccurrenceGrid?.dataList) {
                vm.multiOccurrenceGrid.dataList = []
            }
            vm.multiOccurrenceGrid.dataList.push(savedData);
            vm.multiOccurrenceGrid.dataListReady = true
            updateLocalDataList();
            vm.multiOccurrenceGrid.emit('dataListUpdate');
        }
        else {
            if (showError) {
                showNotificationError({ data: "error", message: "G_MSG_ERREUR_ENREG_EXISTANT" })
            }
        }
        $scope.$broadcast("ex-input-lov-effacer-selection")
        return $q.resolve()
    }
    //methode pour trouver l'index à effacer
    function findIndex(idLigne: string | number) {
        let index = -1;
        let id = -1;
        Object.values(vm.multiOccurrenceGrid.dataList).forEach((el, i) => {
            if (el && typeof (el) === "object") {
                //on utilise la property id
                if (el.id === idLigne) {
                    index = i;
                    id = el.id;
                }
            }
        })
        return { index: index, id: id };
    }

    //effacer la donnée de l'array
    function deleteLocalAction(idLigne: string | number): IPromise<any> {
        const data = findIndex(idLigne);
        let index = data.index
        Object.values(vm.array).forEach((value, idx) => {
            if (value.id && value.id === data.id) {
                vm.array.splice(idx, 1);
            }
        })
        vm.multiOccurrenceGrid.dataList.splice(index, 1);
        vm.multiOccurrenceGrid.emit('dataListUpdate');
        updateLocalDataList();
        return $q.resolve();
    }

    function updateLocalDataList() {
        const key = "lov" + vm.col + vm.srccod;
        vm.datalist = vm.multiOccurrenceGrid.dataList;
        if (localStorage.getItem(key) !== null) {
            localStorage.removeItem(key)
        }
        nettoyerArray();
        localStorage.setItem(key, JSON.stringify(vm.array))
    }

    //function qui fais le nettoyage de l'array pour garder seulement les variables de l'utilisateur
    function nettoyerArray() {
        let array: any[] = [];
        let tmp = {}
        Object.values(vm.array).forEach((el) => {
            Object.keys(el).forEach((col) => {
                if (col && isNaN(+col)) {
                    //on utilise les champs qui n'ont pas un $$ et qui ne sont pas un index dans vm.data
                    if (typeof (col) === "string" && !(col.substring(0, 1) === "$")) {
                        Object.assign(tmp, {
                            ...tmp,
                            [col]: el[col]
                        })
                    }
                }
            })
            array.push(tmp)
            tmp = {}
        })
        vm.array = array;
    }

    function getRandom() {
        let max = 1000;
        return Math.floor(Math.random() * (max + 1));
    }

    function initLocalDataList() {
        try {
            const key = "lov" + vm.col + vm.srccod;
            removeDuplicatesFromLocalStorage(key)
            const data = localStorage.getItem(key);
            const values = JSON.parse(data);
            let obj: Array<arrayValues> = []
            values.forEach((el: any) => {
                let element: arrayValues = {}
                Object.keys(el).forEach((key) => {
                    Object.assign(element, {
                        ...element,
                        [key]: el[key]
                    })
                })
                obj.push(element);
                (vm.isLovIntervalle) ? saveLocalActionIntervalle(null, null, true, element) : saveLocalAction(element, true, false)
            })
        } catch (error) {
        }
    }

    type SourceObject = { [key: string]: any };
    function copyFields(source: SourceObject): SourceObject {
        return Object.entries(source).reduce((acc, [key, value]) => {
            if (!key.startsWith('$')) {
                acc[key] = value;
            }
            return acc;
        }, {} as SourceObject);
    }

    function compareIntervalleData(obj1: any, obj2: any): boolean {
        return obj1.intervalleDe === obj2.intervalleDe && obj1.intervalleA === obj2.intervalleA;
    }

    function removeDuplicatesFromLocalStorage(key: string): void {
        const items = JSON.parse(localStorage.getItem(key));

        const uniqueItems: any[] = [];
        const seenIds: (string | number)[] = [];

        items.forEach((item: any) => {
            const id = (item as any)?.id;
            if (id) {
                if (!seenIds.includes(id)) {
                    seenIds.push(id);
                    uniqueItems.push(item);
                }
            }
        });
        localStorage.setItem(key, JSON.stringify(uniqueItems))
    }

}