import IStateService = angular.ui.IStateService;
import { IComposantTab } from '../ex-tab/ex-tab.controller';
import { IComponentController, IQService, IRootScopeService, IScope, ITimeoutService } from 'angular';
import IPromise = angular.IPromise;
import { IStickyOptions } from "../../behaviors/ex-sticky/ex-sticky.behavior";
import { IComposantFormulaireInput } from "../ex-formulaire-input/ex-formulaire-input.controller";

export interface IComposantTabs extends IComponentController {
    formulaireInputCtrl: IComposantFormulaireInput;
    selected: number;
    sticky: boolean;
    stickyOptions: IStickyOptions;
    listTabs: Array<IComposantTab>;
    listTabsPromises: Array<IPromise<IComposantTab>>;
    etatTemporaire: boolean;
    register(tabScopePromise: IPromise<IComposantTab>): number;
}

/* @ngInject */
export default function TabsController($q: IQService,
    $scope: IScope, $rootScope: IRootScopeService,
    $state: IStateService,
    $timeout: ITimeoutService) {
    const vm: IComposantTabs = this;

    vm.$onInit = $onInit;
    vm.$postLink = $postLink;
    vm.register = register;

    function $onInit() {
        if (vm.sticky) {
            vm.stickyOptions = {
                parentLimit: 'ex-tabs',
                ...(vm.stickyOptions || {})
            }
        }
        vm.listTabsPromises = [];

        $scope.$watch("vm.selected", (newValue) => {
            if (newValue !== undefined) {
                const index: number = Number.parseInt(newValue.toString())
                if (!isNaN(index)) {
                    //on active le flag du tab selected
                    const tab = vm.listTabs[index]
                    $rootScope.$broadcast('ex-tab-tab-selected-multi-occurrence', { bloc: tab.bloc })
                }
                // Permet de corriger les largeurs d'affichage lors du changement de tab
                $rootScope.$broadcast('exGridReajusterLargeurColonne');
            }
        })
    }

    function $postLink() {
        $timeout(() => {
            $q.all(vm.listTabsPromises).then((listTabs: Array<IComposantTab>) => {
                vm.listTabs = listTabs.filter((tab: IComposantTab) => tab !== null);

                // On réassigne les index selon les tabs affichés
                vm.listTabs.forEach((tab: IComposantTab, index: number) => {
                    tab.index = index;
                });
            });

            if (vm.formulaireInputCtrl && vm.formulaireInputCtrl.champ.col) {
                const champ = vm.formulaireInputCtrl.champ;
                $scope.$watch('vm.selected', (newValue: number, oldValue: number) => {
                    if (newValue !== oldValue) {
                        if ((<any>champ).valuesMap) {
                            vm.formulaireInputCtrl.formCtrl[champ.col].$viewValue = (<any>champ).valuesMap[newValue];
                        } else {
                            vm.formulaireInputCtrl.formCtrl[champ.col].$viewValue = newValue;
                        }

                        vm.formulaireInputCtrl.formCtrl[champ.col].$commitViewValue();
                        vm.formulaireInputCtrl.formCtrl[champ.col].$setPristine();
                        vm.formulaireInputCtrl.formCtrl[champ.col].$setUntouched();
                        if (oldValue === undefined) {
                            vm.formulaireInputCtrl.formCtrl.$$parentForm.$setPristine();
                        }
                    }
                });

                $scope.$on('exGridReajusterLargeurColonne', () => {
                    window.dispatchEvent(new Event('resize'));
                });
            }

            if (vm.etatTemporaire) {
                $scope.$watch('vm.selected', onTabSelected)
            }
        });
    }

    function register(tabScopePromise: IPromise<IComposantTab>): number {
        vm.listTabsPromises.push(tabScopePromise);
        return vm.listTabsPromises.length - 1;
    }

    function onTabSelected(tab: number): void {
        if (typeof tab !== 'undefined') {
            $state.go($state.current, { ...$state.params, onglet: tab }, {
                location: 'replace',
                reload: false,
                notify: false
            });
        }
    }
}
