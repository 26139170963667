import { IHttpService, IRootScopeService, ITimeoutService } from 'angular';
import { IComponentController, IScope } from 'angular';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { IEcranDetailsResourcesEntite } from '../../resources/ecran-details.resource';
import { IDialog } from '../../services/dialog.service';
import { IMonoOccurrence, IMonoOccurrenceClass } from '../../services/mono-occurrence.service';
import { IParametresSecuriteService } from '../../services/parametres-securite.service';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IComposantMonoOccurrenceEcran } from '../ex-mono-occurrence-ecran/ex-mono-occurrence-ecran.controller';
import IDialogOptions = angular.material.IDialogOptions;
import { IComposantMonoOccurrence } from '../ex-mono-occurrence/ex-mono-occurrence.controller';
import { IMultiOccurrence, IMultiOccurrenceOptions } from '../../services/multi-occurrence.service';
import { IApiConfig } from '../../interfaces/api-config.interface';
import IResourceService = angular.resource.IResourceService;
import { IDataTypeClass } from '../../services/data-types/data-type.service';
import { IProfil } from '../../resources/profil.resource';
import { INotificationHandler } from '../../services/utils/notification-handler.service';
interface IComposantInputLovSelectionMultiple extends IComponentController {
    occurrence: IMultiOccurrence;
    monoOccurrence: IMonoOccurrence;
    lblTitre: string;
    openWindow(event: MouseEvent): void;
    formData: any;
    ecranContextCtrl: IEcranContextController;
    monoOccurrenceEcranCtrl: IComposantMonoOccurrenceEcran;
    monoOccurrenceCtrl?: IComposantMonoOccurrence;
    iconVisible?: boolean;
    multiOccurrence: IMultiOccurrence;
    multiOccurrenceOptions: IMultiOccurrenceOptions;
    source: string;
    menucleref: string;
    data: any;
    description: any;
    arraySelectionMultiple: any[];
    multiParent: IMultiOccurrence,
    pmtcleint: number,
    isLovIntervalle: boolean
}
/* @ngInject */
export default function InputLovSelectionMultipleController(defaults: IDefaultsService,
    $resource: IResourceService,
    ApiConfig: IApiConfig,
    ecranDetails: IEcranDetailsResourcesEntite,
    DialogChampSelectionMultiple: IDialog,
    parametresSecurite: IParametresSecuriteService,
    MonoOccurrence: IMonoOccurrenceClass,
    LovDataType: IDataTypeClass,
    $scope: IScope,
    $timeout: ITimeoutService,
    $http: IHttpService,
    profil: IProfil,
    notificationHandler: INotificationHandler,
    $rootScope: IRootScopeService) {

    const vm: IComposantInputLovSelectionMultiple = this;
    vm.openWindow = openWindow;
    vm.$onDestroy = $onDestroy;
    vm.$onInit = function $onInit() {
        vm.dataType = new LovDataType({
            schemaItem: { column: vm.col, type: "string" },
            template: 'span ng-bind-html="vm.data[vm.col] | exData : vm.schemas[vm.col]"></span>',
            params: {}
        });

        defaults(vm, {
            lblTitre: ecranDetails.titre
        });

        $timeout(() => { callValideStorage() }, 500)
        getPmtcleint();
    }
    function callValideStorage() {
        if (vm.multiOccurrenceCtrl) {
            valideLocalStorage();
        }
    }
    function $onDestroy() {
        if (vm.multiOccurrenceGrid) {
            vm.multiOccurrenceGrid.removeListener('vm.multiOccurrenceCtrl.multiOccurrence.valideLocalStorage', valideLocalStorage);
        }
    }

    function setVisible() {
        if (vm.multiOccurrenceCtrl && vm.multiOccurrenceCtrl.multiOccurrence && vm.multiOccurrenceCtrl.multiOccurrence.criteresSuggeres) {
            Object.values(vm.multiOccurrenceCtrl.multiOccurrence.criteresSuggeres.liste).forEach((el: any) => {
                if (el && el.col === vm.col && el.lovSelectionMultiple) {
                    vm.iconVisible = true;
                    vm.source = el.dataType.params.source;
                    vm.description = el.dataType.params.description
                }
            })
        }
    }

    async function setIsIntervalle() {
        if (vm?.multiOccurrenceCtrl?.multiOccurrence) {
            await vm.multiOccurrenceCtrl.multiOccurrence.isLovMultipleIntervalle(vm.multiOccurrenceCtrl.multiOccurrence.srccod, vm.source, vm.col).then((result: any) => {
                if (result && result.isIntervalle) {
                    vm.isLovIntervalle = (result?.isIntervalle === 1) ? true : false
                }
            })
        }
    }

    function setMultiOccurrence() {
        let requete;
        requete = $resource(`${ApiConfig.ROOT}/liste-valeurs/` + vm.source.trim(), {
            srccod: vm.multiOccurrenceCtrl.multiOccurrence.srccod,
            ...parametresSecurite(vm.ecranContextCtrl.stateParams)
        });

        requete.query().$promise.then((response: any) => {
            vm.monoOccurrence = new MonoOccurrence({
                stateParams: vm.ecranContextCtrl.stateParams,
                ecranDetails: vm.ecranContextCtrl.ecranDetails,
                ecranSourceDetails: vm.ecranContextCtrl.ecranSourceDetails,
                ...vm.monoOccurrenceOptions
            });
            vm.data = new vm.monoOccurrence.DataResource(response);
            Object.assign(vm.data, response);

            vm.monoOccurrence.data = new vm.monoOccurrence.DataResource(response);
            Object.assign(vm.monoOccurrence.data, response);
        }).catch((err) => { });
    }

    function sendMultiSelection() {
        if (vm.arraySelectionMultiple && Array.isArray(vm.arraySelectionMultiple)) {
            let ids: any
            let intervalle1: any = []
            let intervalle2: any = []
            if (vm.isLovIntervalle) {
                vm.arraySelectionMultiple.map((e) => {
                    intervalle1.push(e["idsIntervalle"].intervalleDe)
                    intervalle2.push(e["idsIntervalle"].intervalleA)
                });
                if (intervalle1.length > 0) {
                    insertData([], 1, intervalle1, intervalle2)
                }
                else {
                    //si la selection multiple a été vidée on supprime les valeurs dans la bd
                    if (vm.pmtcleint) {
                        insertData([], 0, [], [])
                    }
                }
            } else {
                ids = vm.arraySelectionMultiple.map((e) => {
                    return JSON.stringify(e[vm.col]);
                });

                if (ids && Array.isArray(ids)) {
                    if (ids.length > 0) {
                        insertData(ids, 1, [], [])
                    } else {
                        //si la selection multiple a été vidée on supprime les valeurs dans la bd
                        if (vm.pmtcleint) {
                            insertData([], 0, [], [])
                        }

                    }

                }
            }

        }
    }

    function insertData(ids: any, showError: number, intervalle1: any, intervalle2: any) {
        $http.post(`${ApiConfig.ROOT}/mcparm-multi-tmp/insert/`, {
            ids: ids.join(','),
            col: vm.col,
            source: vm.source,
            usrcleint: profil.preferences.usrcleint,
            srccod: vm.multiOccurrenceCtrl.multiOccurrence.srccod,
            pmtcleint: vm.pmtcleint,
            isLovIntervalle: (vm.isLovIntervalle) ? 1 : 0,
            intervalleDe: intervalle1.join(','),
            intervalleA: intervalle2.join(','),
            ...parametresSecurite(vm.ecranContextCtrl.stateParams)
        }).then((result: any) => {
            if (!result?.data?.pmtcleint && showError) {
                afficherValidationErreur();
            }
        })
    }
    function afficherValidationErreur() {
        notificationHandler.erreur({
            lblTitre: 'G_LBL_CRITERES_MULTIPLES',
            lblMessage: 'G_LBL_CRITERES_MULTIPLES_SAVE_ERROR'
        });
        deleteLocalStorage();
    }

    async function getPmtcleint(): Promise<number> {
        vm.pmtcleint = vm?.occurrence?.pmtcleint
        //si les criteres suggeres sont crees à partir d'un formulaire custom, hors du multiOcurrence on doit mettre les proprietes qui ne sont pas initialises
        if (vm?.occurrence && vm.occurrence?.isCriteresuggerePartage) {
            if (!vm.multiOccurrenceCtrl) {
                vm.multiOccurrenceCtrl = vm.occurrence
            }
            vm.multiOccurrenceCtrl.multiOccurrence = vm.occurrence;
        } else {
            if (!vm?.multiOccurrenceCtrl?.multiOccurrence && vm?.monoOccurrenceEcranCtrl?.multiOccurrencesEnfants) {
                Object.values(vm.monoOccurrenceEcranCtrl.multiOccurrencesEnfants).forEach((el) => {
                    //on cherche l'information dans les libelles car on utilise aussi les informations des libelles
                    const validation = Object.keys(el.libelles).filter((field: any) => field.toLowerCase().includes(vm.col))
                    if (Array.isArray(validation) && validation.length > 0) {
                        if (!vm.multiOccurrenceCtrl) {
                            vm.multiOccurrenceCtrl = el
                        }
                        vm.multiOccurrenceCtrl.multiOccurrence = el;
                        return;
                    }
                });
            }
        }
        return Promise.resolve(1)
    }

    async function openWindow(event: MouseEvent) {
        let libelle = "";
        let formCtrl: any;
        Object.keys(vm.multiOccurrenceCtrl.multiOccurrence.libelles).forEach((el) => {
            if (el === vm.col.toString().toUpperCase()) {
                libelle = vm.multiOccurrenceCtrl.multiOccurrence.libelles[el];
                formCtrl = vm.formCtrl[vm.col]
            }
        })
        setMultiOccurrence();
        await setIsIntervalle()
        // On n'utilise pas 'stopPropagation' à cause des effets secondaires indésirables, comme la prévention
        // de la fermeture des menus
        if (vm.multiOccurrenceCtrl.multiOccurrenceOptions && vm.multiOccurrenceOptions.multiOccurrenceParent && vm.id) {
            vm.multiOccurrenceOptions.multiOccurrenceParent.activeRowCleint = vm.id;
        }
        const dialogOptions: IDialogOptions = {
            locals: {
                lblTitre: vm.lblTitre,
                largeur: vm.largeurModale,
                icon: vm.icon,
                titre: libelle,
                formCtrl: formCtrl,
                col: vm.col,
                source: vm.source,
                data: (vm.data) ? vm.data : [],
                description: vm.description,
                srccod: vm.multiOccurrenceCtrl.multiOccurrence.srccod,
                array: vm.array,
                multiParent: vm.multiOccurrenceCtrl,
                isLovIntervalle: vm.isLovIntervalle
            }
        };
        vm.multiParent = dialogOptions.locals.multiParent;
        getPmtcleint();
        return DialogChampSelectionMultiple.show(dialogOptions).catch(() => { }).finally(() => {
            valideLocalStorage();
            sendMultiSelection();
        });
    }
    function valideLocalStorage() {
        try {
            vm.arraySelectionMultiple = [];
            vm.visible = false
            const data = localStorage.getItem("lov" + vm.col + vm.multiOccurrenceCtrl.multiOccurrence.srccod);
            if (data !== null) {
                const values = JSON.parse(data)
                if (values && Array.isArray(values) && values.length > 0) {
                    vm.arraySelectionMultiple = values;
                    vm.visible = true
                }
            }
        } catch (error) {
            vm.visible = false;
        }
        setVisible();
        $scope.$emit('vm.setSelectionMultipleEmpty', { flag: vm.visible });
    }
    function deleteLocalStorage() {
        const srccod = (vm?.multiOccurrenceCtrl?.multiOccurrence?.srccod && typeof (vm?.multiOccurrenceCtrl?.multiOccurrence?.srccod === "string")) ? vm.multiOccurrenceCtrl.multiOccurrence.srccod.toLocaleLowerCase() : vm.multiOccurrenceCtrl.multiOccurrence.srccod
        const data = localStorage.getItem("lov" + vm.col + srccod);
        if (data !== null) {
            let lov = ("lov" + vm.col + vm.multiOccurrenceCtrl.multiOccurrence.srccod);
            localStorage.removeItem(lov)
            vm.arraySelectionMultiple = []
            callValideStorage();
        }
    }
    //message entre composant lors de'un modification du formulaire de critere suggeres
    $scope.$on('ex.input.lov.selection.multiple.valide.storage', () => {
        callValideStorage()
    })

    $rootScope.$on('ex.input.lov.selection.multiple.all.valide.storage', (event) => {
        if (!event.defaultPrevented) {
            event.defaultPrevented = true;
            getPmtcleint().finally(() => {
                callValideStorage()
                if (vm?.multiOccurrence && vm.multiOccurrence.hasItemsSelectionMultiple()) {
                    sendMultiSelection()
                }
            });
        }
    })

    //on valide le local storage et on enregistra les données
    $rootScope.$on('ex.input.lov.selection.multiple.save.data', () => {
        callValideStorage()
        if (Array.isArray(vm.arraySelectionMultiple) && vm.arraySelectionMultiple.length > 0) {
            sendMultiSelection()
        }
    })
}