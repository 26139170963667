import IDialogService = angular.material.IDialogService;
import { IDialogAllStatus } from '../../constants/dialog.constant';
import { IApiError } from '../../interfaces/api-error.interface';
import { IAugmentedJQuery, IComponentController, IFormController, IPromise, IScope, IRootScopeService, ITimeoutService, IHttpService } from 'angular';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IChangementManager } from '../../services/changement-manager.service';
import { IProfil } from '../../resources/profil.resource';
import { IApiConfig } from '../../interfaces/api-config.interface';
import { IParametresSecuriteService } from '../../services/parametres-securite.service';
import { IEcranContextController } from '../../behaviors/ex-ecran-context/ex-ecran-context.behavior';
import { INotificationHandler } from '../../services/utils/notification-handler.service';
import { IFilterLibelle } from '../../filters/ex-libelle.filter';
import { breakTexteInLines } from '../../utils/break-text-in-lines.utils'

export interface IComposantDialog extends IComponentController {
    icon: string;
    lblTitre: string;
    lblConfirm: any;
    lblCancel: any;
    warm: any;
    validationError: any;
    error: IApiError;
    busy: boolean;
    closeOnError: boolean;
    formDialog: IFormController;
    saveAndNextReturn: any;
    showConfirmAction: boolean;
    hideCancelAction: boolean;
    confirmDisabled: boolean;
    submitOnEnter: boolean;
    reloadData: any;
    authValiderCode: boolean;
    authCodeRecu: boolean;
    authInputDisabled: boolean;
    authBlocTitre: string;
    bloc: string;
    formCtrlAuth: IFormController;
    ecranContext: IEcranContextController;
    data: any;
    authMessageTextMax: number
    errorValideItem: IValidateItems
    originallyDisabledElements: Map<any, HTMLElement[]>
    appliquer(): void;
    cancel(): void;
    confirmAction(): any;
    isDisabled(): boolean;
    onKeydown(event: KeyboardEvent): void;
    confirmerQuitterPage(): IPromise<any>;
    validerCode(): void;
    valideDisabledAuthCode(): boolean;
    isAuthCodeRecu(): boolean;
}
interface IValidateItems {
    CODE: string,
    STATUS: number,
    USER_MESSAGE: string
}
const lblConfirm = 'G_LBL_BTN_CONFIRMER';
const lblCancel = 'G_LBL_BTN_ANNULER';

/* @ngInject */
export default function DialogController($mdDialog: IDialogService,
    DialogStatus: IDialogAllStatus,
    defaults: IDefaultsService,
    $scope: IScope,
    $document: IAugmentedJQuery,
    $mdUtil: any,
    changementManager: IChangementManager,
    $rootScope: IRootScopeService,
    profil: IProfil,
    $timeout: ITimeoutService,
    $http: IHttpService,
    ApiConfig: IApiConfig,
    parametresSecurite: IParametresSecuriteService,
    notificationHandler: INotificationHandler,
    exLibelleFilter: IFilterLibelle) {
    const vm: IComposantDialog = this;

    vm.$onInit = $onInit;
    vm.cancel = cancel;
    vm.appliquer = appliquer;
    vm.isDisabled = isDisabled;
    vm.onKeydown = onKeydown;
    vm.validerCode = validerCode;
    vm.valideDisabledAuthCode = valideDisabledAuthCode
    vm.isAuthCodeRecu = isAuthCodeRecu
    function $onInit() {
        try {
            $timeout(() => {
                const objPreferences: any = profil.preferences;
                if (objPreferences.vaesysapp == "EMP2") {
                    if (vm.error) {
                        vm.icon = "dangerous"
                        vm.lblTitre = "G_MSG_ERREUR_VALIDATION"
                        vm.lblCancel = "G_LBL_BTN_FERMER"
                        vm.validationError = true

                    }
                    if (vm.lblTitre == "G_LBL_TITRE_MODIFICATION_EN_COURS") {
                        vm.warm = true
                    }
                    if (vm.lblTitre == "G_LBL_BTN_SUPP_ENR") {
                        vm.lblConfirm = "G_LBL_BTN_SUPPRIMER"
                        vm.warm = true
                        vm.lblTitre = "G_LBL_BTN_SUPPRIMER_ELEMENT"
                        vm.icon = "warning"
                    }
                    if (vm.lblCancel == "G_LBL_BTN_OK") {
                        vm.lblCancel = "G_LBL_BTN_FERMER"
                    }
                }
            });
        } catch (error) {
        }
        defaults(vm, {
            lblConfirm: lblConfirm,
            lblCancel: lblCancel,
            showConfirmAction: Boolean(vm.confirmAction),
            //hideCancelAction: false,
            confirmDisabled: false,
            hideUsingCallbackOnly: false,
            authCodeRecu: setFlagAuthCodeRecu(false),
            authMessageTextMax: 60,
            authInputDisabled: false,
            originallyDisabledElements: new Map<any, HTMLElement[]>()
        });

        /**
         * Ferme le dialog lorsque l'usager change de page.
         */
        const stateChangeListener = changementManager.watchStateChange(() => cancel());

        $scope.$on('$destroy', () => {
            stateChangeListener();
            $document.off('keydown', $mdUtil.debounce(onKeydown, 250));
        });

        $document.on('keydown', $mdUtil.debounce(onKeydown, 250));
    }

    function onKeydown(event: KeyboardEvent) {
        // Prévient de fermer plusieurs niveaux de dialogue d'un seul coup
        if (event.defaultPrevented) return
        event.preventDefault()

        if (vm.submitOnEnter && event.key === "Enter") {
            appliquer();
        } else if (event.key === "Escape") {
            cancel()
        }
    }

    function cancel() {
        if (vm.busy) return

        if (vm.confirmerQuitterPage) {
            vm.confirmerQuitterPage().then(() => $mdDialog.cancel(DialogStatus.FERMER)).catch(() => {
                // Fermeture annulée, on ignore
            });
        } else {
            //si reloadOnError =1 dans l'ecran on execute le fetdata
            if (vm && vm.error && vm.error.reloadOnError) {
                $rootScope.$broadcast('exMenuItemMonoOccurrence.fetchData');
            }
            $mdDialog.cancel(DialogStatus.FERMER);
            if (vm?.reloadData) {
                $rootScope.$broadcast('exMenuItemMonoOccurrence.fetchData')
            }
        }
    }

    function appliquer(): void {

        if (vm?.formCtrlAuth?.authcode) {
            vm.formDialog.$addControl(vm.formCtrlAuth.authcode)
        }

        vm.formDialog.$setSubmitted();
        vm.formDialog.$commitViewValue();
        // Si le formulaire est invalide, on arrête ici
        if (isDisabled()) return

        const actionResult = vm.confirmAction();

        if (actionResult && actionResult.then) {
            vm.busy = true;
            actionResult.then((result: any) => {
                $mdDialog.hide(result);
                $rootScope.$broadcast('vm.multiOccurrence.etat.reinitialiser');
                //déplacer le grid vers la gauche s'il existe
                $rootScope.$broadcast('exGridReajusterLargeurColonneToLeft');
                updateCalendrier(result);
            }).catch((error: IApiError) => {
                vm.authInputDisabled = true
                setFlagAuthCodeRecu(true)
                if (vm.closeOnError) {
                    return $mdDialog.cancel(error);
                }
            }).finally(() => vm.busy = false);
        } else {
            $mdDialog.hide(actionResult);
        }
    }

    function isDisabled() {
        return !vm.formDialog.$valid || vm.confirmDisabled || Boolean(vm?.busy) || (valideAuthCode())
    }
    function updateCalendrier(result: any) {
        $timeout(() => {
            if (result?.colonne == "date" && result?.valeurs && Array.isArray(result.valeurs) && result.valeurs.length > 0) {
                $rootScope.$broadcast('ex-calendrier.setDate', { newDate: result.valeurs[0], oldDate: undefined });
                $rootScope.$broadcast('ex-criteres-suggeres-update-calendrier', { date: result.valeurs[0] });
            }
            else {
                $rootScope.$broadcast('ex-calendrier.setDate', { newDate: undefined, oldDate: undefined });
            }
        }, 500);
    }

    async function validerCode() {
        if (await valideItemsAuthCode()) {
            const data = encodeURIComponent(JSON.stringify({
                usercle: profil.preferences.usrcleint,
                ciecleint: vm.data.ciecleint,
                cleint: vm.data.$id,
                blocTitre: vm.authBlocTitre,
            }))

            $http.post(`${ApiConfig.ROOT}/authentification-courriel/`, {
                data,
                ...parametresSecurite(vm.ecranContext.stateParams)
            }, {
                headers: { 'Content-Type': 'application/json; charset=UTF-8' }
            }).then((result: any) => {
                if (result?.data?.result) {
                    setFlagAuthCodeRecu(true)
                    vm.authInputDisabled = true
                    setFormReadOnly()
                } else {
                    let error = result?.data?.msg
                    if (typeof (error) !== "string") {
                        error = exLibelleFilter('G_MSG_ERREUR_GENERIQUE')
                    }
                    showError('G_LBL_BTN_AUTH_ERROR_CODE', error)
                    setFlagAuthCodeRecu(false)
                }

            }).catch(() => {
                resetFormReadOnly()
                showError('G_LBL_BTN_AUTH_ERROR_CODE', 'G_MSG_ERREUR_GENERIQUE')
                setFlagAuthCodeRecu(false)
            })
        } else {
            resetFormReadOnly()
            showError('G_LBL_BTN_AUTH_ERROR_CODE', vm.errorValideItem.USER_MESSAGE)
        }
    }

    function setFlagAuthCodeRecu(flag: boolean) {
        if(!vm.authValiderCode){
            vm.authCodeRecu=false
            return 
        }
        vm.authCodeRecu = flag
    }

    function showError(lblTitre: string, lblMessage: string) {
        notificationHandler.erreur({
            lblTitre: exLibelleFilter(lblTitre),
            lblMessage: breakTexteInLines(exLibelleFilter(lblMessage), vm.authMessageTextMax, '<br>')
        });
    }

    async function valideItemsAuthCode() {
        setFlagAuthCodeRecu(false)
        let result = false
        await $http.post(`${ApiConfig.ROOT}/authentification-courriel/valide-items/`, {
            srccod: vm.ecranContext.stateParams.srccod,
            ecrcleint: vm.ecranContext.stateParams.ecrcleint,
            bloc: vm.bloc,
            ...vm.data,
            ...parametresSecurite(vm.ecranContext.stateParams)
        }).then((res: any) => {
            if (res && res?.data && res.data.result) {
                result = true
                setFlagAuthCodeRecu(true)
            }
            else {
                vm.errorValideItem = (res?.data?.error?.USER_MESSAGE) ? res?.data?.error : { CODE: 500, STATUS: 500, USER_MESSAGE: 'G_MSG_ERREUR_GENERIQUE' }
            }
        })
        return result
    }

    function valideAuthCode() {
        return (!vm.authInputDisabled || !vm?.formCtrlAuth?.$valid) && vm.authValiderCode
    }

    function valideDisabledAuthCode(): boolean {
        return !vm.formDialog.$valid || vm.authInputDisabled
    }
    function isAuthCodeRecu(): boolean {
        return vm.authCodeRecu
    }
    function setFormReadOnly() {
        if (!vm.formDialog?.$getControls) return;

        vm.formDialog.$getControls().forEach((control: any) => {
            const controlElement = control?.$$element?.[0];

            if (controlElement) {
                const parent = controlElement.parentElement;
                const buttonElement = parent?.querySelector("button");

                const elementsToDisable = [controlElement];
                if (buttonElement) elementsToDisable.push(buttonElement);

                if (!vm.originallyDisabledElements.has(control)) {
                    vm.originallyDisabledElements.set(control, elementsToDisable);

                    elementsToDisable.forEach((el) => el.classList.add("ex-input-auth-code-parent-controls-disabled"));
                }
            }
        });
    }

    function resetFormReadOnly() {
        if (!vm?.originallyDisabledElements) return;

        vm.originallyDisabledElements.forEach((elements) => {
            elements.forEach((element) => {
                element.classList.remove("ex-input-auth-code-parent-controls-disabled");
            });
        });
        vm.originallyDisabledElements.clear();
    }
}
