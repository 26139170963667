import { IDataType } from '../../services/data-types/data-type.service';
import { ISequenceDataTypeClass } from '../../services/data-types/sequence-data-type.service';
import { IDefaultsService } from '../../services/utils/defaults.service';
import { IComponentController, IFormController, INgModelOptions, IScope, ITimeoutService } from 'angular';
import { IExAugmentedEvent } from '../../interfaces/event.interface';
import IAugmentedJQuery = angular.IAugmentedJQuery;

interface IComposantInputNumber extends IComponentController {
    nameElement: string;
    data: any;
    dataType: IDataType;
    min: any;
    max: any;
    step: any;
    precision: any;
    decimals: any;
    formCtrl: IFormController;
    input: IAugmentedJQuery;
    paddingLength: number;
    integerMaxLength: number;
    separateurMilliers: string;
    modelOptions: INgModelOptions;
    onBlur: Function;
    onFocus: Function;
    value: string;
    onKeypress(event: IExAugmentedEvent): void;
    onBlurAction(event: IExAugmentedEvent): void;
    onFocusAction(event: IExAugmentedEvent): void;
    onPaste(event: IExAugmentedEvent): void;
    getModelOptions(): any;
}

/* @ngInject */
export default function InputNumberController(defaults: IDefaultsService,
    SequenceDataType: ISequenceDataTypeClass,
    $scope: IScope,
    $element: IAugmentedJQuery,
    $timeout: ITimeoutService) {
    const vm: IComposantInputNumber = this;
    const SEPARATEUR_MILLIERS = ' ';
    const INDICATEUR_DECIMALES = '.';
    const INDICATEUR_SIGN = '-';

    vm.$onInit = $onInit;
    vm.onKeypress = onKeypress;
    vm.getModelOptions = getModelOptions;
    vm.onBlurAction = onBlurAction;
    vm.onFocusAction = onFocusAction;
    vm.onPaste = onPaste;

    function $onInit() {
        vm.max = getMax();
        const [maxInteger] = vm.max.toString().split(INDICATEUR_DECIMALES);
        const padding = (vm.dataType && vm.dataType.params && typeof vm.dataType.params.longueur !== 'undefined') ? vm.dataType.params.longueur : 1;

        vm.separateurMilliers = (vm.dataType && vm.dataType.params && typeof vm.dataType.params.separateurMilliers !== 'undefined') ?
            vm.dataType.params.separateurMilliers :
            vm.dataType.params.type !== 'string' ? SEPARATEUR_MILLIERS : null;

        defaults(vm, {
            integerMaxLength: maxInteger.length,
            enableCount: false,
            validateApi: '',
            min: -vm.max,
            paddingLength: (vm.dataType instanceof SequenceDataType) ? 3 : padding
        });

        $timeout(() => {
            vm.input = $element.find('input');
        });

        // On attend que le formulaire soit accessible
        $scope.$watch('::vm.formCtrl[vm.nameElement]', (ctrl: IComponentController) => {
            if (ctrl) {
                //on execute la fonction si le numero est valide
                if (vm?.nameElement && vm?.formCtrl[vm.nameElement]) {
                    vm.formCtrl[vm.nameElement].$formatters.push((value: string) => {
                        return formatValue(value);
                    });
                }
                vm.formCtrl[vm.nameElement].$parsers.push((value: string) => {
                    if (value === null) {
                        return value;
                    }

                    // À noter : on ne peut utiliser Number car on cesserait de bien gérer les gros nombres
                    let parsedValue = String(value)
                        .replace(/^[0]+([0-9]+)/, '$1')

                    if (vm.separateurMilliers) {
                        parsedValue = parsedValue.replace(new RegExp(vm.separateurMilliers, 'g'), '');
                    }

                    return parsedValue;
                });

                vm.formCtrl[vm.nameElement].$validators.min = (value: string) => {
                    return (!value) || (isNaN(Number(value))) || value >= vm.min;
                };

                vm.formCtrl[vm.nameElement].$validators.max = (value: string) => {
                    return (!value) || (isNaN(Number(value))) || value <= vm.max;
                };

                vm.formCtrl[vm.nameElement].$validators.virgule = (value: string) => {
                    return !value || typeof value !== "string" || !value.includes(",");
                };

                vm.formCtrl[vm.nameElement].$validators.number = (value: string) => {
                    //Le replace permet d'afficher un message plus spécifique dans le cas de la virgule
                    return (!value) || !isNaN(Number(value.toString().replace(",", "")));
                };

                vm.formCtrl[vm.nameElement].$validators.decimales = (value: string) => {
                    if (!value) {
                        return true;
                    }

                    const decimales = String(value)
                        .replace(/^[0]+([0-9]+)/, '$1')
                        .split(INDICATEUR_DECIMALES)[1] || "";

                    return (decimales.length <= vm.decimals);
                };

                // Lorsque le champ a déjà une valeur, on veut afficher les erreurs de validations déjà existantes
                vm.formCtrl[vm.nameElement].$processModelValue();
                vm.formCtrl[vm.nameElement].$render();
                vm.formCtrl[vm.nameElement].$validate();

                if (vm.formCtrl[vm.nameElement].$viewValue && vm.formCtrl[vm.nameElement].$invalid) {
                    vm.formCtrl[vm.nameElement].$setTouched();
                    vm.formCtrl[vm.nameElement].$setDirty();
                }
            }
        });
    }

    /**
     * Permet d'obtenir l'attribut 'max' du input number
     * @returns {string}
     */
    function getMax() {
        if (vm.max) {
            return vm.max;
        } else {
            let max = '9';
            let size = 17; //nombre maximal
            let maximum;
            let decimal;

            if (vm.precision) {
                size = vm.precision;
                if (vm.decimals) {
                    size -= vm.decimals;
                    maximum = max.repeat(size);
                    decimal = max.repeat(vm.decimals);
                    max = maximum + INDICATEUR_DECIMALES + decimal;
                } else {
                    max = max.repeat(size);
                }
            } else {
                max = max.repeat(size);
            }

            return Number(max);
        }
    }

    function getModelOptions(): any {
        const options = {
            updateOn: 'default',
            debounce: { '*': 350, keydown: 0 },
            allowInvalid: '$inherit'
        };
        return Object.assign(options, vm.modelOptions);
    }

    function onKeypress(event: IExAugmentedEvent) {
        if (shouldPreventKeypress(event)) {
            event.preventDefault();
        }
    }

    function shouldPreventKeypress(event: IExAugmentedEvent) {
        const input: HTMLInputElement = <HTMLInputElement>event.target;
        const key: number = event.which;
        const indexPoint = input.value.indexOf(INDICATEUR_DECIMALES);
        const integer = getIntegerValue();

        // Touche raçourcis, on accepte
        if (event.metaKey || event.ctrlKey) {
            return false;
        }

        // Caractères numériques
        if (key >= 48 && key <= 57) {
            return (
                (
                    // On prévient si la longeur est déjà atteinte sans compter les zéros au début
                    integer.replace(/^[0]+([0-9]+)/, '$1').length >= vm.integerMaxLength ||
                    // Ou en comptant les zéros si le curseur est au début
                    (integer.length >= vm.integerMaxLength && input.selectionStart === 0)
                ) &&
                // Si on a une sélection, on accepte puisque ça n'ajoute pas à la longueur
                input.selectionStart === input.selectionEnd &&
                (indexPoint === -1 || indexPoint >= input.selectionEnd)
            ) || (
                    // Prévient l'ajout de décimal quand on est à la fin et on a atteint la limite
                    (
                        input.selectionStart === input.selectionEnd &&
                        input.selectionEnd === input.value.length &&
                        input.selectionEnd !== 0 &&
                        vm.decimals &&
                        indexPoint !== -1 &&
                        (input.value.length - indexPoint - 1) === vm.decimals
                    )
                )
        }

        // Sign négatif (défaut '-')
        if (key === INDICATEUR_SIGN.charCodeAt(0)) {
            return (
                // Négatif non accepté
                (vm.min >= 0) ||
                // Le premier caractère est déjà un négatif
                (input.value[0] === INDICATEUR_SIGN) ||
                // Le curseur n'est pas au début du champ
                (input.selectionStart !== 0)
            );
        }

        // Indicateur de décimale (défaut '.')
        if (key === INDICATEUR_DECIMALES.charCodeAt(0)) {
            return (
                // Les décimales ne sont pas acceptées
                !vm.decimals ||
                // Il y a déjà un signe de décimale et il n'est pas inclu dans la sélection
                (indexPoint !== -1 && (indexPoint < input.selectionStart || indexPoint >= input.selectionEnd))
            );
        }

        // Permettre la virgule pour pouvoir afficher un message d'erreur
        if (key === ",".charCodeAt(0)) {
            return false;
        }

        // Séparateur de milliers (défaut ' ')
        if (vm.separateurMilliers && key === vm.separateurMilliers.charCodeAt(0)) {
            return (
                // Maximum plus petit que 1000
                vm.max < 1000 ||
                // Curseur au début du champ
                input.selectionStart === 0 ||
                // Il y a un point et le curseur est après le point
                (
                    indexPoint !== -1 && (
                        input.selectionStart >= indexPoint ||
                        input.selectionEnd >= indexPoint
                    )
                ) ||
                // Le caractère avant le curseur est un sépateur de millier
                input.value[input.selectionStart - 1] === vm.separateurMilliers ||
                // Le caractère après le curseur est un sépateur de millier
                input.value[input.selectionEnd] === vm.separateurMilliers
            );
        }

        // Firefox laisse passer certaines touches spéciales dans keypress
        return ![
            // Flèches, delete
            0,
            // Backspace
            8
        ].includes(event.which);
    }

    function getIntegerValue() {
        const val = String(vm.input.val());
        const indexPoint = val.indexOf(INDICATEUR_DECIMALES);
        const value = (indexPoint === -1) ? val : val.substring(0, indexPoint);

        let integerValue = value
            .replace(new RegExp(INDICATEUR_SIGN, 'g'), '');

        if (vm.separateurMilliers) {
            integerValue = integerValue.replace(new RegExp(vm.separateurMilliers, 'g'), '');
        }

        return integerValue;
    }

    function onBlurAction(event: IExAugmentedEvent) {
        const input: HTMLInputElement = <HTMLInputElement>event.target;
        const value = input.value;

        if (value !== '') {
            const patternInvalidChars = new RegExp(`[^0-9${INDICATEUR_DECIMALES}${INDICATEUR_SIGN}]`, 'g');
            const newValue = value.replace(patternInvalidChars, '');
            const formattedValue = formatValue(newValue);

            updateValue(formattedValue);
        } else {
            updateValue(null);
        }

        if (vm.onBlur) {
            vm.onBlur(vm.data, event);
        }
    }

    function onFocusAction(event: IExAugmentedEvent) {
        if (vm.onFocus) {
            vm.onFocus(vm.data, event);
        }
    }

    function onPaste(event: JQueryEventObject) {
        const originalEvent: ClipboardEvent = <ClipboardEvent>(event.originalEvent);
        const input: HTMLInputElement = <HTMLInputElement>event.target;
        let clipboardData = originalEvent.clipboardData.getData('text/plain');
        const value = input.value;
        const premierePartie = value.substring(0, input.selectionStart);
        const dernierePartie = value.substring(input.selectionEnd, value.length);

        // Un seul point de décimal autorisé
        if (premierePartie.includes(INDICATEUR_DECIMALES) || dernierePartie.includes(INDICATEUR_DECIMALES)) {
            const patternDecimales = new RegExp(`\\${INDICATEUR_DECIMALES}`, 'g');
            clipboardData = clipboardData.replace(patternDecimales, '');
        }

        // Si on est dans la partie décimale, on interdit les virgules
        if (premierePartie.includes(INDICATEUR_DECIMALES) && vm.separateurMilliers) {
            const patternMilliers = new RegExp(vm.separateurMilliers, 'g');
            clipboardData = clipboardData.replace(patternMilliers, '');
        }

        let newValue = cleanValue(premierePartie + clipboardData + dernierePartie);

        // Gestion du sign négatif
        if (vm.min < 0) {
            newValue = newValue[0] + newValue.substring(1).replace(new RegExp(INDICATEUR_SIGN, 'g'), '');
        } else {
            newValue = newValue.replace(new RegExp(INDICATEUR_SIGN, 'g'), '');
        }

        const cleanedClipboardData = cleanValue(clipboardData);
        const caretPosition = input.selectionStart + cleanedClipboardData.length;

        event.preventDefault();

        updateValue(newValue);
        input.setSelectionRange(caretPosition, caretPosition);
    }

    function updateValue(newValue: string) {
        if ((vm.input.val() !== newValue || vm.formCtrl[vm.nameElement].$invalid) && !vm.input.val().toString().includes(",")) {
            vm.formCtrl[vm.nameElement].$setViewValue(newValue);
            vm.input.val(newValue);
            vm.input.trigger('change');
        }
    }

    function formatValue(value: string | number) {
        const cleanedValue = cleanValue(String(value));

        if (cleanedValue === null || typeof cleanedValue === 'undefined' || cleanedValue === '') {
            return '';
        }

        // On ne veut pas utiliser le filre Angular car ça perd la précision
        const sign = (String(cleanedValue)[0] === INDICATEUR_SIGN) ? INDICATEUR_SIGN : '';
        const [integer, decimales = ''] = String(cleanedValue)
            .replace(/^[0]+([0-9]+)/, '$1')
            .replace(INDICATEUR_SIGN, '')
            .split(INDICATEUR_DECIMALES);

        // On ajoute les espaces de milliers
        let formattedNumber = (vm.separateurMilliers) ?
            integer.replace(/\B(?=(\d{3})+(?!\d))/g, vm.separateurMilliers) :
            integer;

        const paddedInteger = formattedNumber.padStart(vm.paddingLength, '0');

        if (vm.decimals) {
            return sign + paddedInteger + INDICATEUR_DECIMALES + decimales.replace(/[0]+$/, '').padEnd(vm.decimals, '0');
        } else {
            return sign + paddedInteger;
        }
    }

    function cleanValue(value: string) {
        let cleanedValue = value.replace(new RegExp(`[^0-9\\${INDICATEUR_DECIMALES}\\${INDICATEUR_SIGN}${vm.separateurMilliers}]`, 'g'), '');

        if (vm.separateurMilliers) {
            // On prévient les multiples séparateur de milliers consécutifs
            cleanedValue = cleanedValue.replace(new RegExp(`${vm.separateurMilliers}{2,}`, 'g'), vm.separateurMilliers);
        }

        return cleanedValue;
    }
}
